import ContextMenuItem, {
  ContextMenuItemProps,
} from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import gql from "graphql-tag";
import { FC } from "react";
import ArchiveConfirmationModal from "../modals/ArchiveConfirmationModal";
import TranslateTextModal from "../modals/translationSetModals/TranslateTextModal";
import { useCopyCourseMutation } from "./CourseOverflowMenu.generated";
import useContentPermissions from "@src/components/libraryItemDetailPages/hooks/useContentPermissions";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import { CourseLibrary } from "./hooks/useLibraryTableRowRenderFunctions";
import AutomationModal from "./assignmentModals/AutomationModal";
import { isUserSelectionEmpty } from "@src/utils/assignments";
import { useLibraryItemAccessAutomationLazyQuery } from "./LibraryContainer.generated";
import { useUpdateCourseArchivedMutation } from "../libraryItemDetailPages/course/CourseDetailPageHeader.generated";

type Props = {
  libraryItem: CourseLibrary;
  className?: string;
  iconClassName?: string;
  rotateIcon?: boolean;
  isVisible: boolean;
};

const CourseOverflowMenu: FC<Props> = ({ libraryItem }) => {
  const course = libraryItem.course;
  const { userActionPermissions } = useActionPermissions();
  const { addErrorToast, addToast } = useToast();
  const contentPermissions = useContentPermissions({ courseId: course.id });
  const [copyCourse] = useCopyCourseMutation({
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    variables: {
      id: course.id,
    },
    onCompleted: (data) => {
      if (data.copyCourse.success) {
        addToast({
          iconType: "star",
          message: `${libraryItem.name.en} successfully duplicated 🎉`,
        });
      } else {
        addErrorToast({
          ...data.copyCourse,
          callsite: "course_card_copy_course",
        });
      }
    },
  });
  const [archiveCourse] = useUpdateCourseArchivedMutation({
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    onCompleted: (data) => {
      if (data.updateCourse.success) {
        addToast({
          message: "Course successfully archived",
          iconType: "trash-2",
        });
      } else {
        addErrorToast({
          ...data.updateCourse,
          callsite: "course_card_archive_course",
        });
      }
    },
  });
  const { showModal } = useModal();
  const options: ContextMenuItemProps[] = [];
  const [getAccessAutomation] = useLibraryItemAccessAutomationLazyQuery();

  const libraryAccess: ContextMenuItemProps = {
    icon: "book-open",
    label: "Update Access",
    disabled: !userActionPermissions?.updateAutomations,
    onSelect: () => {
      getAccessAutomation({
        variables: {
          id: libraryItem.id,
        },
      }).then(({ data }) => {
        const automation = data?.LibraryItem?.accessAutomation;
        if (!automation) {
          return;
        }
        const existingAutomation = !isUserSelectionEmpty(
          automation.userSelection,
        );
        showModal(
          <AutomationModal
            automationItem={{
              automation,
              contentName: libraryItem.name.en,
              __typename: "Course",
            }}
            existingAutomation={existingAutomation}
          />,
        );
      });
    },
  };

  const archive: ContextMenuItemProps = {
    icon: "trash-2",
    label: "Archive",
    disabled: !userActionPermissions?.manageLibrary,
    onSelect: () => {
      showModal(
        <ArchiveConfirmationModal
          onArchive={() =>
            archiveCourse({
              variables: {
                courseId: course.id,
                input: { archived: { value: true } },
              },
            })
          }
          title={"Archive Course"}
          itemName={libraryItem.name.en}
          includeModuleCopy
        />,
      );
    },
  };

  const unarchive: ContextMenuItemProps = {
    icon: "undo",
    label: "Unarchive",
    disabled: !userActionPermissions?.manageLibrary,
    onSelect: () => {
      archiveCourse({
        variables: {
          courseId: course.id,
          input: { archived: { value: false } },
        },
      });
    },
  };

  const rename: ContextMenuItemProps = {
    icon: "pencil",
    label: "Rename",
    disabled: !contentPermissions?.hasEditContentPermission,
    onSelect: () => {
      showModal(
        <TranslateTextModal
          label={"Name"}
          title={"Edit Course Name"}
          translationSetUuid={libraryItem.name.uuid!}
        />,
      );
    },
  };

  const duplicate: ContextMenuItemProps = {
    icon: "copy",
    label: "Duplicate",
    disabled: !userActionPermissions?.createContent,
    onSelect: () => {
      copyCourse();
    },
  };

  if (libraryItem.archivedAt) {
    options.push(unarchive);
  } else {
    options.push(rename);
    if (course.publishedAt) {
      options.push(libraryAccess);
    }
    options.push(duplicate, archive);
  }

  return (
    <>
      {options.map((x) => (
        <ContextMenuItem key={x.label} {...x} />
      ))}
    </>
  );
};

export default CourseOverflowMenu;

gql`
  mutation DeletePath($id: Int!) {
    deletePath(id: $id) {
      success
    }
  }

  mutation CopyCourse($id: Int!) {
    copyCourse(id: $id) {
      success
      course {
        id
        libraryItem {
          id
        }
      }
      error {
        code
      }
    }
  }
`;
