import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgDetailTabCountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgDetailTabCountsQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number } };


export const OrgDetailTabCountsDocument = gql`
    query OrgDetailTabCounts {
  People(input: {}) {
    totalCount
  }
}
    `;

/**
 * __useOrgDetailTabCountsQuery__
 *
 * To run a query within a React component, call `useOrgDetailTabCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDetailTabCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDetailTabCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgDetailTabCountsQuery(baseOptions?: Apollo.QueryHookOptions<OrgDetailTabCountsQuery, OrgDetailTabCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgDetailTabCountsQuery, OrgDetailTabCountsQueryVariables>(OrgDetailTabCountsDocument, options);
      }
export function useOrgDetailTabCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDetailTabCountsQuery, OrgDetailTabCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgDetailTabCountsQuery, OrgDetailTabCountsQueryVariables>(OrgDetailTabCountsDocument, options);
        }
export type OrgDetailTabCountsQueryHookResult = ReturnType<typeof useOrgDetailTabCountsQuery>;
export type OrgDetailTabCountsLazyQueryHookResult = ReturnType<typeof useOrgDetailTabCountsLazyQuery>;
export type OrgDetailTabCountsQueryResult = Apollo.QueryResult<OrgDetailTabCountsQuery, OrgDetailTabCountsQueryVariables>;