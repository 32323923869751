import { useContext } from "react";
import { TagContextState, TagContext } from "@contexts/TagContext/TagContext";

const useTagContext = (): TagContextState => {
  const contextVal = useContext(TagContext);
  if (!contextVal) {
    throw Error("forgot to initialize TagContext");
  }
  return contextVal;
};

export default useTagContext;
