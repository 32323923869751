import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { FC, ReactNode, useMemo } from "react";

type Slice = {
  color: string;
  value: number;
};

type Props = {
  slices: Slice[];
  size: number;
  strokeWidth: number;
  backgroundColor?: string;
  children?: ReactNode;
};

const ProgressPie: FC<Props> = ({
  size,
  slices,
  strokeWidth,
  backgroundColor = deprecatedTones.gray4Alpha,
  children,
}) => {
  const radius = 50 - strokeWidth / 2;
  const innerCircleCircumference = Math.PI * 2 * radius;
  const slicesWithOffset = useMemo(() => {
    let offset = 0;
    return slices.map((slice) => {
      const length = innerCircleCircumference * (slice.value / 100);
      const result = {
        ...slice,
        offset,
        length,
      };
      offset += length;
      return result;
    });
  }, [slices, innerCircleCircumference]);
  const initialOffset = innerCircleCircumference / 4;

  return (
    <AutoLayout height={size} width={size} style={{ position: "relative" }}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
        />

        {slicesWithOffset.map((slice) => {
          return (
            <circle
              key={slice.color}
              cx="50"
              cy="50"
              r={radius}
              fill="transparent"
              stroke={slice.color}
              strokeWidth={strokeWidth}
              strokeDasharray={`${slice.length} ${
                innerCircleCircumference - slice.length
              }`}
              strokeDashoffset={`${initialOffset - slice.offset}`}
            />
          );
        })}
      </svg>
      {children && (
        <AutoLayout
          style={{ position: "absolute" }}
          alignmentHorizontal="center"
          alignmentVertical="center"
          height={size}
          width={size}
        >
          {children}
        </AutoLayout>
      )}
    </AutoLayout>
  );
};

export default ProgressPie;
