import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { TranslationSetFragment } from "@src/fragments.generated";
import useOrderedLanguageChoices from "@src/hooks/useOrderedLanguageChoices";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import {
  removeLangFromTranslationSetOverrides,
  updateTranslationSet,
  useTranslation,
} from "@utils/translationSets";
import { css, StyleSheet } from "aphrodite";
import { FC, useState } from "react";

type Props = {
  value: TranslationSetFragment;
  onChange: (newValue: TranslationSetFragment) => void;
};

const TranslationsDropdown: FC<Props> = ({ value, onChange }) => {
  const langChoices = useOrderedLanguageChoices();
  const langChoicesWithoutEng = langChoices.filter(
    (lang) => lang.value !== "en",
  );
  const [selectedLanguage, setSelectedLanguage] = useState<Language>("es");
  const googleTranslation = useTranslation(value, selectedLanguage, true).text;
  const existingOverride = value.translationOverrides.find(
    (over) => over.language === selectedLanguage,
  );
  const existingOverrideText = existingOverride?.text;
  return (
    <>
      <AutoLayout>
        <AutoLayout direction="vertical">
          <Text
            type="P2"
            fontWeight="SemiBold"
            styleDeclaration={styles.englishText}
          >
            English
          </Text>
          <Text
            type="P2"
            color={deprecatedTones.gray12}
            styleDeclaration={styles.englishTextBeforeTranslation}
            multiline={true}
          >
            {value.en}
          </Text>
        </AutoLayout>
        <div className={css(styles.middleArrow)}>
          <DeprecatedIcon
            type="arrow-right"
            style={{ color: deprecatedTones.blue9 }}
          />
        </div>
        <AutoLayout direction="vertical">
          {langChoicesWithoutEng.length !== 0 && (
            <div className={css(styles.selectContainer)}>
              <select
                className={css(styles.select)}
                value={selectedLanguage}
                onChange={(e) =>
                  setSelectedLanguage(e.target.value as Language)
                }
                onBlur={(e) => setSelectedLanguage(e.target.value as Language)}
              >
                {langChoicesWithoutEng.map((language, i) => (
                  <option key={`${language}-${i}`} value={language.value}>
                    {language.label}
                  </option>
                ))}
              </select>
              <DeprecatedIcon
                type="chevron-down"
                styleDeclaration={styles.caret}
              />
            </div>
          )}
          <Spacer size={8} />
          <AutoLayout
            direction="vertical"
            styleDeclaration={
              existingOverride
                ? styles.overrideTextContainerOnOverride
                : styles.overrideTextContainer
            }
          >
            <Text
              type="P2"
              fontWeight="Medium"
              multiline={true}
              color={deprecatedTones.gray8}
              styleDeclaration={[
                styles.initialTranslatedText,
                existingOverride && styles.initialTranslatedTextOverride,
              ]}
            >
              {googleTranslation}
              <Spacer size={8} />
            </Text>
            {!existingOverride && (
              <AutoLayout
                onClick={() =>
                  onChange(
                    updateTranslationSet({
                      ts: value,
                      lang: selectedLanguage,
                      text: "",
                    }),
                  )
                }
                styleDeclaration={styles.overrideButton}
              >
                <DeprecatedIcon
                  type="edit"
                  color={deprecatedTones.gray8}
                  style={{ marginRight: "3px" }}
                />
                <Text type="P2" fontWeight="SemiBold">
                  Override
                </Text>
              </AutoLayout>
            )}
            {existingOverride && (
              <>
                <Spacer size={3} />
                <TextField
                  text={existingOverrideText || ""}
                  multiline={true}
                  placeholder="Type here..."
                  onTextChange={(val) =>
                    onChange(
                      updateTranslationSet({
                        ts: value,
                        lang: selectedLanguage,
                        text: val,
                      }),
                    )
                  }
                  inputStyleDeclaration={styles.overrideTextField}
                  hideBorder={true}
                />

                <AutoLayout styleDeclaration={styles.removeButton}>
                  <Button
                    variant="No Outline"
                    text="Remove"
                    textType="P3"
                    textFontWeight="Regular"
                    fontColor={deprecatedTones.gray6}
                    onClick={() => {
                      const newTransSet = removeLangFromTranslationSetOverrides(
                        value,
                        selectedLanguage,
                      );
                      onChange(newTransSet);
                    }}
                  />
                </AutoLayout>
              </>
            )}
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </>
  );
};

export default TranslationsDropdown;

const styles = StyleSheet.create({
  englishText: {
    marginTop: "2px",
  },
  englishTextBeforeTranslation: {
    marginTop: "24px",
    width: "125px",
  },
  middleArrow: {
    padding: "0 12px",
    paddingRight: "16px",
    color: "red",
  },
  languageDropdown: {
    border: "none",
    zIndex: 1000,
    padding: 0,
    width: "125px",
  },
  overrideTextContainer: {
    border: `1px solid ${deprecatedTones.gray4Alpha}`,
    marginTop: "8px",
    borderRadius: 8,
    padding: "6px",
    backgroundColor: deprecatedTones.gray4Alpha,
    width: "183px",
  },
  overrideTextContainerOnOverride: {
    border: `1px solid ${deprecatedTones.blue3}`,
    marginTop: "8px",
    borderRadius: 8,
    padding: "6px",
    backgroundColor: "none",
    width: "183px",
  },
  overrideTextField: {
    zIndex: 1,
    borderRadius: "none",
    marginTop: "8px",
  },
  initialTranslatedText: {
    borderBottom: `1px solid ${deprecatedTones.gray3}`,
    paddingBottom: "3px",
    alignSelf: "stretch",
    marginRight: "8px",
    marginLeft: "8px",
  },
  initialTranslatedTextOverride: {
    textDecoration: "line-through",
  },
  overrideButton: {
    marginTop: "12px",
    marginBottom: "6px",
    paddingLeft: "12px",
    backgroundColor: deprecatedTones.gray4Alpha,
    border: "none",
    ":hover": {
      cursor: "pointer",
    },
  },
  removeButton: {
    marginLeft: "auto",
  },
  select: {
    fontSize: 14,
    fontWeight: 600,
    border: `1px solid ${deprecatedTones.white}`,
    borderRadius: 8,
    padding: "0 32px 8px 12px",
    height: 32,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    appearance: "none",
    position: "relative",
    outline: "none",
    cursor: "pointer",
  },
  selectContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  caret: {
    position: "absolute",
    right: 4,
    paddingBottom: "8px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
});
