import Modal from "@components/modals/Modal";
import { useAddUsersToLocationsMutation } from "@components/modals/operations.generated";
import PostActionSummaryModal from "@components/modals/PostActionSummaryModal";
import LocationMultiSelect from "@components/ui/LocationMultiSelect";
import Spacer from "@components/ui/Spacer";
import Text from "@ui/text";
import {
  RecipientLocation,
  UserMultiSelect,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import { useModal } from "@hooks/useModal";
import { useTracking } from "@hooks/useTracking";
import { AddUsersToLocationsInput } from "@src/types.generated";
import { pluralize } from "@utils/strings";
import { css, StyleSheet } from "aphrodite";
import { FC, useMemo, useState } from "react";
import { MinimalEmployeeFragment } from "../ui/operations.generated";

type Props = {
  data?: AddTraineesToLocationsInput;
  onSuccess?: () => void;
  refetchQueries?: string[];
};

export type AddTraineesToLocationsInput = {
  trainees?: MinimalEmployeeFragment[];
  locations?: RecipientLocation[];
};

const AddUsersToLocationsModal: FC<Props> = ({
  data = {},
  onSuccess,
  refetchQueries = [],
}) => {
  const initialLocations = useMemo(
    () => data?.locations || [],
    [data?.locations],
  );
  const initialEmployees = useMemo(
    () => data?.trainees || [],
    [data?.trainees],
  );
  const [locations, setLocations] = useState(initialLocations);
  const locationIds = useMemo(() => locations.map((g) => g.id), [locations]);
  const [employees, setEmployees] = useState(initialEmployees);
  const employeeIds = useMemo(() => employees.map((e) => e.id), [employees]);

  const { showModal, closeModal } = useModal();
  const addTraineesToGroupsInput: AddUsersToLocationsInput = {
    locationIds: locationIds,
    userIds: employeeIds,
  };
  const { trackEvent } = useTracking();
  const [addTraineesToGroups, { loading }] = useAddUsersToLocationsMutation({
    variables: {
      input: addTraineesToGroupsInput,
    },
    refetchQueries,
    onCompleted: (data) => {
      if (data.addUsersToLocations.success) {
        closeModal();
        if (onSuccess) {
          onSuccess();
        }
        trackEvent("users_added_to_location", addTraineesToGroupsInput);
        showModal(
          <PostActionSummaryModal
            title={`${employees.length} ${pluralize(
              "employee",
              employees.length,
            )} added to ${locations.length} ${pluralize(
              "location",
              locations.length,
            )}`}
          />,
        );
      }
    },
  });
  return (
    <Modal
      title={`Add to locations`}
      className={css(styles.container)}
      confirmButtonProps={{
        copy: `Add ${employeeIds.length} ${pluralize(
          "employee",
          employeeIds.length,
        )} to ${locationIds.length} ${pluralize(
          "location",
          locationIds.length,
        )}`,
        disabled: locationIds.length === 0 || employeeIds.length === 0,
        onClick: () => {
          if (!locations) {
            return;
          }
          addTraineesToGroups();
        },
        loading,
      }}
      headerClassName={css(styles.modalHeader)}
      footerClassName={css(styles.modalHeader, styles.modalFooter)}
      bodyStyle={{ width: 600 }}
    >
      <div>
        <div className={css(styles.section)}>
          <Text type="P2" fontWeight="SemiBold">
            People
          </Text>
          <Spacer size={10} />

          <UserMultiSelect employees={employees} setEmployees={setEmployees} />
        </div>
        <div className={css(styles.section)}>
          <Text type="P2" fontWeight="SemiBold">
            locations
          </Text>
          <Spacer size={10} />
          <div className={css(styles.groupsSection)}>
            <LocationMultiSelect
              showLocationsWithNoTrainees
              locations={locations}
              setLocations={setLocations}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUsersToLocationsModal;

export const styles = StyleSheet.create({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    flexGrow: 1,
    maxHeight: "80px",
  },
  modalFooter: {
    alignItems: "center",
  },
  section: {
    padding: "24px 24px 0 24px",
  },
  groupsSection: {
    marginBottom: "48px",
    position: "relative",
  },
});
