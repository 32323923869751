import { gql } from "@apollo/client";
import FeedbackItem from "@components/home/FeedbackItem";
import { useRecentCourseFeedbackQuery } from "@components/home/RecentFeedback.generated";
import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import { StyleSheet } from "aphrodite";
import { FC, useMemo } from "react";

const RecentFeedback: FC = () => {
  const { data, loading } = useRecentCourseFeedbackQuery();
  const courseFeedback = useMemo(() => {
    return data?.courseFeedback || [];
  }, [data]);
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      styleDeclaration={styles.container}
    >
      <Text type="H2" fontWeight="SemiBold" headlineFont>
        Course feedback
      </Text>
      <Spacer size={30} />
      {loading ? (
        <AutoLayout
          alignmentHorizontal="center"
          alignmentVertical="center"
          alignSelf="center"
          style={{ height: 200 }}
        >
          <Spinner />
        </AutoLayout>
      ) : (
        <AutoLayout
          direction="vertical"
          flex={1}
          alignSelf="stretch"
          spaceBetweenItems={14}
        >
          {courseFeedback.map((cf) => {
            return <FeedbackItem key={cf.id} feedbackItem={cf} />;
          })}
        </AutoLayout>
      )}
      {!loading && courseFeedback.length === 0 ? (
        <Text type="P1">No recent feedback</Text>
      ) : null}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {},
});

gql`
  query RecentCourseFeedback {
    courseFeedback: AdminCourseFeedback(pagination: { limit: 20 }) {
      ...CourseFeedbackFull
    }
  }

  fragment CourseFeedbackFull on CourseFeedback {
    id
    createdAt
    comment
    rating
    courseInstance {
      ...RecentFeedbackCourseInstance
    }
  }

  fragment RecentFeedbackCourseInstance on CourseInstance {
    id
    completedAt
    user {
      id
      name
    }
    course {
      id
      libraryItem {
        id
        name {
          ...TranslationSet
        }
      }
    }
  }
`;

export default RecentFeedback;
