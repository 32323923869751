import { DeactivationReason } from "@src/types.generated";

export function getDeactivatedByCopy(e: {
  deactivatedReason?: DeactivationReason | null;
  deactivatedBy?: { name: string } | null;
}): string {
  if (e.deactivatedReason === DeactivationReason.Hris) {
    return "by HRIS integration";
  } else if (e.deactivatedReason === DeactivationReason.SwitchedOrgs) {
    return "by switching companies";
  } else if (e.deactivatedReason === DeactivationReason.Manual) {
    return "manually" + (e.deactivatedBy ? " by " + e.deactivatedBy?.name : "");
  } else if (e.deactivatedReason === DeactivationReason.DeactivatedOrg) {
    return "when the company was deactivated";
  } else {
    return "";
  }
}
