import React, { FC } from "react";
import Tabs from "@src/deprecatedDesignSystem/components/Tabs";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import OrgDetailHeader from "@components/home/OrgDetailHeader";
import OrgDetailOverviewTab from "@components/home/OrgDetailOverviewTab";
import OrgDetailPeopleTab from "@components/home/OrgDetailPeopleTab";
import OrgDetailLocationsTab from "@components/home/OrgDetailLocationsTab";
import OrgDetailRolesTab from "@components/home/OrgDetailRolesTab";
import OrgDetailLocationGroupsTab from "@components/home/OrgDetailLocationGroupsTab";
import { LocationGroupType } from "@src/types.generated";
import useHiddenLocationGroupTypes from "@components/home/hooks/useHiddenLocationGroupTypes";
import { gql } from "@apollo/client";
import { useOrgDetailTabCountsQuery } from "@components/home/OrgDetail.generated";
import useRoles from "@hooks/useRoles";
import useLocations from "@hooks/useLocations";
import useLocationGroups from "@hooks/useLocationGroups";
import { locationGroupTypeDisplayNamePlural } from "@components/home/constants";
import useRoleGroups from "@src/hooks/useRoleGroups";
import OrgDetailRoleGroupsTab from "./OrgDetailRoleGroupsTab";

type LocationGroupTab =
  | "overview"
  | "people"
  | "roles"
  | "roleGroups"
  | "locations"
  | LocationGroupType.Franchise
  | LocationGroupType.Region
  | LocationGroupType.Brand;

export const LOCATION_GROUP_TABS: LocationGroupTab[] = [
  "overview",
  "people",
  "locations",
  "roles",
  "roleGroups",
  LocationGroupType.Region,
  LocationGroupType.Franchise,
  LocationGroupType.Brand,
];

const OrgDetail: FC = () => {
  const hiddenLgTabs = useHiddenLocationGroupTypes();
  const { data: countsData } = useOrgDetailTabCountsQuery();
  const { roles } = useRoles();
  const { locations } = useLocations();
  const { lgTypeToLgs } = useLocationGroups();
  const { roleGroups } = useRoleGroups();
  return (
    <AutoLayout
      flex={1}
      alignSelf={"stretch"}
      direction={"vertical"}
      paddingHorizontal={52}
      data-testid={"org-detail"}
    >
      <OrgDetailHeader />
      <Tabs
        tabs={LOCATION_GROUP_TABS}
        hiddenTabs={hiddenLgTabs}
        render={{
          overview: <OrgDetailOverviewTab />,
          people: <OrgDetailPeopleTab />,
          roles: <OrgDetailRolesTab />,
          roleGroups: <OrgDetailRoleGroupsTab />,
          locations: <OrgDetailLocationsTab />,
          [LocationGroupType.Franchise]: (
            <OrgDetailLocationGroupsTab type={LocationGroupType.Franchise} />
          ),
          [LocationGroupType.Brand]: (
            <OrgDetailLocationGroupsTab type={LocationGroupType.Brand} />
          ),
          [LocationGroupType.Region]: (
            <OrgDetailLocationGroupsTab type={LocationGroupType.Region} />
          ),
        }}
        labels={{
          overview: "Overview",
          people: "People",
          roles: "Roles",
          roleGroups: "Departments",
          locations: "Locations",
          ...locationGroupTypeDisplayNamePlural,
        }}
        counts={{
          people: countsData?.People.totalCount,
          roles: roles.length,
          roleGroups: roleGroups.length,
          locations: locations.length,
          ...Object.values(LocationGroupType).reduce(
            (acc, lgType) => {
              const lgs = lgTypeToLgs[lgType];
              if (lgs) {
                acc[lgType] = lgs.length;
              }
              return acc;
            },
            {} as Record<LocationGroupType, number>,
          ),
        }}
      />
    </AutoLayout>
  );
};

export default OrgDetail;

gql`
  query OrgDetailTabCounts {
    People(input: {}) {
      totalCount
    }
  }
`;
