import React, { FC, useCallback, useState } from "react";
import { StyleSheet } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import gql from "graphql-tag";
import { GraphqlOperations, TranslationSetInput } from "@src/types.generated";
import { TranslationSetFragment } from "@src/fragments.generated";
import { useModal } from "@hooks/useModal";
import {
  useTranslationSetQuery,
  useUpdateTranslationSetMutation,
} from "@components/modals/translationSetModals/TranslateTextModal.generated";

import { useToast } from "@hooks/useToast";
import Modal from "@components/modals/Modal";
import TranslationTextField from "@src/deprecatedDesignSystem/components/TranslationTextField";

type Props = {
  translationSetUuid: string;
  label: string;
  title: string;
  onSave?: (newTs: TranslationSetFragment) => void;
  saveButtonDataTestId?: string;
};

const TranslateTextModal: FC<Props> = ({
  translationSetUuid,
  label,
  title,
  onSave,
  saveButtonDataTestId,
}) => {
  const { data } = useTranslationSetQuery({
    variables: { uuid: translationSetUuid },
    onError() {
      addErrorToast({ callsite: "translate_text_modal" });
    },
    onCompleted(data) {
      setTranslationSetInput(data.TranslationSet);
    },
  });
  const { closeModal } = useModal();
  const { addErrorToast } = useToast();
  const [updateTranslationSetMutation] = useUpdateTranslationSetMutation({
    refetchQueries: [
      GraphqlOperations.Query.CourseVersion,
      GraphqlOperations.Query.LibraryItemDetailPage_LibraryItem,
      GraphqlOperations.Query.TranslationSet,
    ],
    onCompleted(data) {
      if (data.updateTranslationSet.success) {
        closeModal();
        if (onSave) {
          onSave(data.updateTranslationSet.translationSet!);
        }
      } else {
        addErrorToast(data);
      }
    },
    onError() {
      addErrorToast({ callsite: "translate_text_modal" });
    },
  });

  const onSaveCallback = useCallback(
    (translationSet: TranslationSetInput) => {
      updateTranslationSetMutation({
        variables: {
          uuid: translationSetUuid,
          input: translationSet,
        },
      });
    },
    [translationSetUuid, updateTranslationSetMutation],
  );

  const [translationSetInput, setTranslationSetInput] = useState(
    data?.TranslationSet,
  );

  if (data && !data.TranslationSet) {
    closeModal();
    addErrorToast({
      message: "Object not found",
      callsite: "translate_text_modal",
    });
    return null;
  }

  if (!translationSetInput) {
    return null;
  }

  return (
    <Modal
      title={title}
      confirmButtonProps={{
        copy: "Save",
        "data-testid": saveButtonDataTestId,
        onClick: () => {
          if (translationSetInput) {
            onSaveCallback(translationSetInput);
          }
        },
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <TranslationTextField
        label={label}
        value={translationSetInput}
        onChange={(translationSet) => {
          setTranslationSetInput(translationSet);
        }}
        containerStyleDeclaration={styles.textFieldContainer}
      />
    </Modal>
  );
};

export default TranslateTextModal;

gql`
  query TranslationSet($uuid: String!) {
    TranslationSet(uuid: $uuid) {
      ...TranslationSet
    }
  }
  mutation updateTranslationSet($uuid: String!, $input: TranslationSetInput!) {
    updateTranslationSet(uuid: $uuid, input: $input) {
      success
      translationSet {
        ...TranslationSet
      }
    }
  }
`;

const styles = StyleSheet.create({
  modal: { maxWidth: "unset" },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 0,
  },
  translateModeToggleContainer: {
    position: "absolute",
    right: 32,
  },
  modalFooter: {
    borderTop: "none",
  },
  modalFooterContent: {
    marginRight: "auto",
  },
  modalContent: {
    margin: 24,
    marginTop: 32,
    marginBottom: 48,
    display: "flex",
  },
  nonTranslationContent: {
    width: "100%",
  },
  leftCol: {
    paddingRight: 12,
    flex: 1,
  },
  rightCol: {
    paddingLeft: 12,
    flex: 1,
  },
  pathNameLabel: {
    fontSize: 13,
    color: deprecatedColors.outline,
    fontWeight: 600,
    marginBottom: 8,
    height: 18,
  },
  headerContent: {
    display: "flex",
    padding: "12px 12px 12px 24px",
    minHeight: 56,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  title: {
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 16,
    fontWeight: 500,
  },
  textFieldContainer: {
    padding: "24px 24px 30px",
  },
});
