import GroupSearchResult from "@components/ui/GroupSearchResult";
import { useGetRolesWithEmployeesQuery } from "@components/ui/operations.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Pill from "@src/deprecatedDesignSystem/components/Pill";
import {
  RecipientGroup,
  RecipientRole,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import useClickOutside from "@hooks/useClickOutside";
import useKeyboardNavigation from "@hooks/useKeyboardNavigation";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { pluralize } from "@utils/strings";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import Fuse from "fuse.js";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import RelativePortal from "react-relative-portal";

type Props = {
  roles: RecipientRole[];
  setRoles: (recipients: RecipientRole[]) => void;
  styleDeclaration?: StyleDeclaration;
  showRolesWithNoTrainees?: boolean;
};

const fuseConfig = {
  keys: ["name"],
  threshold: 0.3,
};

const RoleMultiSelect: FC<Props> = ({
  roles,
  setRoles,
  styleDeclaration,
  showRolesWithNoTrainees = false,
}) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const onClickOutside = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  useClickOutside(containerRef, onClickOutside, false);
  const { data: roleData, previousData: previousRoleData } =
    useGetRolesWithEmployeesQuery({
      variables: {
        input: { search: { value: searchValue } },
      },
    });
  const roleIds = useMemo(() => {
    return new Set((roles || []).map((r) => r.id));
  }, [roles]);
  const availableRoles = useMemo(() => {
    let availableGroups = (
      roleData?.Roles.objects ||
      previousRoleData?.Roles.objects ||
      []
    ).filter((x) => !roleIds.has(x.id));
    if (!showRolesWithNoTrainees) {
      availableGroups = availableGroups.filter(
        (x) => x?.employees && x.employees.length > 0,
      );
    }
    return availableGroups;
  }, [
    roleData?.Roles.objects,
    previousRoleData?.Roles.objects,
    showRolesWithNoTrainees,
    roleIds,
  ]);

  const fuse = useMemo(() => {
    return new Fuse(availableRoles, fuseConfig);
  }, [availableRoles]);

  const roleSearchResults = useMemo(() => {
    let temp = [...availableRoles];
    if (searchValue) {
      temp = fuse.search(searchValue).map((result) => result.item);
    }
    return temp;
  }, [fuse, searchValue, availableRoles]);
  const totalSearchResults = useMemo(() => {
    return roleSearchResults.length;
  }, [roleSearchResults]);
  const addRole = useCallback(
    (recipient: RecipientGroup) => {
      setRoles([...roles, recipient]);
      setSearchValue("");
      if (totalSearchResults !== 1) {
        setSearchFocus(true);
        if (searchRef.current) {
          searchRef.current.focus();
        }
      } else {
        setSearchFocus(false);
        if (searchRef.current) {
          searchRef.current.blur();
        }
      }
    },
    [roles, setRoles, setSearchValue, totalSearchResults],
  );
  const removeRole = useCallback(
    (role: RecipientRole) => {
      setTimeout(() => setRoles(roles.filter((r) => !(r.id === role.id))), 20);
    },
    [roles, setRoles],
  );
  const removeLastRole = useCallback(() => {
    if (roles.length > 0) {
      setRoles(roles.slice(0, -1));
    }
  }, [roles, setRoles]);
  const addRoleAtIndex = useCallback(
    (index: number) => {
      addRole(roleSearchResults[index]);
    },
    [addRole, roleSearchResults],
  );
  const onEscape = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  const onBackspace = useCallback(() => {
    if (!searchValue) {
      removeLastRole();
    }
  }, [searchValue, removeLastRole]);
  const { keyboardSelectIndex, setKeyboardSelectIndex, onKeyDown } =
    useKeyboardNavigation(
      roleSearchResults.length,
      addRoleAtIndex,
      onEscape,
      onBackspace,
    );
  const placeholder = useMemo(() => {
    if (roles.length !== 0) {
      return undefined;
    }
    return "Search roles...";
  }, [roles.length]);

  return (
    <div>
      <div
        className={css(styles.container, styleDeclaration)}
        ref={containerRef}
      >
        <div className={css(styles.selectedRow, searchFocus && styles.focused)}>
          {roles.map((g) => {
            return (
              <AutoLayout key={g.id} paddingBottom={6} paddingRight={6}>
                <Pill
                  key={`role-${g.id}`}
                  title={g.name}
                  subtitle={
                    g.employees
                      ? `${g.employees.length} ${pluralize(
                          "Trainee",
                          g.employees.length,
                        )}`
                      : undefined
                  }
                  onRemove={() => removeRole(g)}
                />
              </AutoLayout>
            );
          })}
          <input
            value={searchValue}
            ref={searchRef}
            onFocus={() => {
              setSearchFocus(true);
            }}
            onChange={(evt) => {
              setSearchValue(evt.target.value);
              setSearchFocus(true);
            }}
            className={css(styles.searchInput)}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={css(styles.rightBelowSearchBar)}>
          {searchFocus && (
            // @ts-ignore-next-line
            <RelativePortal component="div" right={0} left={0} fullWidth>
              <div className={css(styles.resultsContainer)}>
                {roleSearchResults.map((role, i) => (
                  <GroupSearchResult
                    group={role}
                    key={role.id}
                    addGroup={addRole}
                    highlighted={i === keyboardSelectIndex}
                    onMouseMove={() => setKeyboardSelectIndex(i)}
                    type={"Role"}
                  />
                ))}
                {roleSearchResults.length === 0 && (
                  <div className={css(styles.resultRow, styles.noMatches)}>
                    <div>
                      {searchValue
                        ? `No roles match your search`
                        : `All roles selected`}
                    </div>
                  </div>
                )}
              </div>
            </RelativePortal>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleMultiSelect;

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    margin: 0,
    height: "100%",
    flex: 1,
  },
  selectedRow: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: "8px",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    padding: "8px 8px 4px 8px",
    minHeight: 48,
    flexWrap: "wrap",
    fontSize: "14px",
    ":hover": {
      border: `1px solid ${deprecatedTones.blue5}`,
    },
  },
  focused: {
    ...MiscStyles.focus,
  },
  rightBelowSearchBar: {
    position: "relative",
  },
  resultsContainer: {
    width: "calc(100% - 2px)",
    padding: "8px 0",
    backgroundColor: deprecatedColors.background,
    top: -4,
    boxShadow: "0 9px 39px -21px rgba(0, 0, 0, 0.49)",
    borderRadius: 8,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    zIndex: 1000,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
  },
  resultRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    fontSize: 14,
    fontWeight: 500,
    height: 40,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  selected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    marginBottom: 4,
    minWidth: 4,
    "::placeholder": {
      color: deprecatedTones.gray6,
    },
  },
  noMatches: {
    fontSize: 13,
    marginLeft: 12,
    color: deprecatedTones.gray6,
    cursor: "unset",
    ":hover": {
      backgroundColor: deprecatedColors.disabled,
    },
  },
});
