import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReactivateEmployeesMutationVariables = Types.Exact<{
  employeeIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ReactivateEmployeesMutation = { __typename?: 'Mutation', reactivateEmployees: { __typename?: 'EmployeesMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, employees?: Array<{ __typename?: 'Employee', id: number, deactivatedAt?: string | null }> | null } };


export const ReactivateEmployeesDocument = gql`
    mutation ReactivateEmployees($employeeIds: [Int!]!) {
  reactivateEmployees(employeeIds: $employeeIds) {
    success
    error {
      code
    }
    employees {
      id
      deactivatedAt
    }
  }
}
    `;
export type ReactivateEmployeesMutationFn = Apollo.MutationFunction<ReactivateEmployeesMutation, ReactivateEmployeesMutationVariables>;

/**
 * __useReactivateEmployeesMutation__
 *
 * To run a mutation, you first call `useReactivateEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateEmployeesMutation, { data, loading, error }] = useReactivateEmployeesMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useReactivateEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateEmployeesMutation, ReactivateEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateEmployeesMutation, ReactivateEmployeesMutationVariables>(ReactivateEmployeesDocument, options);
      }
export type ReactivateEmployeesMutationHookResult = ReturnType<typeof useReactivateEmployeesMutation>;
export type ReactivateEmployeesMutationResult = Apollo.MutationResult<ReactivateEmployeesMutation>;
export type ReactivateEmployeesMutationOptions = Apollo.BaseMutationOptions<ReactivateEmployeesMutation, ReactivateEmployeesMutationVariables>;