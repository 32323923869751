import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopySkillMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type CopySkillMutation = { __typename?: 'Mutation', copySkill: { __typename?: 'SkillMutationResult', success: boolean, skill?: { __typename?: 'Skill', id: number } | null, error?: { __typename?: 'Error', code: string } | null } };


export const CopySkillDocument = gql`
    mutation CopySkill($id: Int!) {
  copySkill(id: $id) {
    success
    skill {
      id
    }
    error {
      code
    }
  }
}
    `;
export type CopySkillMutationFn = Apollo.MutationFunction<CopySkillMutation, CopySkillMutationVariables>;

/**
 * __useCopySkillMutation__
 *
 * To run a mutation, you first call `useCopySkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySkillMutation, { data, loading, error }] = useCopySkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopySkillMutation(baseOptions?: Apollo.MutationHookOptions<CopySkillMutation, CopySkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySkillMutation, CopySkillMutationVariables>(CopySkillDocument, options);
      }
export type CopySkillMutationHookResult = ReturnType<typeof useCopySkillMutation>;
export type CopySkillMutationResult = Apollo.MutationResult<CopySkillMutation>;
export type CopySkillMutationOptions = Apollo.BaseMutationOptions<CopySkillMutation, CopySkillMutationVariables>;