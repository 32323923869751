import TranslationsDropdown from "@components/modals/translationSetModals/TranslationsDropdown";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import * as Popover from "@radix-ui/react-popover";
import { TranslationSetFragment } from "@src/fragments.generated";
import { updateTranslationSet } from "@utils/translationSets";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import {
  FC,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  useState,
} from "react";

type Props = {
  value: TranslationSetFragment;
  onChange: (newValue: TranslationSetFragment) => void;
  placeholder?: string;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  height?: "40px" | "32px";
  width?: React.CSSProperties["width"];
  label?: string;
  subtext?: string;
  leftIcon?: DeprecatedIconType;
  containerStyleDeclaration?: StyleDeclaration;
  inputStyleDeclaration?: StyleDeclaration;
  inputType?: HTMLInputTypeAttribute;
  disabled?: boolean;
  error?: boolean;
  hideBorder?: boolean;
  autoFocus?: boolean;
};

const TranslationTextField: FC<Props> = (
  {
    value,
    onChange,
    placeholder = "",
    onFocus,
    onMouseEnter,
    onMouseLeave,
    onKeyDown,
    height = "32px",
    width = "100%",
    label,
    subtext,
    leftIcon,
    containerStyleDeclaration,
    inputStyleDeclaration,
    inputType = "text",
    disabled = false,
    error = false,
    hideBorder = false,
    autoFocus,
  },
  ref,
) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={css(styles.container, containerStyleDeclaration)}
      style={{ width }}
    >
      {label && (
        <label className={css(styles.label)} htmlFor={value?.en}>
          {label}
        </label>
      )}
      <div className={css(styles.wrapper)}>
        <input
          ref={ref}
          type={inputType}
          disabled={disabled}
          className={css(
            styles.input,
            styles.default,
            error && styles.error,
            hideBorder && styles.hideBorder,
            heightStyles[height],
            leftIcon ? inputLeftIcon[height] : undefined,
            inputStyleDeclaration,
          )}
          placeholder={placeholder}
          style={{ height }}
          value={value?.en}
          onFocus={onFocus && (() => onFocus())}
          onMouseEnter={onMouseEnter && (() => onMouseEnter())}
          onMouseLeave={onMouseLeave && (() => onMouseLeave())}
          onInput={(e) => {
            onChange(
              updateTranslationSet({
                ts: value,
                lang: "en",
                text: (e.target as HTMLInputElement).value,
              }),
            );
          }}
          onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
          autoFocus={autoFocus}
        />
        {leftIcon && (
          <DeprecatedIcon
            type={leftIcon}
            color={deprecatedTones.gray8}
            styleDeclaration={[styles.icon, leftIconStyle[height]]}
          />
        )}
        <Popover.Root
          open={open}
          onOpenChange={(open) => {
            setOpen(open);
          }}
        >
          <AutoLayout
            styleDeclaration={[
              styles.translateIconHover,
              rightIconStyle[height],
            ]}
          >
            <Popover.Trigger asChild>
              <Popover.Anchor>
                <DeprecatedIcon type="language" color={deprecatedTones.gray8} />
              </Popover.Anchor>
            </Popover.Trigger>
          </AutoLayout>
          <AnimatePresence>
            <Popover.Content
              asChild
              className={css(styles.dropdown)}
              align="start"
            >
              <motion.div
                initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
                animate={{
                  scaleY: 1,
                  opacity: 1,
                  transition: { type: "spring", duration: 0.2 },
                }}
                style={{ originY: 0, originX: 1 }}
              >
                <TranslationsDropdown value={value} onChange={onChange} />
              </motion.div>
            </Popover.Content>
          </AnimatePresence>
        </Popover.Root>
      </div>
      {subtext && (
        <Text
          type="P3"
          fontWeight="Medium"
          color={error ? deprecatedTones.red9 : deprecatedTones.gray7}
          styleDeclaration={subtextStyle[height]}
        >
          {subtext}
        </Text>
      )}
    </div>
  );
};

// @ts-ignore
export default forwardRef(TranslationTextField);

const styles = StyleSheet.create({
  container: {
    display: "inline-flex",
    flexDirection: "column",
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    outline: "none",
    boxShadow: "none",
    borderRadius: 8,
    "::placeholder": {
      color: deprecatedTones.gray5,
    },
    color: deprecatedTones.black,
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: deprecatedColors.onPrimary,
    boxSizing: "border-box",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
  },
  default: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    ":hover": {
      border: `1px solid ${deprecatedTones.blue9}`,
    },
    ":focus": {
      border: `2px solid ${deprecatedTones.blue3}`,
    },
    ":disabled": {
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  dropdown: {
    zIndex: 9999,
    marginTop: "8px",
    padding: "8px 8px 8px 16px",
    backgroundColor: deprecatedTones.white,
    width: "100%",
    height: "100%",
    borderRadius: 8,
    boxShadow:
      "0px 0px 12px 6px rgba(142, 145, 146, 0.15), 0px 0px 4px 5px rgba(142, 145, 146, 0.15)",
    border: "none",
    outline: "none",
    ":focus": {
      border: "none",
      outline: "none",
    },
  },
  error: {
    border: `1px solid ${deprecatedTones.red9}`,
    ":hover": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
    ":focus": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
  },
  hideBorder: {
    border: "1px solid transparent",
    ":hover": {
      border: "1px solid transparent",
    },
    ":focus": {
      border: "1px solid transparent",
    },
    ":disabled": {
      border: "1px solid transparent",
    },
  },
  icon: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  translateIconHover: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      borderRadius: "6px",
    },
  },
  translationDropdown: {
    position: "absolute",
    background: "yellow",
  },
  leftIconPx32: {
    left: 6,
  },
  rightIconPx32: {
    right: 4,
  },
  leftIconPx40: {
    left: 8,
  },
  rightIconPx40: {
    right: 8,
  },
  label: {
    fontWeight: 600,
    fontSize: 12,
    color: deprecatedColors.onBackground,
    fontFamily: "Inter",
    marginBottom: 4,
  },
  subtextPx32: {
    margin: `4px 0 0 10px`,
  },
  subtextPx40: {
    margin: `4px 0 0 12px`,
  },
  px32: {
    padding: "0 27px 0 8px",
  },
  px40: {
    padding: "0 30px 0 10px",
  },
  inputLeftIconPx32: {
    paddingLeft: 30,
  },
  inputLeftIconPx40: {
    paddingLeft: 32,
  },
  inputRightIconPx32: {
    paddingRight: 32,
  },
  inputRightIconPx40: {
    paddingRight: 34,
  },
});

const heightStyles = {
  ["32px"]: styles.px32,
  ["40px"]: styles.px40,
};

const inputLeftIcon = {
  ["32px"]: styles.inputLeftIconPx32,
  ["40px"]: styles.inputLeftIconPx40,
};

const leftIconStyle = {
  ["32px"]: styles.leftIconPx32,
  ["40px"]: styles.leftIconPx40,
};

const rightIconStyle = {
  ["32px"]: styles.rightIconPx32,
  ["40px"]: styles.rightIconPx40,
};

const subtextStyle = {
  ["32px"]: styles.subtextPx32,
  ["40px"]: styles.subtextPx40,
};
