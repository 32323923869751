import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgDetail_RoleGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgDetail_RoleGroupsQuery = { __typename?: 'Query', RoleGroups: { __typename?: 'RoleGroups', totalCount: number, objects: Array<{ __typename?: 'RoleGroup', id: string, name: string, userCount: number, roleCount: number }> } };

export type OrgDetail_RoleGroupFragment = { __typename?: 'RoleGroup', id: string, name: string, userCount: number, roleCount: number };

export const OrgDetail_RoleGroupFragmentDoc = gql`
    fragment OrgDetail_RoleGroup on RoleGroup {
  id
  name
  userCount
  roleCount
}
    `;
export const OrgDetail_RoleGroupsDocument = gql`
    query OrgDetail_RoleGroups {
  RoleGroups {
    totalCount
    objects {
      ...OrgDetail_RoleGroup
    }
  }
}
    ${OrgDetail_RoleGroupFragmentDoc}`;

/**
 * __useOrgDetail_RoleGroupsQuery__
 *
 * To run a query within a React component, call `useOrgDetail_RoleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDetail_RoleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDetail_RoleGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgDetail_RoleGroupsQuery(baseOptions?: Apollo.QueryHookOptions<OrgDetail_RoleGroupsQuery, OrgDetail_RoleGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgDetail_RoleGroupsQuery, OrgDetail_RoleGroupsQueryVariables>(OrgDetail_RoleGroupsDocument, options);
      }
export function useOrgDetail_RoleGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDetail_RoleGroupsQuery, OrgDetail_RoleGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgDetail_RoleGroupsQuery, OrgDetail_RoleGroupsQueryVariables>(OrgDetail_RoleGroupsDocument, options);
        }
export type OrgDetail_RoleGroupsQueryHookResult = ReturnType<typeof useOrgDetail_RoleGroupsQuery>;
export type OrgDetail_RoleGroupsLazyQueryHookResult = ReturnType<typeof useOrgDetail_RoleGroupsLazyQuery>;
export type OrgDetail_RoleGroupsQueryResult = Apollo.QueryResult<OrgDetail_RoleGroupsQuery, OrgDetail_RoleGroupsQueryVariables>;