import InviteQRCodeModal from "@components/modals/invitePeople/InviteQRCodeModal";
import InviteAdminOrManagerModal from "@src/components/people/personDetail/modals/inviteOrEditUserModal/InviteAdminOrManagerModal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import useIsFullAdmin from "@hooks/useIsFullAdmin";
import { useModal } from "@hooks/useModal";
import * as Popover from "@radix-ui/react-popover";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";

type Props = {
  locationId?: number;
};

const InviteUserButton: FC<Props> = ({ locationId }) => {
  const [open, setOpen] = useState(false);
  const { showModal } = useModal();
  const isFullAdmin = useIsFullAdmin();
  const { userActionPermissions } = useActionPermissions();
  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger asChild>
        <Popover.Anchor asChild>
          {userActionPermissions?.inviteOthers ? (
            <Button
              text="Invite"
              rightIcon="chevron-down"
              onClick={() => setOpen(true)}
            />
          ) : (
            <NoPermissionsTooltipWrapper
              hasPermission={false}
              caretPosition="Top"
              tooltipSide="bottom"
            >
              <Button
                disabled
                text="Invite"
                rightIcon="chevron-down"
                onClick={() => setOpen(true)}
              />
            </NoPermissionsTooltipWrapper>
          )}
        </Popover.Anchor>
      </Popover.Trigger>
      <AnimatePresence>
        <Popover.Content className={css(styles.dropdown)}>
          <motion.div
            initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
            animate={{
              scaleY: 1,
              opacity: 1,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{ originY: 0, originX: 1 }}
          >
            <AutoLayout
              direction="vertical"
              alignmentVertical={"center"}
              padding={4}
            >
              <AutoLayout
                height={40}
                width={"100%"}
                alignmentHorizontal={"left"}
                alignmentVertical={"center"}
                paddingRight={12}
                paddingLeft={10}
                styleDeclaration={styles.dropdownItem}
                onClick={() => {
                  showModal(
                    <InviteAdminOrManagerModal
                      initialLocationId={locationId}
                    />,
                  );
                  setOpen(false);
                }}
              >
                <Text type="P2" fontWeight="Medium">
                  {isFullAdmin ? "Invite Admin or Manager" : "Invite Manager"}
                </Text>
              </AutoLayout>
              <AutoLayout
                height={40}
                width={"100%"}
                alignmentHorizontal={"left"}
                alignmentVertical={"center"}
                paddingRight={12}
                paddingLeft={10}
                styleDeclaration={styles.dropdownItem}
                onClick={() => {
                  showModal(
                    <InviteQRCodeModal initialLocationId={locationId} />,
                  );
                  setOpen(false);
                }}
              >
                <Text type="P2" fontWeight="Medium">
                  Invite Trainee
                </Text>
              </AutoLayout>
            </AutoLayout>
          </motion.div>
        </Popover.Content>
      </AnimatePresence>
    </Popover.Root>
  );
};

export default InviteUserButton;

const styles = StyleSheet.create({
  dropdown: {
    zIndex: 9999,
    height: "100%",
    width: 252,
    marginTop: 15,
    position: "relative",
    right: 90,
    top: -10,
    backgroundColor: deprecatedTones.white,
    borderRadius: 4,
    boxShadow:
      "0px 0px 12px 6px rgba(142, 145, 146, 0.15), 0px 0px 4px 5px rgba(142, 145, 146, 0.15)",
  },
  dropdownItem: {
    whiteSpace: "nowrap",
    zIndex: 1,
    borderRadius: 8,
    textDecoration: "none",
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});
