import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useDashboardTrainingFeatureFlag from "@hooks/useDashboardTrainingFeatureFlag";
import { FC } from "react";
import useLanguage from "@src/hooks/useLanguage";
import { useTranslation } from "@src/utils/translationSets";
import ToDoListTable from "./ToDoListTable";
import { usePollInterval } from "@src/hooks/usePollInterval";
import { gql } from "@apollo/client";
import { useMyTodoAssignmentsQuery } from "@src/components/my-training/MyTrainingListView.generated";

const MY_TRAINING_ENGLISH = "My Training";

const MyTrainingListView: FC = () => {
  const lang = useLanguage();
  const myTrainingTranslation = useTranslation(
    MY_TRAINING_ENGLISH,
    lang || "en",
  );
  const pollInterval = usePollInterval(1000 * 60);
  const { data, loading } = useMyTodoAssignmentsQuery({
    pollInterval,
  });
  const dashboardTrainingEnabled = useDashboardTrainingFeatureFlag();
  if (!dashboardTrainingEnabled) return null;
  return (
    <AutoLayout flex={1} margin={16} padding={16} direction="vertical">
      <Text type="title" headlineFont>
        {myTrainingTranslation.text || MY_TRAINING_ENGLISH}
      </Text>
      <Spacer size={24} />
      <ToDoListTable
        assignments={data?.MyTodoAssignments.objects || []}
        loading={loading}
      />
    </AutoLayout>
  );
};

gql`
  query MyTodoAssignmentsCount {
    MyTodoAssignments {
      totalCount
    }
  }
  query MyTodoAssignments {
    MyTodoAssignments {
      objects {
        ...ToDoAssignment
      }
    }
  }
  fragment ToDoAssignment on Assignment {
    id
    status
    libraryItem {
      ...ToDoLibraryItem
    }
    currentCourseInstance {
      ...ToDoCourseInstance
    }
    currentPathInstance {
      ...ToDoPathInstance
    }
  }
  fragment ToDoLibraryItem on LibraryItem {
    id
    type
    name {
      ...MyTranslationSet
    }
    coverImage {
      ...CoverImage
    }
    description {
      ...MyTranslationSet
    }
  }
  fragment ToDoPathInstance on PathInstance {
    id
    startedAt
    completedAt
    percentComplete
    dueDate
  }
  fragment ToDoCourseInstance on CourseInstance {
    id
    track
    startedAt
    completedAt
    percentComplete
    dueDate
    certificateUrl
  }
`;

export default MyTrainingListView;
