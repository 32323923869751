import { FC, useMemo } from "react";
import { gql } from "@apollo/client";
import {
  OrgDetail_RoleGroupFragment,
  useOrgDetail_RoleGroupsQuery,
} from "./OrgDetailRoleGroupsTab.generated";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import TableRowAvatarAndName from "@components/people/roleDetail/TableRowAvatarAndName";
import NewRoleGroupButton from "@components/home/NewRoleGroupButton";
import { getRoleGroupDetailRoute } from "@components/people/utils/getDetailRoute";
import { useModal } from "@src/hooks/useModal";
import useActionPermissions from "@src/hooks/useActionPermissions";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import RoleGroupModal from "@components/home/RoleGroupModal";
import DeleteRoleGroupModal from "@components//modals/DeleteRoleGroupModal";

const OrgDetailRoleGroupsTab: FC = () => {
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const { data, loading } = useOrgDetail_RoleGroupsQuery();
  const allItems: OrgDetail_RoleGroupFragment[] = useMemo(() => {
    return data?.RoleGroups.objects || [];
  }, [data?.RoleGroups.objects]);

  return (
    <Table
      dataTestId="role-groups-table"
      title="Departments"
      allItems={allItems}
      rightAction={<NewRoleGroupButton />}
      itemId={(x) => x.id}
      allColumns={["name", "roles", "members"]}
      columnTitles={{
        name: "Name",
        roles: "Roles",
        members: "People",
      }}
      fuseSearchConfig={{
        threshold: 0.2,
        keys: [
          {
            name: "name",
            getFn: (x) => x.name,
          },
        ],
      }}
      rowStyles={{
        height: 24,
      }}
      noDataEmptyState="No results"
      rowClickLink={(x) => {
        return getRoleGroupDetailRoute(x.id);
      }}
      loading={loading}
      sortingFns={{
        name: (x) => x.name,
        roles: (x) => x.roleCount,
        members: (x) => x.userCount,
      }}
      defaultSort={{
        column: "name",
        descending: false,
      }}
      rowHeight={50}
      renderFns={{
        name: (x) => {
          return <TableRowAvatarAndName name={x.name} avatarType="roleGroup" />;
        },
        roles: (x) => {
          return x.roleCount;
        },
        members: (x) => {
          return x.userCount;
        },
      }}
      overflowMenu={(x: OrgDetail_RoleGroupFragment) => {
        if (!userActionPermissions?.changeOrgSettings) {
          return null;
        }
        return (
          <>
            <ContextMenuItem
              label="Edit"
              icon="pencil"
              onSelect={() => {
                showModal(<RoleGroupModal id={x.id} />);
              }}
            />
            <ContextMenuItem
              label="Delete"
              icon="trash-2"
              onSelect={() => {
                showModal(<DeleteRoleGroupModal roleGroup={x} />);
              }}
            />
          </>
        );
      }}
    />
  );
};

export default OrgDetailRoleGroupsTab;

gql`
  query OrgDetail_RoleGroups {
    RoleGroups {
      totalCount
      objects {
        ...OrgDetail_RoleGroup
      }
    }
  }
  fragment OrgDetail_RoleGroup on RoleGroup {
    id
    name
    userCount
    roleCount
  }
`;
