import React, { useMemo, useState } from "react";
import Text from "@src/ui/text";
import Sparkles2FillIcon from "@src/ui/icons/18px/sparkles-2-fill";
import Spacer from "../ui/Spacer";
import { gql } from "@apollo/client";
import { Button } from "@src/ui/button";
import {
  GuestFeedbackAiWorkflowFragment,
  GuestFeedbackCategoryFragment,
} from "./GuestFeedbackRecommendations.generated";
import { pluralize } from "@src/utils/strings";
import * as Modal from "@src/deprecatedDesignSystem/components/ModalComposition";
import { useModal } from "@src/hooks/useModal";
import ScrollableFlexGrow from "../ui/flexBox/ScrollableFlexGrow";
import { useRouter } from "next/router";
import RatingStars from "../contentDetail/courseDetail/assignments/RatingStars";
import {
  Carousel,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
  CarouselItem,
  useCarousel,
} from "@ui/carousel";
import { Card } from "@src/ui/card";
import Circle from "@src/deprecatedDesignSystem/components/Circle";
import { isNotNullOrUndefined } from "@src/typeguards";
import AIGenerateCourseModal from "../modals/ai/AIGenerateCourseModal";
import BetaTag from "../ui/BetaTag";
import UserFillIcon from "@src/ui/icons/18px/user-fill";
import { GuestFeedbackCategoryMembershipFragment } from "./GuestFeedbackRecommendations.generated";
import ArrowUpRightIcon from "@src/ui/icons/18px/arrow-up-right";

interface GuestFeedbackRecommendationsProps {
  data: GuestFeedbackAiWorkflowFragment;
}

const GuestFeedbackRecommendations: React.FC<
  GuestFeedbackRecommendationsProps
> = ({ data }) => {
  const categories = useMemo(
    () => data.categories.filter((c) => c.isApprovedForDisplay),
    [data.categories],
  );
  return (
    <Carousel
      opts={{
        align: "start",
      }}
      className="self-stretch "
    >
      <div className="flex flex-1 flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <Text type="H2" fontWeight="SemiBold" headlineFont>
            AI Guest Feedback Recommendations
          </Text>
          <BetaTag />
        </div>
        <PrevNextButtons totalItems={categories.length} />
      </div>
      <Spacer size={16} axis="vertical" />
      <CarouselContent className="p-1">
        {categories.map((category, i) => (
          <Recommendation key={category.id} category={category} index={i} />
        ))}
      </CarouselContent>
    </Carousel>
  );
};

const PrevNextButtons = ({ totalItems }: { totalItems: number }) => {
  const [index, setIndex] = useState<number>(0);
  const { scrollNext, scrollPrev } = useCarousel();
  return (
    <div className="flex flex-row items-center gap-4">
      <CarouselPrevious
        className="static translate-y-0"
        onClick={() => {
          setIndex((x) => x - 1);
          scrollPrev();
        }}
      />
      {isNotNullOrUndefined(index) && (
        <Text type="P2" fontWeight="Medium">
          {index + 1} - {index + 3} of {totalItems}
        </Text>
      )}
      <CarouselNext
        className="static translate-y-0"
        onClick={() => {
          setIndex((x) => x + 1);
          scrollNext();
        }}
      />
    </div>
  );
};

export default GuestFeedbackRecommendations;

interface RecommendationProps {
  category: GuestFeedbackCategoryFragment;
  index: number;
}

const Recommendation: React.FC<RecommendationProps> = ({ category, index }) => {
  const { name, aiTrainingSuggestion } = category;
  const { showModal, closeModal } = useModal();
  const router = useRouter();
  return (
    <CarouselItem className="me:basis-1/2 lg:basis-1/3">
      <Card className="flex h-[240px] flex-col gap-3 rounded-xl border border-gray-2 p-4">
        <div className="flex items-center justify-between self-stretch">
          <div className="flex flex-row items-center gap-2">
            <div className="flex size-8 items-center justify-center rounded-full bg-purple-2">
              <Text type="P3" fontWeight="SemiBold" className="text-ai">
                {index + 1}
              </Text>
            </div>
            <Text
              ellipsis
              multiline
              type="H3"
              fontWeight="Medium"
              className="line-clamp-2 flex-1"
            >
              {name}
            </Text>
          </div>
        </div>
        <div className="flex-1">
          <Text
            type="P2"
            multiline
            className="line-clamp-5 text-muted-foreground"
          >
            {aiTrainingSuggestion}
          </Text>
        </div>
        <div className="flex gap-3 self-stretch">
          <Button
            variant="outline"
            size="sm"
            className="flex-1"
            onClick={() => {
              showModal(
                <AIGenerateCourseModal
                  initialText={category.aiCourseOutline}
                  onCourseCreated={(c) => {
                    const libraryItemId = c.libraryItem.id;
                    if (libraryItemId) {
                      router.push({
                        pathname: "/library/library-item/[id]",
                        query: { id: libraryItemId },
                      });
                      closeModal();
                    }
                  }}
                  closeModal={closeModal}
                />,
              );
            }}
          >
            <Sparkles2FillIcon className="text-ai" />
            Generate Course
          </Button>
          <Button
            size="sm"
            className="flex-1"
            variant="outline"
            onClick={() => {
              showModal(<RecommendationModal category={category} />);
            }}
          >
            {category.guestFeedbackMemberships.length} guest{" "}
            {pluralize("review", category.guestFeedbackMemberships.length)}
            <ArrowUpRightIcon className="size-3 text-border" />
          </Button>
        </div>
      </Card>
    </CarouselItem>
  );
};

const RecommendationModal: React.FC<{
  category: GuestFeedbackCategoryFragment;
}> = ({ category }) => {
  return (
    <Modal.Modal height={"90vh"} width={750}>
      <Modal.ModalHeader>{`${category.name} · ${category.guestFeedbackMemberships.length} guest ${pluralize("review", category.guestFeedbackMemberships.length)}`}</Modal.ModalHeader>
      <Modal.ModalBody className="p-0">
        <ScrollableFlexGrow>
          <div>
            <div>
              {category.guestFeedbackMemberships.map((gfm) => {
                return <GuestFeedbackItem gfm={gfm} key={gfm.id} />;
              })}
              <Spacer size={24} axis="vertical" />
            </div>
          </div>
        </ScrollableFlexGrow>
      </Modal.ModalBody>
    </Modal.Modal>
  );
};

const GuestFeedbackItem: React.FC<{
  gfm: GuestFeedbackCategoryMembershipFragment;
}> = ({ gfm }) => {
  return (
    <div key={gfm.id} className=" flex flex-col gap-2 border-b p-4">
      <div className="flex flex-row items-center gap-2">
        <Circle size={40} className="bg-purple-2">
          <UserFillIcon className="text-ai" />
        </Circle>
        <div>
          <Text type="P2" fontWeight="Medium">
            Guest at {gfm.guestFeedback.locationName}
          </Text>
          <RatingStars rating={gfm.guestFeedback.starRating} />
        </div>
      </div>
      {gfm.guestFeedback.parts.map((p) => {
        return (
          <div key={p.id}>
            <Text type="P2" fontWeight="SemiBold" multiline>
              {p.question}
            </Text>
            <Text type="P2" fontWeight="Regular" multiline>
              "{p.answer}"
            </Text>
          </div>
        );
      })}
    </div>
  );
};

gql`
  query GuestFeedbackAiWorkflow {
    GuestFeedbackAiWorkflow {
      ...GuestFeedbackAiWorkflow
    }
  }

  fragment GuestFeedbackAiWorkflow on GuestFeedbackAiWorkflow {
    id
    categories {
      ...GuestFeedbackCategory
    }
  }

  fragment GuestFeedbackCategory on GuestFeedbackCategory {
    id
    name
    topLevelCategoryName
    aiTrainingSuggestion
    aiCourseOutline
    isApprovedForDisplay
    guestFeedbackMemberships {
      ...GuestFeedbackCategoryMembership
    }
  }

  fragment GuestFeedbackCategoryMembership on GuestFeedbackCategoryMembership {
    id
    guestFeedback {
      id
      text
      locationName
      starRating
      parts {
        id
        question
        answer
      }
    }
  }
`;
