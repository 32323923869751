import { gql } from "@apollo/client";
import { useHrisQuery } from "./useHris.generated";
import { HrisConfigFragment } from "@src/components/settings/hris/setupFlow/OrgHrisConfigContext.generated";

type Return = {
  isIntegrated: boolean | undefined;
  provider: string | undefined;
  loading: boolean;
  hrisConfig: HrisConfigFragment | undefined;
  hasAppliedHrisCsvUpload: boolean | undefined;
};

export const useHris = (): Return => {
  const { data, loading } = useHrisQuery();

  return {
    isIntegrated: data?.Me?.user.org?.hasMergeIntegration,
    provider: data?.Me?.user.org?.hrisProvider || undefined,
    loading,
    hrisConfig: data?.Me?.user.org?.hrisConfig || undefined,
    hasAppliedHrisCsvUpload: data?.Me?.user.org?.hasAppliedHrisCsvUpload,
  };
};

gql`
  query Hris {
    Me {
      user {
        id
        org {
          id
          hasMergeIntegration
          hrisProvider
          hrisConfig {
            ...HrisConfig
          }
          hasAppliedHrisCsvUpload
        }
      }
    }
  }
`;
