import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { RecipientGroup } from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import useScrollIntoView from "@hooks/useScrollIntoView";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { pluralize } from "@utils/strings";
import { css, StyleSheet } from "aphrodite";
import { useRef } from "react";

type Props<T extends RecipientGroup> = {
  group: T;
  onMouseMove: () => void;
  addGroup: (group: T) => void;
  highlighted: boolean;
  type: "Location" | "Role";
};

function GroupSearchResult<T extends RecipientGroup>({
  group,
  onMouseMove,
  addGroup,
  highlighted,
  type,
}: Props<T>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  useScrollIntoView(ref, highlighted);
  return (
    <div
      className={css(styles.resultRow, highlighted && styles.selected)}
      onClick={(evt) => {
        evt.stopPropagation();
        addGroup(group);
      }}
      ref={ref}
      onMouseMove={onMouseMove}
    >
      <span className={css(styles.groupIconContainer)}>
        <DeprecatedIcon type={type === "Location" ? "pin" : "id-badge"} />
      </span>
      <span className={css(styles.resultName)}>{group.name}</span>
      <span className={css(styles.numTrainees)}>
        {group.employees.length} {pluralize("Employee", group.employees.length)}
      </span>
    </div>
  );
}

export default GroupSearchResult;

const styles = StyleSheet.create({
  resultRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    fontSize: 14,
    fontWeight: 500,
  },
  selected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  groupIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 24,
    width: 24,
  },
  numTrainees: {
    color: deprecatedColors.outline,
    fontSize: 12,
    marginLeft: 8,
  },
  resultName: {
    marginLeft: 8,
  },
});
