import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LastWeekRecapQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.WeeklyRecapInput>;
}>;


export type LastWeekRecapQuery = { __typename?: 'Query', weeklyRecap: { __typename?: 'WeeklyRecap', numTraineesCompletedACourse: number, numInvited: number, numCoursesCreated: number, numCoursesPublished: number } };


export const LastWeekRecapDocument = gql`
    query LastWeekRecap($input: WeeklyRecapInput) {
  weeklyRecap: AdminWeeklyRecap(input: $input) {
    numTraineesCompletedACourse
    numInvited
    numCoursesCreated
    numCoursesPublished
  }
}
    `;

/**
 * __useLastWeekRecapQuery__
 *
 * To run a query within a React component, call `useLastWeekRecapQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastWeekRecapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastWeekRecapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLastWeekRecapQuery(baseOptions?: Apollo.QueryHookOptions<LastWeekRecapQuery, LastWeekRecapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastWeekRecapQuery, LastWeekRecapQueryVariables>(LastWeekRecapDocument, options);
      }
export function useLastWeekRecapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastWeekRecapQuery, LastWeekRecapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastWeekRecapQuery, LastWeekRecapQueryVariables>(LastWeekRecapDocument, options);
        }
export type LastWeekRecapQueryHookResult = ReturnType<typeof useLastWeekRecapQuery>;
export type LastWeekRecapLazyQueryHookResult = ReturnType<typeof useLastWeekRecapLazyQuery>;
export type LastWeekRecapQueryResult = Apollo.QueryResult<LastWeekRecapQuery, LastWeekRecapQueryVariables>;