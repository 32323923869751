import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import useBrandColor from "@hooks/useBrandColor";

type Props = {
  children: ReactNode;
};

const RecapRow: FC<Props> = ({ children }) => {
  const brandColor = useBrandColor();
  return (
    <AutoLayout
      direction="horizontal"
      alignmentVertical="center"
      flex={1}
      alignSelf="stretch"
      styleDeclaration={styles.container}
    >
      <DeprecatedIcon
        type="arrow-right"
        color={brandColor}
        style={{ opacity: 0.6, marginRight: 14 }}
      />
      {children}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default RecapRow;
