import ContextMenuItem, {
  ContextMenuItemProps,
} from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { GraphqlOperations } from "@src/types.generated";
import { FC, useMemo } from "react";
import ArchiveConfirmationModal from "@components/modals/ArchiveConfirmationModal";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import { gql } from "@apollo/client";
import { useCopySkillMutation } from "./SkillOverflowMenu.generated";
import useActionPermissions from "@src/hooks/useActionPermissions";
import useContentPermissions from "../libraryItemDetailPages/hooks/useContentPermissions";
import TranslateTextModal from "../modals/translationSetModals/TranslateTextModal";
import { SkillLibrary } from "./hooks/useLibraryTableRowRenderFunctions";
import { useArchiveSkillMutation } from "@src/components/libraryItemDetailPages/LibraryItemDetailPageContainer.generated";

type Props = {
  libraryItem: SkillLibrary;
  className?: string;
  iconClassName?: string;
  rotateIcon?: boolean;
  isVisible: boolean;
};

const SkillOverflowMenu: FC<Props> = ({ libraryItem }) => {
  const skill = libraryItem.skill;
  const { userActionPermissions } = useActionPermissions();
  const contentPermissions = useContentPermissions({ skillId: skill.id });
  const { addToast, addErrorToast } = useToast();
  const { showModal } = useModal();
  const [copySkill] = useCopySkillMutation({
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    variables: {
      id: skill.id,
    },
    onCompleted: (data) => {
      if (data.copySkill.success) {
        addToast({
          iconType: "star",
          message: `${libraryItem.name.en} successfully duplicated 🎉`,
        });
      } else {
        addErrorToast({
          ...data.copySkill,
          callsite: "duplicate_skill_overflow",
        });
      }
    },
  });
  const [archiveSkill] = useArchiveSkillMutation({
    refetchQueries: [
      ...CONTENT_LIBRARY_REFETCH_QUERIES,
      GraphqlOperations.Query.SkillDetailOverview,
    ],
    onCompleted: (data) => {
      if (data.archiveSkill.success) {
        addToast({
          message: "Check-in successfully archived",
          iconType: "trash-2",
        });
      } else {
        addErrorToast({
          ...data.archiveSkill,
          callsite: "archive_skill_overflow",
        });
      }
    },
  });
  const options: ContextMenuItemProps[] = [];
  const archive: ContextMenuItemProps = useMemo(() => {
    return {
      icon: "trash-2",
      label: "Archive",
      disabled: !userActionPermissions?.manageLibrary,
      onSelect: () => {
        showModal(
          <ArchiveConfirmationModal
            title={"Archive Check-in"}
            itemName={libraryItem.name.en}
            onArchive={() => {
              archiveSkill({
                variables: {
                  skillId: skill?.id || -1,
                  archive: true,
                },
              });
            }}
            includeModuleCopy
          />,
        );
      },
    };
  }, [
    archiveSkill,
    showModal,
    skill?.id,
    libraryItem.name,
    userActionPermissions?.manageLibrary,
  ]);
  const unarchive: ContextMenuItemProps = useMemo(() => {
    return {
      icon: "undo",
      label: "Unarchive",
      disabled: !userActionPermissions?.manageLibrary,
      onSelect: () => {
        archiveSkill({
          variables: { skillId: skill.id, archive: false },
        });
      },
    };
  }, [archiveSkill, skill.id, userActionPermissions?.manageLibrary]);
  const rename: ContextMenuItemProps = useMemo(() => {
    return {
      icon: "pencil",
      label: "Rename",
      disabled: !contentPermissions?.hasEditContentPermission,
      onSelect: () => {
        showModal(
          <TranslateTextModal
            label="Name"
            title="Edit Check-in Name"
            translationSetUuid={libraryItem.name.uuid!}
          />,
        );
      },
    };
  }, [
    contentPermissions?.hasEditContentPermission,
    showModal,
    libraryItem.name.uuid,
  ]);
  const duplicate: ContextMenuItemProps = useMemo(() => {
    return {
      icon: "copy",
      label: "Duplicate",
      disabled: !userActionPermissions?.createContent,
      onSelect: () => {
        copySkill();
      },
    };
  }, [copySkill, userActionPermissions?.createContent]);

  if (libraryItem.archivedAt) {
    options.push(unarchive);
  } else {
    options.push(rename, duplicate, archive);
  }

  return (
    <>
      {options.map((x) => (
        <ContextMenuItem key={x.label} {...x} />
      ))}
    </>
  );
};

export default SkillOverflowMenu;

gql`
  mutation CopySkill($id: Int!) {
    copySkill(id: $id) {
      success
      skill {
        id
      }
      error {
        code
      }
    }
  }
`;
