import Modal from "@components/modals/Modal";
import { useDeleteLocationGroupsMutation } from "@components/people/operations.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { css, StyleSheet } from "aphrodite";
import { useRouter } from "next/router";
import { FC } from "react";
import { GraphqlOperations } from "@src/types.generated";
import { locationGroupTypeDisplayName } from "@components/home/constants";
import { gql } from "@apollo/client";
import { DeleteLocationGroupModal_LocationGroupFragment } from "@components/modals/DeleteLocationGroupModal.generated";

type Props = {
  locationGroup: DeleteLocationGroupModal_LocationGroupFragment;
};

const DeleteLocationGroupModal: FC<Props> = ({ locationGroup }) => {
  const router = useRouter();
  const { closeModal } = useModal();
  const { addToast, addErrorToast } = useToast();
  const typeName = locationGroupTypeDisplayName[locationGroup.type];
  const [deleteLocationGroupMutation, { loading }] =
    useDeleteLocationGroupsMutation({
      variables: {
        ids: [locationGroup.id],
      },
      refetchQueries: [
        GraphqlOperations.Query.GetLocations,
        GraphqlOperations.Query.GetLocationGroups,
        GraphqlOperations.Query.RecentlyViewedLocationGroups,
        GraphqlOperations.Query.OrgDetail_LocationGroups,
      ],
      onCompleted: (data) => {
        if (!data.deleteLocationGroups.success) {
          addErrorToast({ ...data, callsite: "delete_location_group" });
        } else {
          closeModal();
          addToast({
            message: `${typeName} deleted`,
            iconType: "checkmark",
          });
          router.push({
            pathname: "/",
            query: {
              tab: locationGroup.type,
            },
          });
        }
      },
      onError: (data) =>
        addErrorToast({ ...data, callsite: "delete_location_group" }),
    });
  return (
    <Modal
      title={`Delete ${typeName.toLowerCase()}`}
      footerClassName={css(styles.modalFooter)}
      confirmButtonProps={{
        copy: "Delete",
        onClick: deleteLocationGroupMutation,
        alert: true,
        loading,
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <AutoLayout direction="vertical" styleDeclaration={styles.body}>
        <Text type="P1" style={{ whiteSpace: "normal" }}>
          Are you sure you want to delete {locationGroup.name}?
        </Text>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 24,
    fontSize: 14,
  },
  modalFooter: {
    borderTop: "none",
  },
});

export default DeleteLocationGroupModal;

gql`
  fragment DeleteLocationGroupModal_LocationGroup on LocationGroup {
    id
    name
    type
  }
`;
