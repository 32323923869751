import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, MediaFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagDropdownContentTagFragment = { __typename?: 'Tag', id: string, libraryItemCount: number, availableInApp: boolean, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } };

export type TagDropdownContentTagsQueryVariables = Types.Exact<{
  input: Types.TagsInput;
}>;


export type TagDropdownContentTagsQuery = { __typename?: 'Query', Tags: Array<{ __typename?: 'Tag', id: string, libraryItemCount: number, availableInApp: boolean, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } }> };

export const TagDropdownContentTagFragmentDoc = gql`
    fragment TagDropdownContentTag on Tag {
  id
  nameTranslations {
    ...TranslationSet
  }
  libraryItemCount
  availableInApp
}
    ${TranslationSetFragmentDoc}`;
export const TagDropdownContentTagsDocument = gql`
    query TagDropdownContentTags($input: TagsInput!) {
  Tags(input: $input) {
    ...TagDropdownContentTag
  }
}
    ${TagDropdownContentTagFragmentDoc}`;

/**
 * __useTagDropdownContentTagsQuery__
 *
 * To run a query within a React component, call `useTagDropdownContentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagDropdownContentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagDropdownContentTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagDropdownContentTagsQuery(baseOptions: Apollo.QueryHookOptions<TagDropdownContentTagsQuery, TagDropdownContentTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagDropdownContentTagsQuery, TagDropdownContentTagsQueryVariables>(TagDropdownContentTagsDocument, options);
      }
export function useTagDropdownContentTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagDropdownContentTagsQuery, TagDropdownContentTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagDropdownContentTagsQuery, TagDropdownContentTagsQueryVariables>(TagDropdownContentTagsDocument, options);
        }
export type TagDropdownContentTagsQueryHookResult = ReturnType<typeof useTagDropdownContentTagsQuery>;
export type TagDropdownContentTagsLazyQueryHookResult = ReturnType<typeof useTagDropdownContentTagsLazyQuery>;
export type TagDropdownContentTagsQueryResult = Apollo.QueryResult<TagDropdownContentTagsQuery, TagDropdownContentTagsQueryVariables>;