import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { RoleWithPermissionsFragmentDoc } from './NewRoleModal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'RoleMutationResult', success: boolean, role?: { __typename?: 'Role', id: number, name: string, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }>, roleActionPermissions?: { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } } | null, directReports: Array<{ __typename?: 'Role', id: number, name: string }> } | null } };


export const UpdateRoleDocument = gql`
    mutation updateRole($id: Int!, $input: UpdateRoleInput!) {
  updateRole(id: $id, input: $input) {
    success
    role {
      ...RoleWithPermissions
    }
  }
}
    ${RoleWithPermissionsFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;