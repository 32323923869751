import React, { FC, useCallback, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TagPill from "@components/ui/TagPill";
import Spacer from "@components/ui/Spacer";
import useTagContext from "@contexts/TagContext/hooks/useTagContext";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useActionPermissions from "@src/hooks/useActionPermissions";
import useGetTagIdsFromLibraryItems from "./hooks/useGetTagIdsFromLibraryItems";
import { LibraryContainerLibraryItemFragment } from "./LibraryContainer.generated";
import TagSelectDropdown from "@src/components/tags/TagSelectDropdown";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";

type Props = {
  item: LibraryContainerLibraryItemFragment;
};

const LibraryItemTagCell: FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false);
  const { userActionPermissions } = useActionPermissions();
  const disabled = !userActionPermissions?.manageLibrary;
  const tagContext = useTagContext();
  const tagIds = useGetTagIdsFromLibraryItems([item]);

  const onSelectTag = useCallback(
    (tagId: string) => {
      tagContext.createContentTagMemberships([
        { libraryItemId: item.id, tagId },
      ]);
    },
    [item, tagContext],
  );
  const onUnselectTag = useCallback(
    (tagId: string) => {
      tagContext.deleteContentTagMemberships([
        { libraryItemId: item.id, tagId },
      ]);
    },
    [item, tagContext],
  );

  return (
    <Popover
      open={open}
      onOpenChange={() => {
        if (!disabled) {
          setOpen(!open);
        }
      }}
    >
      <PopoverTrigger asChild>
        <AutoLayout
          className={css(
            styles.tagCell,
            styles.horizontalScrollContainer,
            disabled && styles.disabled,
          )}
          direction={"horizontal"}
          flex={1}
          alignSelf={"stretch"}
        >
          {tagIds.map((x) => {
            return (
              <AutoLayout direction={"horizontal"} width={"hug"} key={x}>
                <TagPill tagId={x} />
                <Spacer size={4} axis={"horizontal"} />
              </AutoLayout>
            );
          })}
        </AutoLayout>
      </PopoverTrigger>
      <PopoverContent align={"start"} side={"bottom"} className="w-[300px] p-0">
        <TagSelectDropdown
          selectedTagIds={tagIds}
          onSelectTag={onSelectTag}
          onUnselectTag={onUnselectTag}
        />
      </PopoverContent>
    </Popover>
  );
};

const styles = StyleSheet.create({
  container: {},
  tagCell: {
    textDecoration: "none",
    color: "unset",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: 8,
    marginLeft: -8,
    paddingRight: 8,
    marginRight: -8,
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
  },
  disabled: {
    cursor: "unset",
    ":hover": {
      backgroundColor: "unset",
    },
  },
  horizontalScrollContainer: {
    flex: 1,
    alignSelf: "stretch",
    overflowX: "auto",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
});

export default LibraryItemTagCell;
