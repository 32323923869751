import ArchiveConfirmationModal from "@components/modals/ArchiveConfirmationModal";
import ContextMenuItem, {
  ContextMenuItemProps,
} from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { FC } from "react";
import TranslateTextModal from "../modals/translationSetModals/TranslateTextModal";
import useContentPermissions from "@src/components/libraryItemDetailPages/hooks/useContentPermissions";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import CopyPathModal from "../libraryItemDetailPages/module/build/CopyPathModal";
import { PathLibrary } from "./hooks/useLibraryTableRowRenderFunctions";
import AutomationModal from "./assignmentModals/AutomationModal";
import { isUserSelectionEmpty } from "@src/utils/assignments";
import { useLibraryItemAccessAutomationLazyQuery } from "./LibraryContainer.generated";
import { useArchivePathMutation } from "@src/components/libraryItemDetailPages/LibraryItemDetailPageContainer.generated";

type Props = {
  libraryItem: PathLibrary;
  className?: string;
  iconClassName?: string;
  rotateIcon?: boolean;
  isVisible: boolean;
};

const PathOverflowMenu: FC<Props> = ({ libraryItem }) => {
  const path = libraryItem.path;
  const { userActionPermissions } = useActionPermissions();
  const permissions = useContentPermissions({ pathId: path.id });
  const { addToast, addErrorToast } = useToast();
  const [archivePath] = useArchivePathMutation({
    variables: {
      pathId: path.id,
      archive: true,
    },
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    onCompleted: (data) => {
      if (data.archivePath.success) {
        addToast({
          message: "Module successfully archived",
          iconType: "trash-2",
        });
      } else {
        addErrorToast({
          ...data.archivePath,
          callsite: "archive_path_overflow",
        });
      }
    },
  });

  const { showModal } = useModal();
  const options: ContextMenuItemProps[] = [];

  const [getAccessAutomation] = useLibraryItemAccessAutomationLazyQuery();

  if (libraryItem.archivedAt) {
    options.push({
      icon: "undo",
      label: "Unarchive",
      disabled: !userActionPermissions?.manageLibrary,
      onSelect: () => {
        archivePath({
          variables: { pathId: path.id, archive: false },
        });
      },
    });
  } else {
    options.push({
      icon: "pencil",
      label: "Rename",
      disabled: !permissions?.hasEditContentPermission,
      onSelect: () => {
        showModal(
          <TranslateTextModal
            label={"Name"}
            title={"Edit Path Name"}
            translationSetUuid={libraryItem.name.uuid!}
          />,
        );
      },
    });
    if (path.publishedVersion) {
      options.push({
        icon: "book-open",
        label: "Update Access",
        disabled: !userActionPermissions?.updateAutomations,
        onSelect: () => {
          getAccessAutomation({
            variables: {
              id: libraryItem.id,
            },
          }).then(({ data }) => {
            const automation = data?.LibraryItem?.accessAutomation;
            if (!automation) {
              return;
            }
            const existingAutomation = !isUserSelectionEmpty(
              automation.userSelection,
            );
            showModal(
              <AutomationModal
                automationItem={{
                  automation,
                  contentName: libraryItem.name.en,
                  __typename: "Path",
                }}
                existingAutomation={existingAutomation}
              />,
            );
          });
        },
      });
    }
    options.push(
      {
        icon: "copy",
        label: "Duplicate",
        disabled: !userActionPermissions?.createContent,
        onSelect: () => {
          showModal(<CopyPathModal pathId={path.id} />);
        },
      },
      {
        icon: "trash-2",
        label: "Archive",
        disabled: !userActionPermissions?.manageLibrary,
        onSelect: () =>
          showModal(
            <ArchiveConfirmationModal
              onArchive={archivePath}
              title={`Archive Module`}
              itemName={libraryItem.name.en}
            />,
          ),
      },
    );
  }
  if (!permissions) return null;
  return (
    <>
      {options.map((x) => (
        <ContextMenuItem key={x.label} {...x} />
      ))}
    </>
  );
};

export default PathOverflowMenu;
