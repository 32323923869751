import { AddSharpVersionModal_PremiumContentConfigFragment } from "./AddSharpVersionModal.generated";
import { OrgPremiumContentConfigFragment } from "./../contentLibrary/LibraryContainer.generated";
import { AssignedUserType } from "@src/types.generated";

import { gql } from "@apollo/client";
import { IsSharpSetupCompleteFragment } from "./utils.generated";

export const ASSIGNED_USER_TYPE_COPY: { [key in AssignedUserType]: string } = {
  [AssignedUserType.All]: "All Users",
  [AssignedUserType.Supervisors]: "Supervisor",
  [AssignedUserType.NonSupervisors]: "Non-Supervisor",
};

export const StatesNameMap = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "Washington, D.C.",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const STATES_NOT_COMPLIANT_WITH_FUNDAMENTALS: USState[] = [
  "CA",
  "CT",
  "DE",
  "IL",
  "ME",
  "NY",
  "DC",
];

export const FOUNDATIONS_COURSE = "Fundamentals (All Other States)";
const NY_COPY = "New York & NYC";
const IL_COPY = "Illinois & Chicago";

export const getUSStateLabel = (
  usState: USState | null | undefined,
): string => {
  if (usState == null) {
    return FOUNDATIONS_COURSE;
  }
  if (usState === "NY") {
    return NY_COPY;
  }
  if (usState === "IL") {
    return IL_COPY;
  }
  return StatesNameMap[usState];
};

export interface GroupedOrgConfig {
  stateLabel: string;
  state: USState | null;
  supervisorVersion: OrgPremiumContentConfigFragment | null;
  nonSupervisorVersion: OrgPremiumContentConfigFragment | null;
  catalogPathIds: string[];
  orgPremiumContentConfigIds: number[];
  complianceDurationYears: number;
}

export const groupOrgConfigVersionsFromOrgConfigs = (
  orgPremiumContentConfigs: OrgPremiumContentConfigFragment[] | undefined,
): GroupedOrgConfig[] => {
  if (orgPremiumContentConfigs === undefined) {
    return [];
  }
  return orgPremiumContentConfigs.reduce(
    (acc: GroupedOrgConfig[], obj: OrgPremiumContentConfigFragment) => {
      const usStateLabel = getUSStateLabel(obj.premiumContentConfig.usState);
      let versionObj = acc.find((o) => o.stateLabel === usStateLabel);
      if (!versionObj) {
        versionObj = {
          stateLabel: usStateLabel,
          state: obj.premiumContentConfig.usState || null,
          supervisorVersion: null,
          nonSupervisorVersion: null,
          catalogPathIds: [],
          orgPremiumContentConfigIds: [],
          complianceDurationYears:
            obj.premiumContentConfig.complianceDurationYears,
        };
        acc.push(versionObj);
      }
      versionObj.orgPremiumContentConfigIds.push(obj.id);
      if (obj.premiumContentConfig.catalogPath?.id) {
        versionObj.catalogPathIds.push(obj.premiumContentConfig.catalogPath.id);
      }
      if (
        obj.premiumContentConfig.assignedUserType ===
        AssignedUserType.Supervisors
      ) {
        versionObj.supervisorVersion = obj;
      }
      if (
        obj.premiumContentConfig.assignedUserType ===
        AssignedUserType.NonSupervisors
      ) {
        versionObj.nonSupervisorVersion = obj;
      }
      return acc;
    },
    [],
  );
};

export interface GroupedPremiumConfig {
  stateLabel: string;
  state: USState | null;
  supervisorVersion: AddSharpVersionModal_PremiumContentConfigFragment | null;
  nonSupervisorVersion: AddSharpVersionModal_PremiumContentConfigFragment | null;
  catalogPathIds: string[];
}

export const groupSharpVersionsFromPremiumConfigs = (
  premiumContentConfigs:
    | AddSharpVersionModal_PremiumContentConfigFragment[]
    | undefined,
): GroupedPremiumConfig[] => {
  if (premiumContentConfigs === undefined) {
    return [];
  }
  return premiumContentConfigs.reduce(
    (
      acc: GroupedPremiumConfig[],
      obj: AddSharpVersionModal_PremiumContentConfigFragment,
    ) => {
      const usStateLabel = getUSStateLabel(obj.usState);
      let versionObj = acc.find((o) => o.stateLabel === usStateLabel);
      if (!versionObj) {
        versionObj = {
          stateLabel: usStateLabel,
          state: obj.usState || null,
          supervisorVersion: null,
          nonSupervisorVersion: null,
          catalogPathIds: [],
        };
        acc.push(versionObj);
      }
      if (obj.catalogPath?.id) {
        versionObj.catalogPathIds.push(obj.catalogPath.id);
      }
      if (obj.assignedUserType === AssignedUserType.Supervisors) {
        versionObj.supervisorVersion = obj;
      }
      if (obj.assignedUserType === AssignedUserType.NonSupervisors) {
        versionObj.nonSupervisorVersion = obj;
      }
      return acc;
    },
    [],
  );
};

export const createSetFromPremiumConfigs = (
  premiumConfigs: GroupedOrgConfig[] | GroupedPremiumConfig[],
): Set<string> => {
  const premiumConfigsSet = new Set<string>();
  premiumConfigs.forEach(
    (premiumConfig: GroupedOrgConfig | GroupedPremiumConfig) => {
      premiumConfigsSet.add(premiumConfig.stateLabel);
    },
  );
  return premiumConfigsSet;
};

const sortConfigs = (
  a: GroupedOrgConfig | GroupedPremiumConfig,
  b: GroupedOrgConfig | GroupedPremiumConfig,
) => {
  if (!a.state && b.state) return -1;
  if (a.state && !b.state) return 1;
  return a.stateLabel.localeCompare(b.stateLabel);
};

export const sortGroupedPremiumConfigs = (
  groupedConfigs: GroupedPremiumConfig[],
): GroupedPremiumConfig[] => {
  return groupedConfigs.sort(sortConfigs);
};

export const sortGroupedOrgConfigs = (
  groupedConfigs: GroupedOrgConfig[],
): GroupedOrgConfig[] => {
  return groupedConfigs.sort(sortConfigs);
};

export const isSharpSetupComplete = (
  orgSharpConfig: IsSharpSetupCompleteFragment | null,
): boolean => {
  return Boolean(
    orgSharpConfig?.sharpPolicy?.mediaUrls?.en && orgSharpConfig?.hrEmail,
  );
};

gql(
  `fragment IsSharpSetupComplete on OrgSharpConfig {
    sharpPolicy {
      id
      mediaUrls {
        en
      }
    }
    hrEmail
  }`,
);
