import { FC, useState } from "react";
import { TagDropdownContentTagFragment } from "./TagSelectDropdown.generated";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@src/ui/dialog";
import TranslationTextField from "@src/deprecatedDesignSystem/components/TranslationTextField";
import { Checkbox } from "@src/ui/checkbox";
import { Button } from "@src/ui/button";
import useTagContext from "@src/contexts/TagContext/hooks/useTagContext";

type Props = {
  tag: TagDropdownContentTagFragment;
  onClose: () => void;
};
export const EditTagModal: FC<Props> = ({ tag, onClose }) => {
  const tagContext = useTagContext();
  const [availableInApp, setAvailableInApp] = useState(tag.availableInApp);
  const [nameTranslations, setNameTranslations] = useState(
    tag.nameTranslations,
  );

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Tag</DialogTitle>
        </DialogHeader>

        {tag && (
          <TranslationTextField
            value={nameTranslations}
            onChange={(translationSet) => {
              setNameTranslations(translationSet);
            }}
          />
        )}
        <div className="flex space-x-2">
          <Checkbox
            id="availableInApp"
            checked={availableInApp}
            onCheckedChange={(checked) => setAvailableInApp(!!checked)}
          />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="availableInApp"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Visible to employees
            </label>
            <p className="text-sm text-muted-foreground">
              Let employees use this tag to find content they have access to
            </p>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (tag) {
                tagContext.editTag(tag.id, {
                  nameTranslations,
                  availableInApp,
                });
                onClose();
              }
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditTagModal;
