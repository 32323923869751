import useActionPermissions from "@src/hooks/useActionPermissions";
import { useModal } from "@src/hooks/useModal";
import { FC, useCallback } from "react";
import NoPermissionsTooltipWrapper from "../ui/NoPermissionsTooltipWrapper";
import Button from "@src/deprecatedDesignSystem/components/Button";
import RoleGroupModal from "@components/home/RoleGroupModal";

const NewRoleGroupButton: FC = () => {
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const handleOnClick = useCallback(() => {
    showModal(<RoleGroupModal />);
  }, [showModal]);

  if (!userActionPermissions?.changeOrgSettings) {
    return (
      <NoPermissionsTooltipWrapper
        hasPermission={false}
        caretPosition="Top"
        tooltipSide="bottom"
      >
        <Button disabled text="New Department" onClick={handleOnClick} />
      </NoPermissionsTooltipWrapper>
    );
  }

  return (
    <Button text="New Department" onClick={handleOnClick} leftIcon="plus" />
  );
};

export default NewRoleGroupButton;
