import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { AddressFragmentDoc } from '../../contexts/LocationDetailContext/LocationDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationsTable_LocationsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.LocationsInput>;
}>;


export type LocationsTable_LocationsQuery = { __typename?: 'Query', Locations: { __typename?: 'Locations', objects: Array<{ __typename?: 'Location', id: number, name: string, userCount: number, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null, locationGroupMemberships: Array<{ __typename?: 'LocationGroupMembership', locationGroup: { __typename?: 'LocationGroup', id: string, type: Types.LocationGroupType, name: string } }> }> } };

export type LocationsTable_LocationFragment = { __typename?: 'Location', id: number, name: string, userCount: number, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null, locationGroupMemberships: Array<{ __typename?: 'LocationGroupMembership', locationGroup: { __typename?: 'LocationGroup', id: string, type: Types.LocationGroupType, name: string } }> };

export const LocationsTable_LocationFragmentDoc = gql`
    fragment LocationsTable_Location on Location {
  id
  name
  userCount
  address {
    ...Address
  }
  locationGroupMemberships {
    locationGroup {
      id
      type
      name
    }
  }
}
    ${AddressFragmentDoc}`;
export const LocationsTable_LocationsDocument = gql`
    query LocationsTable_Locations($input: LocationsInput) {
  Locations(input: $input) {
    objects {
      ...LocationsTable_Location
    }
  }
}
    ${LocationsTable_LocationFragmentDoc}`;

/**
 * __useLocationsTable_LocationsQuery__
 *
 * To run a query within a React component, call `useLocationsTable_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsTable_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsTable_LocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationsTable_LocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsTable_LocationsQuery, LocationsTable_LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsTable_LocationsQuery, LocationsTable_LocationsQueryVariables>(LocationsTable_LocationsDocument, options);
      }
export function useLocationsTable_LocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsTable_LocationsQuery, LocationsTable_LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsTable_LocationsQuery, LocationsTable_LocationsQueryVariables>(LocationsTable_LocationsDocument, options);
        }
export type LocationsTable_LocationsQueryHookResult = ReturnType<typeof useLocationsTable_LocationsQuery>;
export type LocationsTable_LocationsLazyQueryHookResult = ReturnType<typeof useLocationsTable_LocationsLazyQuery>;
export type LocationsTable_LocationsQueryResult = Apollo.QueryResult<LocationsTable_LocationsQuery, LocationsTable_LocationsQueryVariables>;