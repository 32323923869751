import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRoleLevelsMutationVariables = Types.Exact<{
  input: Types.UpdateRoleLevelsInput;
}>;


export type UpdateRoleLevelsMutation = { __typename?: 'Mutation', updateRoleLevels: { __typename?: 'RolesMutationResult', success: boolean, roles?: Array<{ __typename?: 'Role', id: number, name: string, roleLevel?: number | null }> | null } };

export type RoleLevelFragment = { __typename?: 'Role', id: number, name: string, roleLevel?: number | null };

export const RoleLevelFragmentDoc = gql`
    fragment RoleLevel on Role {
  id
  name
  roleLevel
}
    `;
export const UpdateRoleLevelsDocument = gql`
    mutation updateRoleLevels($input: UpdateRoleLevelsInput!) {
  updateRoleLevels(input: $input) {
    success
    roles {
      ...RoleLevel
    }
  }
}
    ${RoleLevelFragmentDoc}`;
export type UpdateRoleLevelsMutationFn = Apollo.MutationFunction<UpdateRoleLevelsMutation, UpdateRoleLevelsMutationVariables>;

/**
 * __useUpdateRoleLevelsMutation__
 *
 * To run a mutation, you first call `useUpdateRoleLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleLevelsMutation, { data, loading, error }] = useUpdateRoleLevelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleLevelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleLevelsMutation, UpdateRoleLevelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleLevelsMutation, UpdateRoleLevelsMutationVariables>(UpdateRoleLevelsDocument, options);
      }
export type UpdateRoleLevelsMutationHookResult = ReturnType<typeof useUpdateRoleLevelsMutation>;
export type UpdateRoleLevelsMutationResult = Apollo.MutationResult<UpdateRoleLevelsMutation>;
export type UpdateRoleLevelsMutationOptions = Apollo.BaseMutationOptions<UpdateRoleLevelsMutation, UpdateRoleLevelsMutationVariables>;