import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuestFeedbackAiWorkflowQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GuestFeedbackAiWorkflowQuery = { __typename?: 'Query', GuestFeedbackAiWorkflow?: { __typename?: 'GuestFeedbackAiWorkflow', id: string, categories: Array<{ __typename?: 'GuestFeedbackCategory', id: string, name: string, topLevelCategoryName: string, aiTrainingSuggestion: string, aiCourseOutline: string, isApprovedForDisplay: boolean, guestFeedbackMemberships: Array<{ __typename?: 'GuestFeedbackCategoryMembership', id: string, guestFeedback: { __typename?: 'GuestFeedback', id: string, text?: string | null, locationName: string, starRating: number, parts: Array<{ __typename?: 'GuestFeedbackPart', id: string, question: string, answer: string }> } }> }> } | null };

export type GuestFeedbackAiWorkflowFragment = { __typename?: 'GuestFeedbackAiWorkflow', id: string, categories: Array<{ __typename?: 'GuestFeedbackCategory', id: string, name: string, topLevelCategoryName: string, aiTrainingSuggestion: string, aiCourseOutline: string, isApprovedForDisplay: boolean, guestFeedbackMemberships: Array<{ __typename?: 'GuestFeedbackCategoryMembership', id: string, guestFeedback: { __typename?: 'GuestFeedback', id: string, text?: string | null, locationName: string, starRating: number, parts: Array<{ __typename?: 'GuestFeedbackPart', id: string, question: string, answer: string }> } }> }> };

export type GuestFeedbackCategoryFragment = { __typename?: 'GuestFeedbackCategory', id: string, name: string, topLevelCategoryName: string, aiTrainingSuggestion: string, aiCourseOutline: string, isApprovedForDisplay: boolean, guestFeedbackMemberships: Array<{ __typename?: 'GuestFeedbackCategoryMembership', id: string, guestFeedback: { __typename?: 'GuestFeedback', id: string, text?: string | null, locationName: string, starRating: number, parts: Array<{ __typename?: 'GuestFeedbackPart', id: string, question: string, answer: string }> } }> };

export type GuestFeedbackCategoryMembershipFragment = { __typename?: 'GuestFeedbackCategoryMembership', id: string, guestFeedback: { __typename?: 'GuestFeedback', id: string, text?: string | null, locationName: string, starRating: number, parts: Array<{ __typename?: 'GuestFeedbackPart', id: string, question: string, answer: string }> } };

export const GuestFeedbackCategoryMembershipFragmentDoc = gql`
    fragment GuestFeedbackCategoryMembership on GuestFeedbackCategoryMembership {
  id
  guestFeedback {
    id
    text
    locationName
    starRating
    parts {
      id
      question
      answer
    }
  }
}
    `;
export const GuestFeedbackCategoryFragmentDoc = gql`
    fragment GuestFeedbackCategory on GuestFeedbackCategory {
  id
  name
  topLevelCategoryName
  aiTrainingSuggestion
  aiCourseOutline
  isApprovedForDisplay
  guestFeedbackMemberships {
    ...GuestFeedbackCategoryMembership
  }
}
    ${GuestFeedbackCategoryMembershipFragmentDoc}`;
export const GuestFeedbackAiWorkflowFragmentDoc = gql`
    fragment GuestFeedbackAiWorkflow on GuestFeedbackAiWorkflow {
  id
  categories {
    ...GuestFeedbackCategory
  }
}
    ${GuestFeedbackCategoryFragmentDoc}`;
export const GuestFeedbackAiWorkflowDocument = gql`
    query GuestFeedbackAiWorkflow {
  GuestFeedbackAiWorkflow {
    ...GuestFeedbackAiWorkflow
  }
}
    ${GuestFeedbackAiWorkflowFragmentDoc}`;

/**
 * __useGuestFeedbackAiWorkflowQuery__
 *
 * To run a query within a React component, call `useGuestFeedbackAiWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestFeedbackAiWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestFeedbackAiWorkflowQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestFeedbackAiWorkflowQuery(baseOptions?: Apollo.QueryHookOptions<GuestFeedbackAiWorkflowQuery, GuestFeedbackAiWorkflowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestFeedbackAiWorkflowQuery, GuestFeedbackAiWorkflowQueryVariables>(GuestFeedbackAiWorkflowDocument, options);
      }
export function useGuestFeedbackAiWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestFeedbackAiWorkflowQuery, GuestFeedbackAiWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestFeedbackAiWorkflowQuery, GuestFeedbackAiWorkflowQueryVariables>(GuestFeedbackAiWorkflowDocument, options);
        }
export type GuestFeedbackAiWorkflowQueryHookResult = ReturnType<typeof useGuestFeedbackAiWorkflowQuery>;
export type GuestFeedbackAiWorkflowLazyQueryHookResult = ReturnType<typeof useGuestFeedbackAiWorkflowLazyQuery>;
export type GuestFeedbackAiWorkflowQueryResult = Apollo.QueryResult<GuestFeedbackAiWorkflowQuery, GuestFeedbackAiWorkflowQueryVariables>;