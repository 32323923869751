import { SmallDetailPageListItem } from "../locationGroupDetail/SmallDetailPageList";
import IconAvatar from "@components/people/IconAvatar";
import {
  RoleDetail_RoleGroupFragment,
  RoleDetailDirectReportFragment,
} from "./RoleDetail.generated";
import { RoleThinFragment } from "@src/hooks/useRoles.generated";
import { RoleLevelFragment } from "./modals/EditHierarchyLevelsModal.generated";
import { getRoleDetailRoute } from "../utils/getDetailRoute";

export const roleGroupToItems = (
  roleGroup: RoleDetail_RoleGroupFragment,
): SmallDetailPageListItem => {
  return {
    key: roleGroup.id,
    avatar: <IconAvatar type="roleGroup" />,
    title: {
      text: roleGroup.name,
      link: {
        pathname: "/role-groups/[id]",
        query: {
          id: roleGroup.id,
        },
      },
    },
    subtitle: [
      `Roles: ${roleGroup.roleCount}`,
      `People: ${roleGroup.userCount}`,
    ],
  };
};

export const directReportsToItems = (
  directReports: RoleDetailDirectReportFragment[],
): SmallDetailPageListItem[] => {
  return (
    directReports.map((report) => ({
      key: report.id.toString(),
      avatar: <IconAvatar type="role" />,
      title: {
        text: report.name,
        link: getRoleDetailRoute(report.id),
      },
      subtitle: [],
    })) || []
  );
};

export type RoleLevelRoleFragment = Pick<RoleLevelFragment, "id" | "name">;
export type RoleLevel = {
  roles: RoleLevelRoleFragment[];
};

export const initializeRoleLevelsFromRoles = (
  roles: RoleThinFragment[],
): RoleLevel[] => {
  const roleLevels: RoleLevel[] = [];
  const lowestRoleLevel = roles.reduce(
    (acc, role) => Math.max(acc, role.roleLevel || 0),
    0,
  );
  Array.from({ length: lowestRoleLevel }, () => {
    roleLevels.push({ roles: [] });
  });
  roles.forEach((role) => {
    if (!role.roleLevel) return;
    roleLevels[role.roleLevel - 1].roles.push({ id: role.id, name: role.name });
  });
  return roleLevels;
};
