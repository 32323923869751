import React, { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import IconAvatar from "@components/people/IconAvatar";
import Spacer from "@components/ui/Spacer";
import Text from "@ui/text";

type Props = {
  name: string;
  logoUrl?: string;
  avatarType: React.ComponentProps<typeof IconAvatar>["type"];
};

const TableRowAvatarAndName: FC<Props> = (props) => {
  return (
    <AutoLayout alignmentVertical="center" spaceBetweenItems={8} flex={1}>
      <AutoLayout alignmentVertical="center" flex={1}>
        <IconAvatar type={props.avatarType} logoUrl={props.logoUrl} />
        <Spacer size={12} axis={"horizontal"} />
        <AutoLayout flex={1}>
          <Text style={{ flex: 1 }} type={"P2"} fontWeight={"Medium"} ellipsis>
            {props.name}
          </Text>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

export default TableRowAvatarAndName;
