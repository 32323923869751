import Modal from "@components/modals/Modal";
import { useDeleteRoleGroupsMutation } from "@components/people/operations.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { css, StyleSheet } from "aphrodite";
import { useRouter } from "next/router";
import { FC } from "react";
import { GraphqlOperations } from "@src/types.generated";
import { gql } from "@apollo/client";
import { DeleteRoleGroupModal_RoleGroupFragment } from "@components/modals/DeleteRoleGroupModal.generated";

type Props = {
  roleGroup: DeleteRoleGroupModal_RoleGroupFragment;
};

const DeleteRoleGroupModal: FC<Props> = (props) => {
  const router = useRouter();
  const { closeModal } = useModal();
  const { addToast, addErrorToast } = useToast();
  const [deleteRoleGroupMutation, { loading }] = useDeleteRoleGroupsMutation({
    variables: {
      ids: [props.roleGroup.id],
    },
    refetchQueries: [
      GraphqlOperations.Query.GetRoles,
      GraphqlOperations.Query.GetRoleGroups,
      GraphqlOperations.Query.OrgDetail_RoleGroups,
      GraphqlOperations.Query.RecentlyViewedRoleGroups,
    ],
    onCompleted: (data) => {
      if (!data.deleteRoleGroups.success) {
        addErrorToast({ ...data, callsite: "delete_role_group" });
      } else {
        closeModal();
        addToast({
          iconType: "checkmark",
          message: "Department deleted",
        });
        router.push({
          pathname: "/",
          query: {
            tab: "roleGroups",
          },
        });
      }
    },
    onError: (data) =>
      addErrorToast({ ...data, callsite: "delete_role_group" }),
  });
  return (
    <Modal
      title="Delete Department"
      footerClassName={css(styles.modalFooter)}
      confirmButtonProps={{
        copy: "Delete",
        onClick: deleteRoleGroupMutation,
        alert: true,
        loading,
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <AutoLayout direction="vertical" styleDeclaration={styles.body}>
        <Text type="P1" style={{ whiteSpace: "normal" }}>
          Are you sure you want to delete {props.roleGroup.name}?
        </Text>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 24,
    fontSize: 14,
  },
  modalFooter: {
    borderTop: "none",
  },
});

export default DeleteRoleGroupModal;

gql`
  fragment DeleteRoleGroupModal_RoleGroup on RoleGroup {
    id
    name
  }
`;
