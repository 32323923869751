import RecapRow from "./RecapRow";
import useLastWeekRecapParts from "./useLastWeekRecapParts";
import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useBrandColor from "@hooks/useBrandColor";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  locationIds?: number[];
  allLocations?: boolean;
};

const InThePastWeek: FC<Props> = ({ locationIds, allLocations = true }) => {
  const brandColor = useBrandColor();
  const { parts, noRecap } = useLastWeekRecapParts({
    locationIds,
    allLocations,
  });
  return (
    <AutoLayout
      direction="vertical"
      flex={3}
      alignSelf="stretch"
      styleDeclaration={styles.container}
    >
      <Text
        type="H2"
        headlineFont
        fontWeight="SemiBold"
        style={{ color: brandColor }}
      >
        In the past week
      </Text>
      <Spacer size={allLocations ? 22 : 8} />
      <AutoLayout direction="vertical">
        {noRecap ? (
          <Text type="P2" fontWeight="Medium" color={brandColor} multiline>
            Last week was a quiet week. We'll let you know when there's stuff to
            report 😴
          </Text>
        ) : (
          parts.map((part, i) => (
            <RecapRow key={`recap-row-${i}`}>{part}</RecapRow>
          ))
        )}
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default InThePastWeek;
