import { FC } from "react";
import { TagDropdownContentTagFragment } from "./TagSelectDropdown.generated";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@src/ui/dialog";
import { Button } from "@src/ui/button";
import useTagContext from "@src/contexts/TagContext/hooks/useTagContext";
import Text from "@src/ui/text";

type Props = {
  tag: TagDropdownContentTagFragment;
  onClose: () => void;
  onComplete: () => void;
};

const DeleteTagModal: FC<Props> = ({ tag, onClose, onComplete }) => {
  const tagContext = useTagContext();
  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Tag</DialogTitle>
        </DialogHeader>
        <Text type="P2">
          Are you sure you want to delete "{tag.nameTranslations.en}"?
        </Text>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              tagContext.deleteTag(tag.id);
              onClose();
              onComplete();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default DeleteTagModal;
