import React, { FC } from "react";
import { useModal } from "../../hooks/useModal";
import Modal from "./Modal";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import Spacer from "../ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = {
  title: string;
  content?: JSX.Element;
};

const PostActionSummaryModal: FC<Props> = ({ title, content }) => {
  const { closeModal } = useModal();
  return (
    <Modal showHeader={false} footerClassName={css(styles.hidden)}>
      <AutoLayout
        direction="vertical"
        width="420px"
        spacingMode="space-between"
        alignmentHorizontal="center"
        styleDeclaration={styles.body}
      >
        <div>
          <AutoLayout
            width="72px"
            height="72px"
            marginTop="46px"
            marginLeft="auto"
            marginRight="auto"
            styleDeclaration={styles.checkmarkContainer}
            alignmentHorizontal="center"
            alignmentVertical="center"
          >
            <DeprecatedIcon
              type="checkmark"
              iconSize={36}
              color={deprecatedTones.gray10}
            />
          </AutoLayout>
          <Spacer size={21} />
          <Text type="P1" fontWeight="SemiBold" multiline={true}>
            {title}
          </Text>
        </div>
        <Text type="P1" multiline={true}>
          {content}
        </Text>
        <Button
          variant="Primary"
          text="Close"
          style={{ alignSelf: "stretch", margin: "20px 24px" }}
          onClick={() => closeModal()}
        />
      </AutoLayout>
    </Modal>
  );
};

export default PostActionSummaryModal;

const styles = StyleSheet.create({
  hidden: {
    display: "none",
  },
  body: {
    minHeight: "300px",
  },
  checkmarkContainer: {
    backgroundColor: deprecatedTones.blue2,
    borderRadius: "50%",
  },
});
