import Modal from "@components/modals/Modal";
import { useReactivateEmployeesMutation } from "@components/modals/ReactivatePeopleModal.generated";
import Spacer from "@components/ui/Spacer";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import { EmployeeThinFragment } from "@src/fragments.generated";
import { GraphqlOperations } from "@src/types.generated";
import { pluralize } from "@utils/strings";
import { StyleSheet, css } from "aphrodite";
import { gql } from "graphql-tag";
import { FC, useMemo } from "react";

type Props = {
  employees: EmployeeThinFragment[];
  onSuccess?: () => void;
};

const ReactivatePeopleModal: FC<Props> = ({ employees, onSuccess }) => {
  const { closeModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const { trackEvent } = useTracking();
  const { addErrorToast, addToast } = useToast();
  const employeeIds = useMemo(() => {
    return employees.map((e) => e.id);
  }, [employees]);
  const [reactivateEmployees, { loading }] = useReactivateEmployeesMutation({
    refetchQueries: [
      GraphqlOperations.Query.PersonDetailHeader,
      GraphqlOperations.Query.PersonDetailDetails,
      GraphqlOperations.Query.GetPeople,
    ],
    onCompleted: (data) => {
      if (data.reactivateEmployees.success) {
        closeModal();
        trackEvent("reactivated_employees", {
          employeeIds,
        });
        onSuccess && onSuccess();
        addToast({
          iconType: "user",
          message: `${employees.length} ${pluralize(
            "employee",
            employees.length,
          )} ${employees.length === 1 ? "has" : "have"} been reactivated`,
        });
      } else {
        addErrorToast({
          ...data.reactivateEmployees.error,
          callsite: "reactivate_employees",
        });
      }
    },
    onError: (error) => {
      addErrorToast({ ...error, callsite: "reactivate_employees" });
    },
    variables: {
      employeeIds,
    },
  });

  return (
    <Modal
      title={"Reactivate people"}
      confirmButtonProps={{
        copy: "Reactivate",
        disabled: !userActionPermissions?.deactivateTrainees,
        onClick: reactivateEmployees,
        loading,
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <div className={css(styles.body)}>
        {!userActionPermissions ? (
          <Spinner centered />
        ) : !userActionPermissions?.deactivateTrainees ? (
          <Text type="P2" multiline>
            You do not have permission to reactivate people
          </Text>
        ) : (
          <>
            <Text type="P2" multiline>
              Are you sure you want to reactivate {employees.length}{" "}
              {pluralize("employee", employees.length)}?
            </Text>
            <Spacer size={16} />
            <div className={css(styles.bullet)}>
              • They will immediately re-gain access to Opus
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

gql`
  mutation ReactivateEmployees($employeeIds: [Int!]!) {
    reactivateEmployees(employeeIds: $employeeIds) {
      success
      error {
        code
      }
      employees {
        id
        deactivatedAt
      }
    }
  }
`;

export default ReactivatePeopleModal;

const styles = StyleSheet.create({
  body: {
    fontSize: "14px",
    padding: "32px 24px",
  },
  bullet: {
    marginBottom: "4px",
    lineHeight: "18px",
    color: deprecatedColors.outline,
  },
});
