import { FC, useState } from "react";
import { TagDropdownContentTagFragment } from "./TagSelectDropdown.generated";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@src/ui/dialog";
import TranslationTextField from "@src/deprecatedDesignSystem/components/TranslationTextField";
import { Checkbox } from "@src/ui/checkbox";
import { Button } from "@src/ui/button";
import useTagContext from "@src/contexts/TagContext/hooks/useTagContext";
import { uuid4 } from "@src/utils/strings";
import { TranslationSetFragment } from "@src/fragments.generated";

type Props = {
  onClose: () => void;
  onComplete: (tag: TagDropdownContentTagFragment) => void;
};
export const CreateTagModal: FC<Props> = ({ onClose, onComplete }) => {
  const tagContext = useTagContext();
  const [availableInApp, setAvailableInApp] = useState(true);
  const [nameTranslations, setNameTranslations] =
    useState<TranslationSetFragment>({
      en: "",
      uuid: uuid4(),
      translationOverrides: [],
    });

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Tag</DialogTitle>
        </DialogHeader>

        <TranslationTextField
          value={nameTranslations}
          onChange={(translationSet) => {
            setNameTranslations(translationSet);
          }}
        />

        <div className="flex space-x-2">
          <Checkbox
            id="availableInApp"
            checked={availableInApp}
            onCheckedChange={(checked) => setAvailableInApp(!!checked)}
          />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="availableInApp"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Visible to employees
            </label>
            <p className="text-sm text-muted-foreground">
              Let employees use this tag to find content they have access to
            </p>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              tagContext.createTag(
                uuid4(),
                {
                  nameTranslations,
                  availableInApp,
                },
                (createdTag) => {
                  onComplete({ ...createdTag, libraryItemCount: 0 });
                },
              );
              onClose();
            }}
          >
            Add Tag
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTagModal;
