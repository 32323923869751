import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePathMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeletePathMutation = { __typename?: 'Mutation', deletePath: { __typename?: 'PathMutationResult', success: boolean } };

export type CopyCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type CopyCourseMutation = { __typename?: 'Mutation', copyCourse: { __typename?: 'CourseMutationResult', success: boolean, course?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string } } | null, error?: { __typename?: 'Error', code: string } | null } };


export const DeletePathDocument = gql`
    mutation DeletePath($id: Int!) {
  deletePath(id: $id) {
    success
  }
}
    `;
export type DeletePathMutationFn = Apollo.MutationFunction<DeletePathMutation, DeletePathMutationVariables>;

/**
 * __useDeletePathMutation__
 *
 * To run a mutation, you first call `useDeletePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathMutation, { data, loading, error }] = useDeletePathMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePathMutation(baseOptions?: Apollo.MutationHookOptions<DeletePathMutation, DeletePathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePathMutation, DeletePathMutationVariables>(DeletePathDocument, options);
      }
export type DeletePathMutationHookResult = ReturnType<typeof useDeletePathMutation>;
export type DeletePathMutationResult = Apollo.MutationResult<DeletePathMutation>;
export type DeletePathMutationOptions = Apollo.BaseMutationOptions<DeletePathMutation, DeletePathMutationVariables>;
export const CopyCourseDocument = gql`
    mutation CopyCourse($id: Int!) {
  copyCourse(id: $id) {
    success
    course {
      id
      libraryItem {
        id
      }
    }
    error {
      code
    }
  }
}
    `;
export type CopyCourseMutationFn = Apollo.MutationFunction<CopyCourseMutation, CopyCourseMutationVariables>;

/**
 * __useCopyCourseMutation__
 *
 * To run a mutation, you first call `useCopyCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCourseMutation, { data, loading, error }] = useCopyCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyCourseMutation(baseOptions?: Apollo.MutationHookOptions<CopyCourseMutation, CopyCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyCourseMutation, CopyCourseMutationVariables>(CopyCourseDocument, options);
      }
export type CopyCourseMutationHookResult = ReturnType<typeof useCopyCourseMutation>;
export type CopyCourseMutationResult = Apollo.MutationResult<CopyCourseMutation>;
export type CopyCourseMutationOptions = Apollo.BaseMutationOptions<CopyCourseMutation, CopyCourseMutationVariables>;