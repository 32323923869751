import React, { FC, useMemo } from "react";
import LocationsTable from "@components/home/LocationsTable";
import NewLocationButton from "@components/people/locationDetail/NewLocationButton";

const OrgDetailLocationsTab: FC = () => {
  const rightAction = useMemo(() => {
    return <NewLocationButton />;
  }, []);
  return <LocationsTable rightAction={rightAction} />;
};

export default OrgDetailLocationsTab;
