import useActionPermissions from "@src/hooks/useActionPermissions";
import { FC } from "react";
import Button from "@src/deprecatedDesignSystem/components/Button";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import NewRoleModal from "@components/modals/NewRoleModal";

const NewRoleButton: FC = () => {
  const { userActionPermissions } = useActionPermissions();

  if (!userActionPermissions?.changeOrgSettings) {
    return (
      <NoPermissionsTooltipWrapper
        hasPermission={false}
        caretPosition="Top"
        tooltipSide="bottom"
      >
        <Button disabled text={"New Role"} />
      </NoPermissionsTooltipWrapper>
    );
  }

  return (
    <NewRoleModal trigger={<Button text={"New Role"} leftIcon="plus" />} />
  );
};

export default NewRoleButton;
