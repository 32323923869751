import useActionPermissions from "@src/hooks/useActionPermissions";
import { useModal } from "@src/hooks/useModal";
import { FC, useCallback } from "react";
import Button from "@src/deprecatedDesignSystem/components/Button";
import NoPermissionsTooltipWrapper from "@components/ui/NoPermissionsTooltipWrapper";
import EditHierarchyLevelsModal from "./modals/EditHierarchyLevelsModal";

const EditHierarchyLevelsButton: FC = () => {
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const handleOnClick = useCallback(() => {
    showModal(<EditHierarchyLevelsModal />);
  }, [showModal]);
  if (!userActionPermissions?.changeOrgSettings) {
    return (
      <NoPermissionsTooltipWrapper
        hasPermission={false}
        caretPosition="Top"
        tooltipSide="bottom"
      >
        <Button
          variant="Outline"
          disabled
          text={BUTTON_TEXT}
          onClick={handleOnClick}
        />
      </NoPermissionsTooltipWrapper>
    );
  }
  return (
    <Button
      variant="Outline"
      text={BUTTON_TEXT}
      onClick={handleOnClick}
      leftIcon="pencil"
    />
  );
};

const BUTTON_TEXT = "Edit Hierarchy Levels";

export default EditHierarchyLevelsButton;
