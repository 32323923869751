import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyPathModalQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type CopyPathModalQuery = { __typename?: 'Query', AdminPath?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string } } } | null };

export type CopyPathModalFragment = { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string } } };

export type CopyPathMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  duplicateChildContent: Types.Scalars['Boolean'];
}>;


export type CopyPathMutation = { __typename?: 'Mutation', copyPath: { __typename?: 'PathMutationResult', success: boolean, path?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string } } | null, error?: { __typename?: 'Error', code: string } | null } };

export const CopyPathModalFragmentDoc = gql`
    fragment CopyPathModal on Path {
  id
  libraryItem {
    id
    name {
      en
    }
  }
}
    `;
export const CopyPathModalDocument = gql`
    query CopyPathModal($id: Int!) {
  AdminPath(id: $id) {
    ...CopyPathModal
  }
}
    ${CopyPathModalFragmentDoc}`;

/**
 * __useCopyPathModalQuery__
 *
 * To run a query within a React component, call `useCopyPathModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyPathModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyPathModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyPathModalQuery(baseOptions: Apollo.QueryHookOptions<CopyPathModalQuery, CopyPathModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyPathModalQuery, CopyPathModalQueryVariables>(CopyPathModalDocument, options);
      }
export function useCopyPathModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyPathModalQuery, CopyPathModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyPathModalQuery, CopyPathModalQueryVariables>(CopyPathModalDocument, options);
        }
export type CopyPathModalQueryHookResult = ReturnType<typeof useCopyPathModalQuery>;
export type CopyPathModalLazyQueryHookResult = ReturnType<typeof useCopyPathModalLazyQuery>;
export type CopyPathModalQueryResult = Apollo.QueryResult<CopyPathModalQuery, CopyPathModalQueryVariables>;
export const CopyPathDocument = gql`
    mutation CopyPath($id: Int!, $duplicateChildContent: Boolean!) {
  copyPath(id: $id, duplicateChildContent: $duplicateChildContent) {
    success
    path {
      id
      libraryItem {
        id
      }
    }
    error {
      code
    }
  }
}
    `;
export type CopyPathMutationFn = Apollo.MutationFunction<CopyPathMutation, CopyPathMutationVariables>;

/**
 * __useCopyPathMutation__
 *
 * To run a mutation, you first call `useCopyPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPathMutation, { data, loading, error }] = useCopyPathMutation({
 *   variables: {
 *      id: // value for 'id'
 *      duplicateChildContent: // value for 'duplicateChildContent'
 *   },
 * });
 */
export function useCopyPathMutation(baseOptions?: Apollo.MutationHookOptions<CopyPathMutation, CopyPathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyPathMutation, CopyPathMutationVariables>(CopyPathDocument, options);
      }
export type CopyPathMutationHookResult = ReturnType<typeof useCopyPathMutation>;
export type CopyPathMutationResult = Apollo.MutationResult<CopyPathMutation>;
export type CopyPathMutationOptions = Apollo.BaseMutationOptions<CopyPathMutation, CopyPathMutationVariables>;