import { gql } from "@apollo/client";
import { useLastWeekRecapQuery } from "./useLastWeekRecapParts.generated";
import Text from "@ui/text";
import useBrandColor from "@hooks/useBrandColor";
import { pluralize, wasOrWere } from "@utils/strings";
import { useMemo } from "react";

type Return = {
  parts: React.ReactNode[];
  loading: boolean;
  noRecap: boolean;
};

const useLastWeekRecapParts = ({
  locationIds,
  allLocations = true,
}: {
  locationIds?: number[];
  allLocations?: boolean;
}): Return => {
  const brandColor = useBrandColor();
  const { data, loading, previousData } = useLastWeekRecapQuery({
    variables: {
      input: {
        locationIds,
      },
    },
  });
  const recapParts = useMemo(() => {
    const recap = data?.weeklyRecap || previousData?.weeklyRecap;
    if (!recap) {
      return [];
    }
    const {
      numCoursesCreated,
      numCoursesPublished,
      numInvited,
      numTraineesCompletedACourse,
    } = recap;

    const parts = [];
    if (numTraineesCompletedACourse > 0) {
      parts.push(
        <Text type="P2" color={brandColor}>
          {pluralizeAndMaybeBold("trainee", numTraineesCompletedACourse)}{" "}
          completed a course.
        </Text>,
      );
    }
    const invitedCopy = numInvitedCopy(numInvited);
    if (invitedCopy) {
      parts.push(
        <Text type="P2" color={brandColor}>
          {invitedCopy}
        </Text>,
      );
    }
    const coursesPart = coursesCopy(numCoursesCreated, numCoursesPublished);
    if (coursesPart && allLocations) {
      parts.push(
        <Text type="P2" color={brandColor}>
          {coursesPart}
        </Text>,
      );
    }

    return parts;
  }, [brandColor, data?.weeklyRecap, previousData?.weeklyRecap, allLocations]);

  return {
    parts: recapParts,
    loading,
    noRecap: !loading && (recapParts == null || recapParts.length === 0),
  };
};

const pluralizeAndMaybeBold = (noun: string, count: number, postfix = "") => {
  const text = `${count} ${pluralize(noun, count)}${
    postfix ? " " + postfix : ""
  }`;
  return count > 0 ? <strong>{text}</strong> : text;
};

const numInvitedCopy = (numInvited: number) => {
  if (numInvited === 0) {
    return null;
  } else if (numInvited === 1) {
    return <strong>1 person was invited.</strong>;
  } else {
    return <strong>{numInvited} people were invited.</strong>;
  }
};

const coursesCopy = (numCreated: number, numPublished: number) => {
  if (numCreated === 0 && numPublished === 0) {
    return null;
  } else if (numCreated === 0) {
    return (
      <>
        {pluralizeAndMaybeBold("course", numPublished)}{" "}
        {wasOrWere(numPublished)} published.
      </>
    );
  } else if (numPublished === 0) {
    return (
      <>
        {pluralizeAndMaybeBold("course", numCreated)} {wasOrWere(numCreated)}{" "}
        created.
      </>
    );
  } else {
    return (
      <>
        {pluralizeAndMaybeBold("course", numCreated)} {wasOrWere(numCreated)}{" "}
        created and {pluralizeAndMaybeBold("course", numPublished)}{" "}
        {wasOrWere(numPublished)} published.
      </>
    );
  }
};

gql`
  query LastWeekRecap($input: WeeklyRecapInput) {
    weeklyRecap: AdminWeeklyRecap(input: $input) {
      numTraineesCompletedACourse
      numInvited
      numCoursesCreated
      numCoursesPublished
    }
  }
`;

export default useLastWeekRecapParts;
