import Modal from "@components/modals/Modal";
import LocationMultiSelect from "@components/ui/LocationMultiSelect";
import {
  RecipientLocation,
  UserMultiSelect,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import { useModal } from "@hooks/useModal";
import { useTracking } from "@hooks/useTracking";
import { pluralize } from "@utils/strings";
import { FC, useMemo, useState } from "react";
import {
  RemoveUsersFromLocationsMutationVariables,
  useRemoveUsersFromLocationsMutation,
} from "./operations.generated";
import PostActionSummaryModal from "./PostActionSummaryModal";
import { css, StyleSheet } from "aphrodite";
import Text from "@ui/text";
import Spacer from "../ui/Spacer";
import { GraphqlOperations } from "@src/types.generated";
import { MinimalEmployeeFragment } from "../ui/operations.generated";

type Props = {
  trainees?: MinimalEmployeeFragment[];
  locations?: RecipientLocation[];
  onSuccess?: () => void;
};

const RemoveTraineesFromLocationsModal: FC<Props> = ({
  locations: initialLocations = [],
  trainees: initialTrainees = [],
  onSuccess,
}) => {
  const [locations, setLocations] = useState(initialLocations);
  const locationIds = useMemo(() => locations.map((g) => g.id), [locations]);
  const [employees, setEmployees] = useState(initialTrainees);
  const employeeIds = useMemo(() => employees.map((e) => e.id), [employees]);
  const { showModal, closeModal } = useModal();
  const removeUsersFromLocationsVariables: RemoveUsersFromLocationsMutationVariables =
    {
      locationIds,
      employeeIds,
    };
  const { trackEvent } = useTracking();
  const [addTraineesToLocations, { loading }] =
    useRemoveUsersFromLocationsMutation({
      variables: removeUsersFromLocationsVariables,
      refetchQueries: [GraphqlOperations.Query.GetPeople],
      onCompleted: (data) => {
        if (data.removeUsersFromLocations.success) {
          closeModal();
          if (onSuccess) {
            onSuccess();
          }
          trackEvent(
            "trainees_removed_from_locations",
            removeUsersFromLocationsVariables,
          );
          showModal(
            <PostActionSummaryModal
              title={`${employees.length} ${pluralize(
                "trainee",
                employees.length,
              )} Removed from ${locations.length} ${pluralize(
                "location",
                locations.length,
              )}`}
            />,
          );
        }
      },
    });

  return (
    <Modal
      title={`Remove from locations`}
      className={css(styles.container)}
      confirmButtonProps={{
        copy: `Remove ${employeeIds.length} ${pluralize(
          "trainee",
          employeeIds.length,
        )} from ${locationIds.length} ${pluralize(
          "location",
          locationIds.length,
        )}`,
        disabled: locationIds.length === 0 || employeeIds.length === 0,
        onClick: () => {
          if (!locations) {
            return;
          }
          addTraineesToLocations();
        },
        loading,
      }}
      headerClassName={css(styles.modalHeader)}
      footerClassName={css(styles.modalHeader, styles.modalFooter)}
      bodyStyle={{ width: 600 }}
    >
      <div className={css(styles.traineeSelectContainer)}>
        <Text type="P2" fontWeight="SemiBold">
          People
        </Text>
        <Spacer size={10} />
        <UserMultiSelect employees={employees} setEmployees={setEmployees} />
      </div>
      <div className={css(styles.groupSelect)}>
        <Text type="P2" fontWeight="SemiBold">
          Remove from locations
        </Text>
        <Spacer size={10} />
        <LocationMultiSelect
          locations={locations}
          setLocations={setLocations}
        />
      </div>
    </Modal>
  );
};

export default RemoveTraineesFromLocationsModal;

const styles = StyleSheet.create({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    flexGrow: 1,
    maxHeight: "80px",
  },
  modalFooter: {
    alignItems: "center",
  },
  groupSelect: {
    padding: "24px",
  },
  traineeSelectContainer: {
    padding: "24px 24px 0 24px",
  },
});
