import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEngagementQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ProductEngagementInput>;
}>;


export type GetEngagementQuery = { __typename?: 'Query', EmployeeEngagement: { __typename?: 'EmployeeEngagement', totalEmployees: number, activeEmployees: number } };

export type GetProductAdoptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductAdoptionQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number }, Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, seatsExpected?: number | null } | null } } | null };


export const GetEngagementDocument = gql`
    query GetEngagement($input: ProductEngagementInput) {
  EmployeeEngagement: AdminEmployeeEngagement(input: $input) {
    totalEmployees
    activeEmployees
  }
}
    `;

/**
 * __useGetEngagementQuery__
 *
 * To run a query within a React component, call `useGetEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEngagementQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEngagementQuery(baseOptions?: Apollo.QueryHookOptions<GetEngagementQuery, GetEngagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEngagementQuery, GetEngagementQueryVariables>(GetEngagementDocument, options);
      }
export function useGetEngagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEngagementQuery, GetEngagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEngagementQuery, GetEngagementQueryVariables>(GetEngagementDocument, options);
        }
export type GetEngagementQueryHookResult = ReturnType<typeof useGetEngagementQuery>;
export type GetEngagementLazyQueryHookResult = ReturnType<typeof useGetEngagementLazyQuery>;
export type GetEngagementQueryResult = Apollo.QueryResult<GetEngagementQuery, GetEngagementQueryVariables>;
export const GetProductAdoptionDocument = gql`
    query GetProductAdoption {
  People(input: {includeStaffForStaff: {value: false}}) {
    totalCount
  }
  Me {
    user {
      id
      org {
        id
        seatsExpected
      }
    }
  }
}
    `;

/**
 * __useGetProductAdoptionQuery__
 *
 * To run a query within a React component, call `useGetProductAdoptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAdoptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAdoptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductAdoptionQuery(baseOptions?: Apollo.QueryHookOptions<GetProductAdoptionQuery, GetProductAdoptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductAdoptionQuery, GetProductAdoptionQueryVariables>(GetProductAdoptionDocument, options);
      }
export function useGetProductAdoptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductAdoptionQuery, GetProductAdoptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductAdoptionQuery, GetProductAdoptionQueryVariables>(GetProductAdoptionDocument, options);
        }
export type GetProductAdoptionQueryHookResult = ReturnType<typeof useGetProductAdoptionQuery>;
export type GetProductAdoptionLazyQueryHookResult = ReturnType<typeof useGetProductAdoptionLazyQuery>;
export type GetProductAdoptionQueryResult = Apollo.QueryResult<GetProductAdoptionQuery, GetProductAdoptionQueryVariables>;