import React, { CSSProperties, FC, MouseEvent, useMemo } from "react";
import Pill from "@src/deprecatedDesignSystem/components/Pill";
import useTag from "@contexts/TagContext/hooks/useTag";

type Props = {
  tagId: string;
  onRemove?: (tagId: string) => void;
  style?: CSSProperties;
  truncateAt?: number;
};

const TagPill: FC<Props> = ({ tagId, onRemove, style, truncateAt }) => {
  const tag = useTag(tagId);
  const onRemoveCallback = useMemo(() => {
    if (onRemove) {
      return (e: MouseEvent) => {
        e.stopPropagation();
        if (onRemove) {
          onRemove(tagId);
        }
      };
    } else {
      return undefined;
    }
  }, [onRemove, tagId]);
  if (!tag) {
    return null;
  }
  return (
    <Pill
      key={tag.id}
      id={tag.id}
      title={tag.nameTranslations.en || ""}
      onRemove={onRemoveCallback}
      style={style}
      truncateAt={truncateAt}
    />
  );
};

export default TagPill;
