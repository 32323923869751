import { AutomationFragment } from "@src/fragments.generated";

export type AutomationItem = {
  automation: AutomationFragment;
  contentName: string;
  __typename: AutomationContentType;
};

export type AutomationContentType =
  | "Course"
  | "TrainingResource"
  | "Path"
  | "OrgPremiumContentConfig";

export const AUTOMATION_CONTENT_TYPES_WITH_START_DATETIME: AutomationContentType[] =
  ["Course", "Path", "OrgPremiumContentConfig"];

export const AUTOMATION_CONTENT_TYPES_WITH_END_DATETIME: AutomationContentType[] =
  ["Course", "Path"];

export const AUTOMATION_CONTENT_TYPES_WITH_NEW_USERS_ONLY: AutomationContentType[] =
  ["Course", "TrainingResource", "Path"];
