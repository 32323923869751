import useActionPermissions from "@src/hooks/useActionPermissions";
import { useModal } from "@src/hooks/useModal";
import { FC, useCallback, useMemo } from "react";
import Button from "@src/deprecatedDesignSystem/components/Button";
import NoPermissionsTooltipWrapper from "../ui/NoPermissionsTooltipWrapper";
import { LocationGroupType } from "@src/types.generated";
import LocationGroupModal from "./LocationGroupModal";
import { locationGroupTypeDisplayName } from "./constants";

type Props = {
  type: LocationGroupType;
};

const NewLocationGroupButton: FC<Props> = (props) => {
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const buttonTitle = useMemo(() => {
    return `New ${locationGroupTypeDisplayName[props.type]}`;
  }, [props.type]);
  const handleOnClick = useCallback(() => {
    showModal(<LocationGroupModal type={props.type} />);
  }, [showModal, props.type]);

  if (!userActionPermissions?.changeOrgSettings) {
    return (
      <NoPermissionsTooltipWrapper
        hasPermission={false}
        caretPosition="Top"
        tooltipSide="bottom"
      >
        <Button disabled text={buttonTitle} onClick={handleOnClick} />
      </NoPermissionsTooltipWrapper>
    );
  }

  return <Button text={buttonTitle} onClick={handleOnClick} leftIcon="plus" />;
};

export default NewLocationGroupButton;
