import { gql } from "@apollo/client";
import { isSharpSetupComplete } from "@src/components/sexualHarassmentPrevention/utils";
import { PremiumContentSetupStep } from "@src/types.generated";
import { OrgPremiumContentConfigStatusFragment } from "./premiumPaths.generated";
import { IsSharpSetupCompleteFragment } from "@src/components/sexualHarassmentPrevention/utils.generated";

type ContentConfigStatus = "Needs setup" | "Active";

export function getOrgSharpConfigStatus(
  orgSharpConfig: IsSharpSetupCompleteFragment,
): ContentConfigStatus {
  if (isSharpSetupComplete(orgSharpConfig)) {
    return "Active";
  } else {
    return "Needs setup";
  }
}

export function getOrgPremiumContentConfigFragmentStatus(
  orgPremiumContentConfig: OrgPremiumContentConfigStatusFragment,
): ContentConfigStatus {
  if (orgPremiumContentConfig.setupStep !== PremiumContentSetupStep.Completed) {
    return "Needs setup";
  }
  return "Active";
}

gql(
  `fragment OrgPremiumContentConfigStatus on OrgPremiumContentConfig {
    setupStep
    }`,
);
