import { getRoleDetailRoute } from "@components/people/utils/getDetailRoute";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import React, { FC, useMemo } from "react";
import { gql } from "@apollo/client";
import NewRoleButton from "@components/people/roleDetail/NewRoleButton";
import TableRowAvatarAndName from "@components/people/roleDetail/TableRowAvatarAndName";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import useActionPermissions from "@hooks/useActionPermissions";
import EditRoleModal from "@components/modals/EditRoleModal";
import { ROLE_USER_TYPE_COPY } from "@utils/copy";
import { UserType } from "@src/types.generated";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import EditHierarchyLevelsButton from "../people/roleDetail/EditHierarchyLevelsButton";
import useRoles from "@src/hooks/useRoles";
import useUser from "@src/hooks/useUser";

const OrgDetailRolesTab: FC = () => {
  const showHierarchyLevelsButton = useFeatureFlag("role-hierarchy");
  const { user } = useUser();
  const { roles, loading } = useRoles();
  const rightAction = useMemo(() => {
    return (
      <div className="flex gap-2">
        {user?.userType === UserType.Admin && showHierarchyLevelsButton && (
          <EditHierarchyLevelsButton />
        )}
        <NewRoleButton />
      </div>
    );
  }, [showHierarchyLevelsButton, user?.userType]);
  const { userActionPermissions } = useActionPermissions();
  const showDirectReports = useFeatureFlag("role-direct-reports");
  return (
    <Table
      title={"Roles"}
      dataTestId={"roles-table"}
      allItems={roles}
      itemId={(item) => item.id}
      allColumns={[
        "name",
        "hierarchyLevel",
        "departments",
        "permissions",
        "members",
        "directReports",
      ]}
      hiddenColumns={() => {
        const hiddenColumns = [];
        if (user?.userType !== UserType.Admin || !showHierarchyLevelsButton) {
          hiddenColumns.push("hierarchyLevel");
        }
        if (!showDirectReports) {
          hiddenColumns.push("directReports");
        }
        return hiddenColumns;
      }}
      rightAction={rightAction}
      columnTitles={{
        name: "Name",
        departments: "Departments",
        members: "People",
        permissions: "Permissions",
        directReports: "Direct Reports",
        hierarchyLevel: "Hierarchy Level",
      }}
      fuseSearchConfig={{
        threshold: 0.2,
        keys: [
          {
            name: "name",
            getFn: (x) => x.name,
          },
        ],
      }}
      overflowMenu={(x) => {
        return (
          <EditRoleModal
            role={x}
            trigger={
              <ContextMenuItem
                disabled={!userActionPermissions?.changeOrgSettings}
                label={"Edit role"}
                icon={"pencil"}
              />
            }
          />
        );
      }}
      rowStyles={{
        height: 24,
      }}
      noDataEmptyState={"No roles"}
      rowClickLink={(x) => {
        return getRoleDetailRoute(x.id);
      }}
      loading={loading}
      sortingFns={{
        name: (x) => x.name,
        members: (x) => x.userCount,
        hierarchyLevel: (x) => x.roleLevel,
        departments: (x) => {
          return (
            x.roleGroupMemberships.map((x) => x.roleGroup.name).join(", ") ||
            null
          );
        },
        directReports: (x) => {
          return x.directReports.map((x) => x.name).join(", ") || null;
        },
      }}
      defaultSort={{
        column: "name",
        descending: false,
      }}
      rowHeight={50}
      renderFns={{
        name: (x) => {
          return <TableRowAvatarAndName name={x.name} avatarType={"role"} />;
        },
        departments: (x) => {
          return (
            x.roleGroupMemberships.map((x) => x.roleGroup.name).join(", ") ||
            null
          );
        },
        hierarchyLevel: (x) => {
          return x.roleLevel;
        },
        permissions: (x) => {
          const type = x.roleActionPermissions?.roleUserType;
          return ROLE_USER_TYPE_COPY[type || UserType.Employee];
        },
        members: (location) => {
          return location.userCount;
        },
        directReports: (x) => {
          return x.directReports.map((x) => x.name).join(", ") || null;
        },
      }}
    />
  );
};

export default OrgDetailRolesTab;

gql`
  query OrgDetail_Roles {
    Roles(input: {}) {
      objects {
        userCount
        ...RoleWithPermissions
      }
    }
  }

  fragment OrgDetail_Role on Role {
    id
    userCount
    ...RoleWithPermissions
  }
`;
