import { getLocationGroupDetailRoute } from "@components/people/utils/getDetailRoute";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import React, { FC, useMemo } from "react";
import { gql } from "@apollo/client";

import {
  OrgDetail_LocationGroupFragment,
  useOrgDetail_LocationGroupsQuery,
  useOrgDetail_LocationGroupsUserCountQuery,
} from "./OrgDetailLocationGroupsTab.generated";
import { LocationGroupType } from "@src/types.generated";
import NewLocationGroupButton from "./NewLocationGroupButton";
import useActionPermissions from "@src/hooks/useActionPermissions";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import { useModal } from "@src/hooks/useModal";
import LocationGroupModal from "./LocationGroupModal";
import { locationGroupTypeDisplayName } from "@components/home/constants";
import { pluralize } from "@utils/strings";
import TableRowAvatarAndName from "@components/people/roleDetail/TableRowAvatarAndName";
import DeleteLocationGroupModal from "@components/modals/DeleteLocationGroupModal";

type Props = {
  type: LocationGroupType;
};

const OrgDetailLocationGroupsTab: FC<Props> = (props) => {
  const { data, loading } = useOrgDetail_LocationGroupsQuery({
    variables: {
      type: props.type,
    },
  });
  const { data: userCountData } = useOrgDetail_LocationGroupsUserCountQuery({
    variables: {
      type: props.type,
    },
  });
  const { showModal } = useModal();
  const { userActionPermissions } = useActionPermissions();
  const allItems: OrgDetail_LocationGroupFragment[] = useMemo(() => {
    return data?.LocationGroups.objects || [];
  }, [data?.LocationGroups.objects]);
  const rightAction = useMemo(() => {
    return <NewLocationGroupButton type={props.type} />;
  }, [props.type]);
  const groupToUserCount = useMemo(() => {
    return userCountData?.LocationGroups.objects.reduce(
      (acc, x) => {
        acc[x.id] = x.userCount;
        return acc;
      },
      {} as Record<string, number>,
    );
  }, [userCountData?.LocationGroups.objects]);
  return (
    <Table
      dataTestId={`${props.type}-table`}
      title={pluralize(locationGroupTypeDisplayName[props.type], 2)}
      rightAction={rightAction}
      allItems={allItems}
      itemId={(item) => item.id}
      allColumns={["name", "locations", "members"]}
      columnTitles={{
        name: "Name",
        locations: "Locations",
        members: "People",
      }}
      fuseSearchConfig={{
        threshold: 0.2,
        keys: [
          {
            name: "name",
            getFn: (x) => x.name,
          },
        ],
      }}
      rowStyles={{
        height: 24,
      }}
      noDataEmptyState={"No results"}
      rowClickLink={(x) => {
        return getLocationGroupDetailRoute(x.id);
      }}
      loading={loading}
      sortingFns={{
        name: (x) => x.name,
        members: (x) => groupToUserCount?.[x.id],
        locations: (x) => x.locationCount,
      }}
      defaultSort={{
        column: "name",
        descending: false,
      }}
      rowHeight={50}
      renderFns={{
        name: (x) => {
          return (
            <TableRowAvatarAndName
              name={x.name}
              avatarType={props.type}
              logoUrl={
                x.brandDetails?.logoUrls?.thumb ||
                x.brandDetails?.logoUrls?.original ||
                undefined
              }
            />
          );
        },
        members: (location) => {
          return groupToUserCount?.[location.id];
        },
        locations: (location) => {
          return location.locationCount;
        },
      }}
      overflowMenu={(x: OrgDetail_LocationGroupFragment) => {
        if (!userActionPermissions?.changeOrgSettings) {
          return null;
        }
        return (
          <>
            <ContextMenuItem
              label="Edit"
              icon="pencil"
              onSelect={() => {
                showModal(<LocationGroupModal id={x.id} type={props.type} />);
              }}
            />
            <ContextMenuItem
              label="Delete"
              icon="trash-2"
              onSelect={() => {
                showModal(<DeleteLocationGroupModal locationGroup={x} />);
              }}
            />
          </>
        );
      }}
    />
  );
};

export default OrgDetailLocationGroupsTab;

gql`
  query OrgDetail_LocationGroups($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      totalCount
      objects {
        ...OrgDetail_LocationGroup
      }
    }
  }

  query OrgDetail_LocationGroupsUserCount($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      totalCount
      objects {
        id
        userCount
      }
    }
  }

  fragment OrgDetail_LocationGroup on LocationGroup {
    id
    name
    type
    locationCount
    brandDetails {
      ...BrandDetails
    }
  }
`;
