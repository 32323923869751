import Modal from "@components/modals/Modal";
import { useAddUsersToRolesMutation } from "@components/modals/operations.generated";
import PostActionSummaryModal from "@components/modals/PostActionSummaryModal";
import Spacer from "@components/ui/Spacer";
import Text from "@ui/text";
import {
  RecipientRole,
  UserMultiSelect,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import { useModal } from "@hooks/useModal";
import { useTracking } from "@hooks/useTracking";
import { AddUsersToRolesInput } from "@src/types.generated";
import { pluralize } from "@utils/strings";
import { css, StyleSheet } from "aphrodite";
import { FC, useMemo, useState } from "react";
import { MinimalEmployeeFragment } from "../ui/operations.generated";
import RoleMultiSelect from "@components/ui/RoleMultiSelect";

type Props = {
  data?: AddTraineesToRolesInput;
  onSuccess?: () => void;
  refetchQueries?: string[];
};

export type AddTraineesToRolesInput = {
  trainees?: MinimalEmployeeFragment[];
  roles?: RecipientRole[];
};

const AddUsersToRolesModal: FC<Props> = ({
  data = {},
  onSuccess,
  refetchQueries = [],
}) => {
  const initialGroups = useMemo(() => data?.roles || [], [data?.roles]);
  const initialEmployees = useMemo(
    () => data?.trainees || [],
    [data?.trainees],
  );
  const [roles, setRoles] = useState(initialGroups);
  const roleIds = useMemo(() => roles.map((g) => g.id), [roles]);
  const [employees, setEmployees] = useState(initialEmployees);
  const employeeIds = useMemo(() => employees.map((e) => e.id), [employees]);

  const { showModal, closeModal } = useModal();
  const addTraineesToGroupsInput: AddUsersToRolesInput = {
    roleIds: roleIds,
    userIds: employeeIds,
  };
  const { trackEvent } = useTracking();
  const [addTraineesToRoles, { loading }] = useAddUsersToRolesMutation({
    variables: {
      input: addTraineesToGroupsInput,
    },
    refetchQueries,
    onCompleted: (data) => {
      if (data.addUsersToRoles.success) {
        closeModal();
        if (onSuccess) {
          onSuccess();
        }
        trackEvent("users_added_to_role", addTraineesToGroupsInput);
        showModal(
          <PostActionSummaryModal
            title={`${employees.length} ${pluralize(
              "employee",
              employees.length,
            )} added to ${roles.length} ${pluralize("role", roles.length)}`}
          />,
        );
      }
    },
  });
  return (
    <Modal
      title={`Add roles`}
      className={css(styles.container)}
      confirmButtonProps={{
        copy: `Add ${employeeIds.length} ${pluralize(
          "employee",
          employeeIds.length,
        )} to ${roleIds.length} ${pluralize("role", roleIds.length)}`,
        disabled: roleIds.length === 0 || employeeIds.length === 0,
        onClick: () => {
          if (!roles) {
            return;
          }
          addTraineesToRoles();
        },
        loading,
      }}
      headerClassName={css(styles.modalHeader)}
      footerClassName={css(styles.modalHeader, styles.modalFooter)}
      bodyStyle={{ width: 600 }}
    >
      <div>
        <div className={css(styles.section)}>
          <Text type="P2" fontWeight="SemiBold">
            People
          </Text>
          <Spacer size={10} />

          <UserMultiSelect employees={employees} setEmployees={setEmployees} />
        </div>
        <div className={css(styles.section)}>
          <Text type="P2" fontWeight="SemiBold">
            Roles
          </Text>
          <Spacer size={10} />
          <div className={css(styles.groupsSection)}>
            <RoleMultiSelect
              showRolesWithNoTrainees
              roles={roles}
              setRoles={setRoles}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUsersToRolesModal;

export const styles = StyleSheet.create({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    flexGrow: 1,
    maxHeight: "80px",
  },
  modalFooter: {
    alignItems: "center",
  },
  section: {
    padding: "24px 24px 0 24px",
  },
  groupsSection: {
    marginBottom: "48px",
    position: "relative",
  },
});
