import GroupSearchResult from "@components/ui/GroupSearchResult";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Pill from "@src/deprecatedDesignSystem/components/Pill";
import {
  RecipientGroup,
  RecipientLocation,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import useClickOutside from "@hooks/useClickOutside";
import useKeyboardNavigation from "@hooks/useKeyboardNavigation";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { pluralize } from "@utils/strings";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import Fuse from "fuse.js";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import RelativePortal from "react-relative-portal";
import { useGetLocationsWithEmployeesQuery } from "@components/ui/operations.generated";

type Props = {
  locations: RecipientLocation[];
  setLocations: (recipients: RecipientLocation[]) => void;
  styleDeclaration?: StyleDeclaration;
  showLocationsWithNoTrainees?: boolean;
};

const fuseConfig = {
  keys: ["name"],
  threshold: 0.3,
};

const LocationMultiSelect: FC<Props> = ({
  locations,
  setLocations,
  styleDeclaration,
  showLocationsWithNoTrainees = false,
}) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const onClickOutside = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  useClickOutside(containerRef, onClickOutside, false);
  const { data: locationsData, previousData: previousLocationsData } =
    useGetLocationsWithEmployeesQuery({
      variables: {
        input: { search: { value: searchValue } },
      },
    });
  const locationIds = useMemo(() => {
    return new Set((locations || []).map((r) => r.id));
  }, [locations]);
  const availableLocations = useMemo(() => {
    let tmp = (
      locationsData?.Locations.objects ||
      previousLocationsData?.Locations.objects ||
      []
    ).filter((x) => !locationIds.has(x.id));
    if (!showLocationsWithNoTrainees) {
      tmp = tmp.filter((x) => x?.employees && x.employees.length > 0);
    }
    return tmp;
  }, [
    locationsData?.Locations.objects,
    previousLocationsData?.Locations.objects,
    showLocationsWithNoTrainees,
    locationIds,
  ]);

  const fuse = useMemo(() => {
    return new Fuse(availableLocations, fuseConfig);
  }, [availableLocations]);

  const locationSearchResults = useMemo(() => {
    let tmp = [...availableLocations];
    if (searchValue) {
      tmp = fuse.search(searchValue).map((result) => result.item);
    }
    return tmp;
  }, [fuse, searchValue, availableLocations]);
  const totalSearchResults = useMemo(() => {
    return locationSearchResults.length;
  }, [locationSearchResults]);
  const addLocation = useCallback(
    (recipient: RecipientLocation) => {
      setLocations([...locations, recipient]);
      setSearchValue("");
      if (totalSearchResults !== 1) {
        setSearchFocus(true);
        if (searchRef.current) {
          searchRef.current.focus();
        }
      } else {
        setSearchFocus(false);
        if (searchRef.current) {
          searchRef.current.blur();
        }
      }
    },
    [locations, setLocations, setSearchValue, totalSearchResults],
  );
  const removeGroup = useCallback(
    (group: RecipientGroup) => {
      setTimeout(
        () => setLocations(locations.filter((r) => !(r.id === group.id))),
        20,
      );
    },
    [locations, setLocations],
  );
  const removeLastGroup = useCallback(() => {
    if (locations.length > 0) {
      setLocations(locations.slice(0, -1));
    }
  }, [locations, setLocations]);
  const addLocationAtIndex = useCallback(
    (index: number) => {
      addLocation(locationSearchResults[index]);
    },
    [addLocation, locationSearchResults],
  );
  const onEscape = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  const onBackspace = useCallback(() => {
    if (!searchValue) {
      removeLastGroup();
    }
  }, [searchValue, removeLastGroup]);
  const { keyboardSelectIndex, setKeyboardSelectIndex, onKeyDown } =
    useKeyboardNavigation(
      locationSearchResults.length,
      addLocationAtIndex,
      onEscape,
      onBackspace,
    );
  const placeholder = useMemo(() => {
    if (locations.length !== 0) {
      return undefined;
    }
    return "Search locations...";
  }, [locations.length]);

  return (
    <div>
      <div
        className={css(styles.container, styleDeclaration)}
        ref={containerRef}
      >
        <div className={css(styles.selectedRow, searchFocus && styles.focused)}>
          {locations.map((g) => {
            return (
              <AutoLayout key={g.id} paddingBottom={6} paddingRight={6}>
                <Pill
                  key={`group-${g.id}`}
                  title={g.name}
                  subtitle={
                    g.employees
                      ? `${g.employees.length} ${pluralize(
                          "Trainee",
                          g.employees.length,
                        )}`
                      : undefined
                  }
                  onRemove={() => removeGroup(g)}
                />
              </AutoLayout>
            );
          })}
          <input
            value={searchValue}
            ref={searchRef}
            onFocus={() => {
              setSearchFocus(true);
            }}
            onChange={(evt) => {
              setSearchValue(evt.target.value);
              setSearchFocus(true);
            }}
            className={css(styles.searchInput)}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={css(styles.rightBelowSearchBar)}>
          {searchFocus && (
            // @ts-ignore-next-line
            <RelativePortal component="div" right={0} left={0} fullWidth>
              <div className={css(styles.resultsContainer)}>
                {locationSearchResults.map((location, i) => (
                  <GroupSearchResult
                    group={location}
                    key={location.id}
                    addGroup={addLocation}
                    highlighted={i === keyboardSelectIndex}
                    onMouseMove={() => setKeyboardSelectIndex(i)}
                    type={"Location"}
                  />
                ))}
                {locationSearchResults.length === 0 && (
                  <div className={css(styles.resultRow, styles.noMatches)}>
                    <div>
                      {searchValue
                        ? `No locations match your search`
                        : `All locations selected`}
                    </div>
                  </div>
                )}
              </div>
            </RelativePortal>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationMultiSelect;

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    margin: 0,
    height: "100%",
    flex: 1,
  },
  selectedRow: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: "8px",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    padding: "8px 8px 4px 8px",
    minHeight: 48,
    flexWrap: "wrap",
    fontSize: "14px",
    ":hover": {
      border: `1px solid ${deprecatedTones.blue5}`,
    },
  },
  focused: {
    ...MiscStyles.focus,
  },
  rightBelowSearchBar: {
    position: "relative",
  },
  resultsContainer: {
    width: "calc(100% - 2px)",
    padding: "8px 0",
    backgroundColor: deprecatedColors.background,
    top: -4,
    boxShadow: "0 9px 39px -21px rgba(0, 0, 0, 0.49)",
    borderRadius: 8,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    zIndex: 1000,
    maxHeight: 300,
    overflowY: "scroll",
    position: "absolute",
  },
  resultRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 14,
    fontWeight: 500,
    height: 40,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  selected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    marginBottom: 4,
    minWidth: 4,
    color: deprecatedColors.onPrimaryContainer,
    "::placeholder": {
      color: deprecatedTones.gray6,
    },
  },
  noMatches: {
    fontSize: 13,
    marginLeft: 12,
    color: deprecatedTones.gray6,
    cursor: "unset",
    ":hover": {
      backgroundColor: deprecatedColors.disabled,
    },
  },
});
