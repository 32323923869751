import { gql } from "@apollo/client";
import {
  useGetEngagementQuery,
  useGetProductAdoptionQuery,
} from "./EngagementAndAdoption.generated";
import ProgressPie from "@components/ui/progressBars/ProgressPie";
import Spacer from "@components/ui/Spacer";
import { deprecatedAlphaTone } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import useBrandColor from "@hooks/useBrandColor";
import { FC, useMemo } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import useIsFullAdmin from "@src/hooks/useIsFullAdmin";

type Props = {
  locationIds?: number[];
  allLocations?: boolean;
};

const EngagementAndAdoption: FC<Props> = ({
  locationIds,
  allLocations = true,
}) => {
  const isFullAdmin = useIsFullAdmin();
  const brandColor = useBrandColor();
  const tenPercentBrandColor = useMemo(
    () => deprecatedAlphaTone(brandColor, 0.1),
    [brandColor],
  );
  const sixtyPercentBrandColor = useMemo(
    () => deprecatedAlphaTone(brandColor, 0.6),
    [brandColor],
  );
  const { data: engagementData, loading: engagementLoading } =
    useGetEngagementQuery({
      variables: {
        input: {
          locationIds,
        },
      },
    });
  const { data: productAdoptionData, loading: productAdoptionLoading } =
    useGetProductAdoptionQuery();
  const engagementPercent = useMemo(() => {
    const active = engagementData?.EmployeeEngagement.activeEmployees || 0;
    const total = engagementData?.EmployeeEngagement.totalEmployees || 1;
    return 100.0 * (active / total);
  }, [engagementData]);
  const adoptionPercent = useMemo(() => {
    if (
      !productAdoptionData?.Me?.user.org?.seatsExpected ||
      !productAdoptionData.People.totalCount
    ) {
      return 0;
    }
    const percentage =
      100.0 *
      (productAdoptionData.People.totalCount /
        productAdoptionData.Me.user.org.seatsExpected);
    return Math.min(percentage, 100);
  }, [productAdoptionData]);

  return (
    <AutoLayout
      alignmentVertical="bottom"
      direction="horizontal"
      flex={2}
      alignSelf="stretch"
      spaceBetweenItems={40}
    >
      {allLocations && (
        <AutoLayout
          direction="vertical"
          alignmentHorizontal="center"
          paddingRight={12}
        >
          {isFullAdmin ? (
            <ProgressPie
              slices={[
                {
                  value: adoptionPercent,
                  color: brandColor,
                },
                {
                  value: adoptionPercent < 100 ? 100 - adoptionPercent : 0,
                  color: tenPercentBrandColor,
                },
              ]}
              size={100}
              strokeWidth={10}
            >
              <AutoLayout
                direction="horizontal"
                alignmentVertical="bottom"
                spaceBetweenItems={1}
              >
                <Text
                  type="H2"
                  fontWeight="SemiBold"
                  color={brandColor}
                  style={{
                    marginLeft: 8,
                  }}
                  loading={productAdoptionLoading}
                  skeletonWidth={35}
                >
                  {adoptionPercent.toFixed() === "0"
                    ? "--"
                    : adoptionPercent.toFixed()}
                </Text>
                <Text
                  type="P4"
                  fontWeight="SemiBold"
                  color={sixtyPercentBrandColor}
                  style={{ marginBottom: 6 }}
                >
                  %
                </Text>
              </AutoLayout>
            </ProgressPie>
          ) : (
            <AutoLayout direction="vertical">
              <AutoLayout alignmentVertical="center" height={100}>
                <DeprecatedIcon type="users" iconSize={36} color={brandColor} />
                <Text
                  type="H2"
                  fontWeight="SemiBold"
                  color={brandColor}
                  style={{
                    marginLeft: 3,
                  }}
                  loading={productAdoptionLoading}
                  skeletonWidth={35}
                >
                  {productAdoptionData?.People.totalCount.toLocaleString(
                    "en-US",
                  ) || 0}
                </Text>
              </AutoLayout>
            </AutoLayout>
          )}
          <Spacer size={20} />
          <Text type="P2" fontWeight="Medium" color={brandColor}>
            Adoption
          </Text>
        </AutoLayout>
      )}
      <AutoLayout
        direction={allLocations ? "vertical" : "horizontal"}
        alignmentHorizontal="center"
      >
        <AutoLayout direction="vertical" alignmentHorizontal="center">
          <ProgressPie
            slices={[
              {
                value: engagementPercent,
                color: brandColor,
              },
              {
                value: engagementPercent < 100 ? 100 - engagementPercent : 0,
                color: tenPercentBrandColor,
              },
            ]}
            size={allLocations ? 100 : 72}
            strokeWidth={10}
          >
            <AutoLayout
              direction="horizontal"
              alignmentVertical="bottom"
              spaceBetweenItems={1}
            >
              <Text
                type="H2"
                fontWeight="SemiBold"
                color={brandColor}
                style={{
                  marginLeft: 8,
                }}
                loading={engagementLoading}
                skeletonWidth={35}
              >
                {engagementPercent?.toFixed() === "0"
                  ? "--"
                  : engagementPercent?.toFixed()}
              </Text>
              <Text
                type="P4"
                fontWeight="SemiBold"
                color={sixtyPercentBrandColor}
                style={{ marginBottom: 6 }}
              >
                %
              </Text>
            </AutoLayout>
          </ProgressPie>
        </AutoLayout>
        <Spacer size={20} />
        <AutoLayout
          direction="vertical"
          alignmentHorizontal={allLocations ? "center" : "left"}
          marginLeft={allLocations ? 0 : 12}
        >
          <Text
            type={allLocations ? "P2" : "H2"}
            headlineFont={!allLocations}
            fontWeight="Medium"
            color={brandColor}
          >
            Engagement
          </Text>
          <Spacer size={allLocations ? 4 : 8} />
          <Text
            type={allLocations ? "P3" : "P2"}
            fontWeight="Regular"
            color={sixtyPercentBrandColor}
            style={{ marginBottom: -20 }}
          >
            Used app in last 30 days
          </Text>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

gql`
  query GetEngagement($input: ProductEngagementInput) {
    EmployeeEngagement: AdminEmployeeEngagement(input: $input) {
      totalEmployees
      activeEmployees
    }
  }
  query GetProductAdoption {
    People(input: { includeStaffForStaff: { value: false } }) {
      totalCount
    }
    Me {
      user {
        id
        org {
          id
          seatsExpected
        }
      }
    }
  }
`;

export default EngagementAndAdoption;
