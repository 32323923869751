import RatingStars from "@components/contentDetail/courseDetail/assignments/RatingStars";
import { getCourseDetailRoute } from "@components/contentLibrary/utils";
import { CourseFeedbackFullFragment } from "@components/home/RecentFeedback.generated";
import Spacer from "@components/ui/Spacer";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Link from "@src/deprecatedDesignSystem/components/Link";
import Text from "@ui/text";
import { formatRelativeDateNoTime } from "@utils/dates";
import { StyleSheet } from "aphrodite";
import { parseISO } from "date-fns";
import { FC, useMemo } from "react";

enum CourseTab {
  Overview = "Overview",
  Assignments = "Assignments",
  Feedback = "Feedback",
}

type Props = {
  feedbackItem: CourseFeedbackFullFragment;
};

const FeedbackItem: FC<Props> = ({ feedbackItem }) => {
  const completedAt = useMemo(
    () =>
      feedbackItem.courseInstance.completedAt
        ? formatRelativeDateNoTime(
            parseISO(feedbackItem.courseInstance.completedAt),
          )
        : null,
    [feedbackItem],
  );
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      styleDeclaration={styles.container}
    >
      <RatingStars rating={feedbackItem.rating} size={20} />
      <Spacer size={8} />
      <Text type="P1" color={deprecatedTones.gray13} multiline>
        {feedbackItem.comment}
      </Text>
      <Spacer size={12} />
      <AutoLayout alignmentVertical="center" spaceBetweenItems={4}>
        <Text type="P2" fontWeight="SemiBold" color={deprecatedTones.black}>
          {feedbackItem.courseInstance.user.name}
        </Text>
        <DeprecatedIcon type="arrow-right" color={deprecatedTones.gray5} />
        <Link
          href={getCourseDetailRoute(
            feedbackItem.courseInstance.course.id,
            undefined,
            CourseTab.Feedback,
          )}
          styleDeclaration={styles.link}
        >
          <Text
            type="P2"
            fontWeight="SemiBold"
            color={deprecatedTones.black}
            multiline
          >
            {feedbackItem.courseInstance.course.libraryItem.name.en}
          </Text>
        </Link>
      </AutoLayout>
      <Spacer size={6} />
      <AutoLayout alignmentVertical="center" spaceBetweenItems={8}>
        {completedAt ? (
          <AutoLayout
            direction="horizontal"
            spaceBetweenItems={5}
            alignSelf="stretch"
          >
            <Text type="P2" fontWeight="Regular" color={deprecatedTones.gray11}>
              {typeof completedAt === "string"
                ? completedAt
                : completedAt.monthAndDay}
            </Text>
            {typeof completedAt !== "string" && completedAt.year ? (
              <Text
                type="P2"
                fontWeight="Regular"
                color={deprecatedTones.gray6}
              >
                '{completedAt.year}
              </Text>
            ) : null}
          </AutoLayout>
        ) : null}
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    padding: 16,
  },
  link: {
    textDecoration: "none",
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
});

export default FeedbackItem;
