import {
  MinimalEmployeeFragment,
  useGetAllEmployeesQuery,
} from "@components/ui/operations.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Pill from "@src/deprecatedDesignSystem/components/Pill";
import useClickOutside from "@hooks/useClickOutside";
import useKeyboardNavigation from "@hooks/useKeyboardNavigation";
import { EmployeeThinFragment } from "@src/fragments.generated";
import { UserType } from "@src/types.generated";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import Fuse from "fuse.js";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import RelativePortal from "react-relative-portal";
import UserSearchResult from "./UserSearchResult";

type Props = {
  employees: MinimalEmployeeFragment[];
  setEmployees: (recipients: MinimalEmployeeFragment[]) => void;
  excludeEmployeeIds?: Set<number>;
  placeholder?: string;
  subtitle?: "phone" | "userType";
  showSelectAll?: boolean;
  userTypes?: UserType[];
  rightAction?: React.ReactNode;
  styleDeclaration?: StyleDeclaration;
};

export type RecipientGroup = {
  id: number;
  name: string;
  employees: EmployeeThinFragment[];
};

export type RecipientLocation = {
  id: number;
  name: string;
  employees: EmployeeThinFragment[];
};

export type RecipientRole = {
  id: number;
  name: string;
  employees: EmployeeThinFragment[];
};

const DEFAULT_VISIBLE_RECIPIENTS = 7;

const fuseConfig = {
  keys: ["name", "locations.name", "roles.name", "email", "phoneNumber"],
  threshold: 0.3,
};

export const UserMultiSelect: FC<Props> = ({
  employees,
  setEmployees,
  excludeEmployeeIds,
  placeholder = "Search people...",
  showSelectAll = true,
  userTypes = [],
  rightAction,
  styleDeclaration,
}) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [allRecipientsVisible, setAllRecipientsVisible] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const onClickOutside = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  useClickOutside(containerRef, onClickOutside, false);
  const { data } = useGetAllEmployeesQuery({
    variables: {
      userTypes,
    },
  });
  const allEmployees = useMemo(() => data?.Employees.objects || [], [data]);
  const selectedEmployeeIds = useMemo(
    () => new Set(employees.map((e) => e.id)),
    [employees],
  );
  const availableEmployees = useMemo(
    () =>
      allEmployees.filter(
        (e) =>
          !selectedEmployeeIds.has(e.id) &&
          (!excludeEmployeeIds || !excludeEmployeeIds.has(e.id)),
      ) || [],
    [allEmployees, selectedEmployeeIds, excludeEmployeeIds],
  );
  const fuse = useMemo(() => {
    return new Fuse(availableEmployees, fuseConfig);
  }, [availableEmployees]);
  const searchResults = useMemo(() => {
    let _employees = availableEmployees;
    if (searchValue) {
      _employees = fuse.search(searchValue).map((result) => result.item);
    }
    return _employees;
  }, [fuse, searchValue, availableEmployees]);
  const visibleEmployees: MinimalEmployeeFragment[] = useMemo(() => {
    const _employees = [...employees];
    return allRecipientsVisible || searchFocus
      ? _employees
      : _employees.slice(0, DEFAULT_VISIBLE_RECIPIENTS);
  }, [employees, allRecipientsVisible, searchFocus]);
  const totalSearchResults = useMemo(() => {
    return searchResults.length;
  }, [searchResults]);
  const addEmployee = useCallback(
    (recipient: MinimalEmployeeFragment) => {
      setEmployees([...employees, recipient]);
      setSearchValue("");
      if (totalSearchResults !== 1) {
        setSearchFocus(true);
        if (searchRef.current) {
          searchRef.current.focus();
        }
      } else {
        setSearchFocus(false);
        if (searchRef.current) {
          searchRef.current.blur();
        }
      }
    },
    [employees, setEmployees, setSearchValue, totalSearchResults],
  );
  const addEmployeeAtIndex = useCallback(
    (index: number) => {
      const employee = searchResults[index];
      addEmployee(employee);
    },
    [addEmployee, searchResults],
  );
  const removeEmployee = useCallback(
    (recipient: MinimalEmployeeFragment) => {
      setTimeout(
        () => setEmployees(employees.filter((r) => !(r.id === recipient.id))),
        20,
      );
    },
    [employees, setEmployees],
  );
  const removeLastRecipient = useCallback(() => {
    const toRemove = employees[employees.length - 1];
    setEmployees(employees.filter((x) => x.id !== toRemove.id));
  }, [employees, setEmployees]);
  const onEscape = useCallback(() => {
    setSearchFocus(false);
  }, [setSearchFocus]);
  const onBackspace = useCallback(() => {
    if (!searchValue) {
      removeLastRecipient();
    }
  }, [searchValue, removeLastRecipient]);
  const dynamicPlaceholder = useMemo(() => {
    if (employees.length !== 0) {
      return undefined;
    }
    return placeholder;
  }, [employees, placeholder]);
  const { keyboardSelectIndex, setKeyboardSelectIndex, onKeyDown } =
    useKeyboardNavigation(
      searchResults.length,
      addEmployeeAtIndex,
      onEscape,
      onBackspace,
    );
  return (
    <div className={css(styles.container, styleDeclaration)} ref={containerRef}>
      <div>
        <div className={css(styles.selectedRow, searchFocus && styles.focused)}>
          <div className={css(styles.content)}>
            {visibleEmployees.map((employee) => {
              return (
                <AutoLayout
                  key={`visible-employee-${employee.id}`}
                  paddingVertical={3}
                  paddingRight={6}
                >
                  <Pill
                    title={employee.name || ""}
                    onRemove={() => removeEmployee(employee)}
                  />
                </AutoLayout>
              );
            })}
            <div
              className={css(
                styles.viewAll,
                visibleEmployees.length >= employees.length && styles.hidden,
              )}
              onClick={() => setAllRecipientsVisible(true)}
            >
              {`+${employees.length - DEFAULT_VISIBLE_RECIPIENTS} more`}
            </div>
            <div
              className={css(
                styles.viewAll,
                (employees.length < visibleEmployees.length ||
                  !allRecipientsVisible ||
                  searchFocus) &&
                  styles.hidden,
              )}
              onClick={() => setAllRecipientsVisible(false)}
            >
              show less
            </div>
            <input
              value={searchValue}
              ref={searchRef}
              onFocus={() => {
                setSearchFocus(true);
              }}
              onChange={(evt) => {
                setSearchValue(evt.target.value);
                setSearchFocus(true);
              }}
              className={css(styles.searchInput)}
              placeholder={dynamicPlaceholder}
              onKeyDown={onKeyDown}
            />
          </div>
          {rightAction && rightAction}
        </div>
        <div className={css(styles.rightBelowSearchBar)}>
          {searchFocus && (
            // @ts-ignore-next-line
            <RelativePortal component="div" right={0} left={0} fullWidth>
              <AutoLayout
                direction={"vertical"}
                alignSelf={"stretch"}
                flex={1}
                className={css(styles.resultsContainer)}
              >
                {searchResults.length > 0 && showSelectAll && allEmployees && (
                  <button
                    className={css(styles.selectAll)}
                    onClick={() => {
                      setEmployees(allEmployees);
                      setSearchFocus(false);
                    }}
                  >
                    Select all
                  </button>
                )}
                <AutoLayout
                  direction={"vertical"}
                  alignSelf={"stretch"}
                  style={{
                    overflowY: "auto",
                  }}
                >
                  {searchResults.map((user, i) => (
                    <UserSearchResult
                      key={user.id}
                      user={user}
                      addEmployee={addEmployee}
                      highlighted={i === keyboardSelectIndex}
                      onMouseMove={() => setKeyboardSelectIndex(i)}
                    />
                  ))}
                  {searchResults.length === 0 && (
                    <div className={css(styles.resultRow, styles.noMatches)}>
                      <div>
                        {searchValue
                          ? "Nobody matches your search"
                          : "Everybody is selected"}
                      </div>
                    </div>
                  )}
                </AutoLayout>
              </AutoLayout>
            </RelativePortal>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    margin: 0,
    height: "100%",
    flex: 1,
  },
  content: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  selectedRow: {
    display: "flex",
    paddingTop: 43,
    alignItems: "center",
    border: `1px solid ${deprecatedTones.gray4Alpha}`,
    borderRadius: "12px",
    padding: "4px 8px 4px 8px",
    minHeight: 40,
    fontSize: "14px",
    ":hover": {
      border: `1px solid ${deprecatedTones.blue2}`,
    },
  },
  focused: {
    ...MiscStyles.focus,
  },
  rightBelowSearchBar: {
    position: "relative",
  },
  resultsContainer: {
    width: "calc(100% - 2px)",
    backgroundColor: deprecatedTones.white,
    padding: 8,
    top: -4,
    boxShadow: "0 9px 39px -21px rgba(0, 0, 0, 0.49)",
    borderRadius: 8,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    zIndex: 1000,
    maxHeight: 300,
    position: "absolute",
  },
  hidden: {
    display: "none",
  },
  resultRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    borderRadius: 8,
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 14,
    fontWeight: 500,
  },
  viewAll: {
    display: "flex",
    alignItems: "center",
    backgroundColor: deprecatedColors.primaryContainer,
    fontWeight: 400,
    fontSize: 14,
    color: deprecatedColors.onPrimaryContainer,
    padding: "0 8px",
    height: 20,
    borderRadius: 4,
    marginRight: 4,
    marginBottom: 4,
    cursor: "pointer",
  },
  searchInput: {
    border: "none",
    outline: "none",
    flex: 1,
    minWidth: 4,
    alignSelf: "stretch",
    color: deprecatedColors.onPrimaryContainer,
    "::placeholder": {
      color: deprecatedTones.gray6,
    },
  },
  noMatches: {
    fontSize: 13,
    marginLeft: 12,
    color: deprecatedTones.gray6,
    cursor: "unset",
    ":hover": {
      backgroundColor: deprecatedColors.disabled,
    },
  },
  selectAll: {
    backgroundColor: "transparent",
    color: deprecatedColors.primary,
    border: "none",
    cursor: "pointer",
    fontSize: 13,
    marginLeft: 8,
    marginBottom: 4,
    padding: 0,
  },
});
