import * as React from "react";
import { IconProps } from "../types";

const PenOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M10.547 4.422L13.578 7.453"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.75 15.25C2.75 15.25 6.349 14.682 7.296 13.735C8.243 12.788 14.623 6.40799 14.623 6.40799C15.46 5.57099 15.46 4.21399 14.623 3.37799C13.786 2.54099 12.429 2.54099 11.593 3.37799C11.593 3.37799 5.213 9.75799 4.266 10.705C3.319 11.652 2.751 15.251 2.751 15.251L2.75 15.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default PenOutlineIcon;
