import React, { FC } from "react";
import DetailHeader from "@src/deprecatedDesignSystem/components/DetailPage/DetailHeader";
import useBranding from "@src/hooks/useBranding";

const OrgDetailHeader: FC = () => {
  const { name } = useBranding();
  return (
    <DetailHeader>
      <DetailHeader.Content>
        <DetailHeader.TitleAndSubtitle>
          <DetailHeader.Title loading={false}>{name}</DetailHeader.Title>
        </DetailHeader.TitleAndSubtitle>
      </DetailHeader.Content>
    </DetailHeader>
  );
};

export default OrgDetailHeader;
