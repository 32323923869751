import EngagementAndAdoption from "./EngagementAndAdoption";
import InThePastWeek from "./InThePastWeek";
import { getDeprecatedBrandBillboardColor } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useBrandColor from "@hooks/useBrandColor";
import { css, StyleSheet } from "aphrodite";
import { FC, useMemo, CSSProperties } from "react";

type Props = {
  locationIds?: number[];
  allLocations?: boolean;
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  compact?: boolean;
};

const Billboard: FC<Props> = ({
  locationIds,
  padding = 59,
  margin = 8,
  allLocations = true,
}) => {
  const brandColor = useBrandColor();
  const background = useMemo(
    () => getDeprecatedBrandBillboardColor(brandColor),
    [brandColor],
  );
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      styleDeclaration={styles.container}
      style={{ background }}
      padding={padding}
      margin={margin}
    >
      <div className={css(styles.grid)}>
        <EngagementAndAdoption
          allLocations={allLocations}
          locationIds={locationIds}
        />
        <div className={css(styles.pastWeekContainer)}>
          <InThePastWeek
            allLocations={allLocations}
            locationIds={locationIds}
          />
        </div>
      </div>
    </AutoLayout>
  );
};

const smallerThanBreakpoint = `@media (max-width: 1100px)`;

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignSelf: "stretch",
    padding: 24,
    gap: 12,
    [smallerThanBreakpoint]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  pastWeekContainer: {
    [smallerThanBreakpoint]: {
      paddingTop: 50,
    },
  },
});

export default Billboard;
