import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { FC } from "react";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { getDueDate, getStartDate } from "./utils";
import { useRouter } from "next/router";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import { useTranslation } from "@src/utils/translationSets";
import useLanguage from "@src/hooks/useLanguage";
import { LibraryItemType } from "@src/types.generated";
import { ToDoAssignmentFragment } from "@src/components/my-training/MyTrainingListView.generated";

export const NO_INCOMPLETE_ASSIGNMENTS_ENGLISH = "No incomplete assignments";

type Props = {
  assignments: ToDoAssignmentFragment[];
  loading: boolean;
};

const ToDoListTable: FC<Props> = ({ assignments, loading }) => {
  const language = useLanguage();
  const router = useRouter();
  const emptyTranslation = useTranslation(
    NO_INCOMPLETE_ASSIGNMENTS_ENGLISH,
    language,
  );
  return (
    <Table
      loading={loading}
      noDataEmptyState={
        <AutoLayout flex={1} alignSelf="stretch">
          <EmptyState
            title={emptyTranslation.text || NO_INCOMPLETE_ASSIGNMENTS_ENGLISH}
            icon="checkmark"
          />
        </AutoLayout>
      }
      showCount={false}
      allItems={assignments}
      allColumns={["name", "type", "startedAt", "dueDate"]}
      disabledSorts={["name", "type", "startedAt", "dueDate"]}
      columnTitles={{
        name: "Name",
        type: "Type",
        startedAt: "Started At",
        dueDate: "Due Date",
      }}
      responsiveness={{
        name: {
          flex: 3,
        },
      }}
      onRowClick={(item) => {
        if (item.currentCourseInstance) {
          router.push({
            pathname: "/my-training/course-instance/[id]",
            query: { id: String(item.currentCourseInstance.id) },
          });
        } else if (item.currentPathInstance) {
          router.push({
            pathname: "/my-training/path-instance/[id]",
            query: { id: String(item.currentPathInstance.id) },
          });
        }
      }}
      renderFns={{
        name: (item) => (
          <AutoLayout alignmentVertical="center" spaceBetweenItems={8}>
            <ContentAvatar
              size={32}
              contentType="Module"
              style={{ borderRadius: 8 }}
              coverImage={
                item.libraryItem.coverImage || item.libraryItem.coverImage
              }
              defaultBackgroundHashKey={item.libraryItem.id}
            />
            <Text type="P2">{item.libraryItem.name.myTranslation}</Text>
          </AutoLayout>
        ),

        type: (item) =>
          item.libraryItem.type === LibraryItemType.Course
            ? "Course"
            : "Module",
        startedAt: (item) =>
          getStartDate(
            item.currentCourseInstance?.startedAt ||
              item.currentPathInstance?.startedAt,
          ),

        dueDate: (item) =>
          getDueDate(
            item.currentCourseInstance?.dueDate ||
              item.currentPathInstance?.dueDate,
          ),
      }}
      itemId={(item) => item.id}
    />
  );
};

export default ToDoListTable;
