import Modal from "@components/modals/Modal";
import {
  RecipientRole,
  UserMultiSelect,
} from "@src/deprecatedDesignSystem/components/UserMultiSelect";
import { useModal } from "@hooks/useModal";
import { useTracking } from "@hooks/useTracking";
import { pluralize } from "@utils/strings";
import { FC, useMemo, useState } from "react";
import {
  RemoveUsersFromRolesMutationVariables,
  useRemoveUsersFromRolesMutation,
} from "./operations.generated";
import PostActionSummaryModal from "./PostActionSummaryModal";
import { css, StyleSheet } from "aphrodite";
import Text from "@ui/text";
import Spacer from "../ui/Spacer";
import { GraphqlOperations } from "@src/types.generated";
import { MinimalEmployeeFragment } from "../ui/operations.generated";
import RoleMultiSelect from "@components/ui/RoleMultiSelect";

type Props = {
  trainees?: MinimalEmployeeFragment[];
  roles?: RecipientRole[];
  onSuccess?: () => void;
};

const RemoveTraineesFromRolesModal: FC<Props> = ({
  roles: initialGroups = [],
  trainees: initialTrainees = [],
  onSuccess,
}) => {
  const [roles, setRoles] = useState(initialGroups);
  const roleIds = useMemo(() => roles.map((g) => g.id), [roles]);
  const [employees, setEmployees] = useState(initialTrainees);
  const employeeIds = useMemo(() => employees.map((e) => e.id), [employees]);
  const { showModal, closeModal } = useModal();
  const removeUsersFromGroupsVariables: RemoveUsersFromRolesMutationVariables =
    {
      roleIds,
      employeeIds,
    };
  const { trackEvent } = useTracking();
  const [addTraineesToRoles, { loading }] = useRemoveUsersFromRolesMutation({
    variables: removeUsersFromGroupsVariables,
    refetchQueries: [GraphqlOperations.Query.GetPeople],
    onCompleted: (data) => {
      if (data.removeUsersFromRoles.success) {
        closeModal();
        if (onSuccess) {
          onSuccess();
        }
        trackEvent(
          "trainees_removed_from_roles",
          removeUsersFromGroupsVariables,
        );
        showModal(
          <PostActionSummaryModal
            title={`${employees.length} ${pluralize(
              "trainee",
              employees.length,
            )} Removed from ${roles.length} ${pluralize("role", roles.length)}`}
          />,
        );
      }
    },
  });

  return (
    <Modal
      title={`Remove from roles`}
      className={css(styles.container)}
      confirmButtonProps={{
        copy: `Remove ${employeeIds.length} ${pluralize(
          "trainee",
          employeeIds.length,
        )} from ${roleIds.length} ${pluralize("role", roleIds.length)}`,
        disabled: roleIds.length === 0 || employeeIds.length === 0,
        onClick: () => {
          if (!roles) {
            return;
          }
          addTraineesToRoles();
        },
        loading,
      }}
      headerClassName={css(styles.modalHeader)}
      footerClassName={css(styles.modalHeader, styles.modalFooter)}
      bodyStyle={{ width: 600 }}
    >
      <div className={css(styles.traineeSelectContainer)}>
        <Text type="P2" fontWeight="SemiBold">
          People
        </Text>
        <Spacer size={10} />
        <UserMultiSelect employees={employees} setEmployees={setEmployees} />
      </div>
      <div className={css(styles.groupSelect)}>
        <Text type="P2" fontWeight="SemiBold">
          Remove from roles
        </Text>
        <Spacer size={10} />
        <RoleMultiSelect roles={roles} setRoles={setRoles} />
      </div>
    </Modal>
  );
};

export default RemoveTraineesFromRolesModal;

const styles = StyleSheet.create({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
  },
  modalHeader: {
    flexGrow: 1,
    maxHeight: "80px",
  },
  modalFooter: {
    alignItems: "center",
  },
  groupSelect: {
    padding: "24px",
  },
  traineeSelectContainer: {
    padding: "24px 24px 0 24px",
  },
});
