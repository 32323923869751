import { FC, useMemo } from "react";
import HomeBillboard from "@components/home/HomeBillboard";
import Spacer from "@components/ui/Spacer";
import RecentlyEdited from "@components/home/RecentlyEdited";
import RecentFeedback from "@components/home/RecentFeedback";
import GuestFeedbackRecommendations from "./GuestFeedbackRecommendations";
import { useGuestFeedbackAiWorkflowQuery } from "./GuestFeedbackRecommendations.generated";
import { css, StyleSheet } from "aphrodite";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";

const OrgDetailOverviewTab: FC = () => {
  const { data } = useGuestFeedbackAiWorkflowQuery();
  const aiGuestFeedbackRecommendationsFlagEnabled = useFeatureFlag(
    "ai-guest-feedback-recommendations",
  );
  const hasApprovedAiSuggestionCategories = useMemo(
    () =>
      data?.GuestFeedbackAiWorkflow?.categories.some(
        (c) => c.isApprovedForDisplay,
      ),
    [data],
  );
  return (
    <>
      <HomeBillboard />
      <Spacer size={24} />
      {aiGuestFeedbackRecommendationsFlagEnabled &&
        data?.GuestFeedbackAiWorkflow &&
        hasApprovedAiSuggestionCategories && (
          <GuestFeedbackRecommendations data={data.GuestFeedbackAiWorkflow} />
        )}
      <Spacer size={24} />
      <div className={css(styles.grid)}>
        <RecentlyEdited />
        <div className={css(styles.recentFeedbackContainer)}>
          <RecentFeedback />
        </div>
      </div>
    </>
  );
};

export default OrgDetailOverviewTab;

const smallerThanBreakpoint = `@media (max-width: 1100px)`;
const styles = StyleSheet.create({
  grid: {
    display: "grid",
    gridTemplateColumns: ".8fr 1fr",
    alignSelf: "stretch",
    gap: 24,
    [smallerThanBreakpoint]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  recentFeedbackContainer: {
    [smallerThanBreakpoint]: {
      paddingTop: 50,
    },
  },
});
