import * as React from "react";
import { gql } from "@apollo/client";
import Modal from "@components/modals/Modal";
import { useModal } from "@src/hooks/useModal";
import { Button } from "@src/ui/button";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import useRoles from "@src/hooks/useRoles";
import {
  RoleLevel,
  RoleLevelRoleFragment,
  initializeRoleLevelsFromRoles,
} from "../utils";
import { Card, CardContent, CardHeader, CardTitle } from "@src/ui/card";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { Badge } from "@src/ui/badge";
import { CSS } from "@dnd-kit/utilities";
import { cloneDeep } from "lodash";
import TrashOutlineIcon from "@src/ui/icons/18px/trash-outline";
import { GraphqlOperations } from "@src/types.generated";
import { useToast } from "@src/hooks/useToast";
import { useUpdateRoleLevelsMutation } from "./EditHierarchyLevelsModal.generated";
import PlusIcon from "@src/ui/icons/18px/plus";

const EditHierarchyLevelsModal: React.FC = () => {
  const { roles } = useRoles();
  const { addToast, addErrorToast } = useToast();
  const [draggedRole, setDraggedRole] =
    React.useState<RoleLevelRoleFragment | null>(null);
  const [roleLevels, setRoleLevels] = React.useState(
    initializeRoleLevelsFromRoles(roles),
  );
  const [updateRoleTiersMutation, { loading }] = useUpdateRoleLevelsMutation({
    refetchQueries: [GraphqlOperations.Query.OrgDetail_Roles],
    variables: {
      input: {
        roleLevels: roleLevels.flatMap((roleLevel, index) =>
          roleLevel.roles.map((role) => ({
            roleId: role.id,
            level: index + 1,
          })),
        ),
      },
    },
    onCompleted: (data) => {
      if (data?.updateRoleLevels?.success) {
        addToast({
          iconType: "check-circle",
          message: "Role hierarchy updated.",
        });
        closeModal();
      } else {
        addErrorToast({
          message: "Failed to update role hierarchy.",
          callsite: "EditHierarchyLevelsModal",
        });
      }
    },
  });
  const { closeModal } = useModal();
  const addRoleLevel = React.useCallback(() => {
    setRoleLevels((prev) => [...prev, { roles: [] }]);
  }, []);
  const deleteRoleLevel = React.useCallback((index: number) => {
    setRoleLevels((prev) => prev.filter((_, i) => i !== index));
  }, []);
  const handleOnDragStart = React.useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      if (!active) return;
      const roleToMoveId = parseInt(
        String(active.id).replace(ROLE_DRAGGABLE_ID, ""),
      );
      const roleToMove = roles.find((r) => r.id === roleToMoveId);
      if (!roleToMove) return;
      setDraggedRole(roleToMove);
    },
    [roles],
  );
  const handleOnDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over || !active) return;
      const newRoleLevelState = cloneDeep(roleLevels);
      const newRoleLevelIndex = parseInt(
        String(over.id).replace(LEVEL_DROPPABLE_INDEX, ""),
      );
      const draggedRoleId = parseInt(
        String(active.id).replace(ROLE_DRAGGABLE_ID, ""),
      );
      const draggedRole = roles.find((r) => r.id === draggedRoleId);
      const draggedRoleLevelIndex = active.data.current?.levelIndex;
      if (!draggedRole || typeof draggedRoleLevelIndex !== "number") return;
      newRoleLevelState[draggedRoleLevelIndex].roles = newRoleLevelState[
        draggedRoleLevelIndex
      ].roles.filter((r) => r.id !== draggedRoleId);
      newRoleLevelState[newRoleLevelIndex].roles = [
        ...newRoleLevelState[newRoleLevelIndex].roles,
        draggedRole,
      ];
      setDraggedRole(null);
      setRoleLevels(newRoleLevelState);
    },
    [roleLevels, roles],
  );
  return (
    <Modal
      title="Edit role hierarchy"
      confirmButtonProps={{
        copy: "Save",
        onClick: updateRoleTiersMutation,
        loading,
      }}
      showHeader={false}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: closeModal,
      }}
    >
      <div className="flex max-h-full max-w-[700px] flex-col pb-6">
        <div className="flex items-center justify-between gap-6 self-stretch p-6">
          <div className="flex flex-col gap-1 self-stretch">
            <Text type="H4" fontWeight="SemiBold">
              Edit role hierarchy
            </Text>
            <Text type="P2" color={deprecatedTones.gray8} multiline>
              Managers can only see and assign roles in lower levels.
            </Text>
          </div>
          <Button variant="outline" onClick={addRoleLevel}>
            <PlusIcon />
            Add level
          </Button>
        </div>
        <div className="relative flex flex-1 flex-col gap-4 self-stretch overflow-visible px-6">
          <div className="absolute inset-y-0 left-12 z-0 h-full w-px bg-gray-4" />
          <DndContext
            onDragStart={handleOnDragStart}
            onDragEnd={handleOnDragEnd}
          >
            {roleLevels.map((roleLevel, index) => {
              return (
                <RoleLevelCard
                  key={index}
                  index={index}
                  roleLevel={roleLevel}
                  deleteRoleLevel={deleteRoleLevel}
                />
              );
            })}
            <DragOverlay>
              {draggedRole && (
                <Badge className="select-none">{draggedRole.name}</Badge>
              )}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </Modal>
  );
};

type RoleLevelCardProps = {
  index: number;
  roleLevel: RoleLevel;
  deleteRoleLevel: (index: number) => void;
};
const RoleLevelCard: React.FC<RoleLevelCardProps> = (props) => {
  const totalRoles = props.roleLevel.roles.length;
  const { setNodeRef: droppableRef } = useDroppable({
    id: `${LEVEL_DROPPABLE_INDEX}${props.index}`,
  });
  const showDeleteButton = props.index !== 0 && totalRoles === 0;
  const handleOnDeleteRoleLevel = React.useCallback(() => {
    props.deleteRoleLevel(props.index);
  }, [props]);
  return (
    <Card className="relative z-50">
      <CardHeader className="relative">
        <CardTitle>Level</CardTitle>
        {showDeleteButton && (
          <Button
            size="icon"
            variant="ghost"
            onClick={handleOnDeleteRoleLevel}
            className="absolute right-4 top-4 rounded-md"
          >
            <TrashOutlineIcon className="text-muted-foreground" />
            <span className="sr-only">Delete Layer</span>
          </Button>
        )}
      </CardHeader>
      <CardContent>
        <div
          ref={droppableRef}
          className="flex flex-wrap gap-2 rounded-md border border-dashed p-4"
          style={{
            alignItems: totalRoles === 0 ? "center" : "flex-start",
            justifyContent: totalRoles === 0 ? "center" : "flex-start",
            minHeight: 80,
          }}
        >
          {totalRoles === 0 && (
            <Text type="P2" color={deprecatedTones.gray8}>
              Drag a role
            </Text>
          )}
          {totalRoles > 0 &&
            props.roleLevel.roles.map((role) => (
              <DraggableBadge
                key={role.id}
                role={{ id: role.id, name: role.name }}
                levelIndex={props.index}
              />
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

type DraggableBadgeProps = {
  levelIndex: number;
  role: RoleLevelRoleFragment;
};
const DraggableBadge: React.FC<DraggableBadgeProps> = (props) => {
  const {
    attributes,
    listeners,
    transform,
    isDragging,
    setNodeRef: draggableRef,
  } = useDraggable({
    id: `${ROLE_DRAGGABLE_ID}${props.role.id}`,
    data: {
      levelIndex: props.levelIndex,
    },
  });
  return (
    <span
      ref={draggableRef}
      style={{
        visibility: isDragging ? "hidden" : "visible",
        border: "none",
        outline: "none",
        touchAction: "none",
        transform: CSS.Transform.toString(transform),
      }}
      {...attributes}
      {...listeners}
    >
      <Badge className="select-none">{props.role.name}</Badge>
    </span>
  );
};

const LEVEL_DROPPABLE_INDEX = "role-level-index-";
const ROLE_DRAGGABLE_ID = "role-id-";

gql`
  mutation updateRoleLevels($input: UpdateRoleLevelsInput!) {
    updateRoleLevels(input: $input) {
      success
      roles {
        ...RoleLevel
      }
    }
  }

  fragment RoleLevel on Role {
    id
    name
    roleLevel
  }
`;

export default EditHierarchyLevelsModal;
