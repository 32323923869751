import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { HrisConfigFragmentDoc } from '../components/settings/hris/setupFlow/OrgHrisConfigContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HrisQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HrisQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, hasMergeIntegration: boolean, hrisProvider?: string | null, hasAppliedHrisCsvUpload: boolean, hrisConfig: { __typename?: 'HrisConfig', id: string, finishedConnectingAt?: string | null, finishedLoadingAt?: string | null, dataVerifiedAt?: string | null, activatedAt?: string | null } } | null } } | null };


export const HrisDocument = gql`
    query Hris {
  Me {
    user {
      id
      org {
        id
        hasMergeIntegration
        hrisProvider
        hrisConfig {
          ...HrisConfig
        }
        hasAppliedHrisCsvUpload
      }
    }
  }
}
    ${HrisConfigFragmentDoc}`;

/**
 * __useHrisQuery__
 *
 * To run a query within a React component, call `useHrisQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrisQuery({
 *   variables: {
 *   },
 * });
 */
export function useHrisQuery(baseOptions?: Apollo.QueryHookOptions<HrisQuery, HrisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HrisQuery, HrisQueryVariables>(HrisDocument, options);
      }
export function useHrisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HrisQuery, HrisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HrisQuery, HrisQueryVariables>(HrisDocument, options);
        }
export type HrisQueryHookResult = ReturnType<typeof useHrisQuery>;
export type HrisLazyQueryHookResult = ReturnType<typeof useHrisLazyQuery>;
export type HrisQueryResult = Apollo.QueryResult<HrisQuery, HrisQueryVariables>;