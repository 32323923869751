import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { format, parseISO } from "date-fns";
import { ReactNode, useCallback } from "react";

export const useFormattedDateInTable = (): ((date: string) => ReactNode) => {
  return useCallback((date: string) => {
    return (
      <AutoLayout
        direction="horizontal"
        alignmentVertical="center"
        spaceBetweenItems={6}
        alignSelf="stretch"
      >
        <Text type={"P2"}>{format(parseISO(date), "M/d/yyyy")}</Text>
      </AutoLayout>
    );
  }, []);
};
