import {
  getLibraryItemType,
  getPremiumLibraryItemUrl,
} from "@components/contentLibrary/utils";
import Spacer from "@components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import { StyleSheet } from "aphrodite";
import { FC, useMemo } from "react";
import { gql } from "@apollo/client";
import { useRecentlyEditedLibraryItemsQuery } from "./RecentlyEdited.generated";
import useLibraryTableRowRenderFunctions from "../contentLibrary/hooks/useLibraryTableRowRenderFunctions";

const RecentlyEdited: FC = () => {
  const { data, loading } = useRecentlyEditedLibraryItemsQuery({
    fetchPolicy: "no-cache",
    variables: {
      input: {
        sort: { column: "lastEdited", descending: true, nullsLast: true },
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
    },
  });
  const libraryItems = useMemo(() => {
    return data?.LibraryItems.objects || [];
  }, [data?.LibraryItems.objects]);

  const { renderAvatar, renderTitleAndSubtitle } =
    useLibraryTableRowRenderFunctions();

  return (
    <AutoLayout direction="vertical" styleDeclaration={styles.container}>
      <Text type="H2" fontWeight="SemiBold" headlineFont>
        Recently edited
      </Text>
      <Spacer size={30} />
      <Table
        showCount={false}
        allItems={libraryItems}
        itemId={(item) => item.id}
        loading={loading}
        rowHeight={60}
        rowClickLink={(x) => {
          if (x.orgPremiumContentConfig) {
            return getPremiumLibraryItemUrl(x);
          }
          return {
            pathname: "/library/library-item/[id]",
            query: { id: x.id },
          };
        }}
        allColumns={["avatar", "name"]}
        responsiveness={{
          avatar: {
            width: 45,
          },
        }}
        renderFns={{
          avatar: (x) => {
            return renderAvatar(x, "40px");
          },
          name: (x) => {
            return renderTitleAndSubtitle(x, getLibraryItemType(x));
          },
        }}
      />
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 300,
  },
});

gql`
  query RecentlyEditedLibraryItems(
    $input: AdminLibraryInput!
    $pagination: PaginationInput
  ) {
    LibraryItems: AdminLibrary(input: $input, pagination: $pagination) {
      objects {
        id
        type
        name {
          uuid
          en
        }
        coverImage {
          ...CoverImage
        }
        path {
          id
          draftVersion {
            id
          }
          publishedVersion {
            id
          }
        }
        course {
          id
          draftVersion {
            id
          }
          publishedVersion {
            id
          }
        }
        trainingResource {
          id
          media {
            ...Media
          }
        }
        skill {
          id
        }
        orgPremiumContentConfig {
          id
          premiumContentConfig {
            id
            catalogPath {
              id
              path {
                id
                libraryItem {
                  id
                  name {
                    uuid
                    en
                  }
                  coverImage {
                    ...CoverImage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecentlyEdited;
