import { gql } from "@apollo/client";
import { useUpdateRoleMutation } from "@components/modals/EditRoleModal.generated";
import RoleModalBody from "@components/modals/RoleModalBody";
import { RoleThinFragment } from "@src/hooks/useRoles.generated";
import { useToast } from "@src/hooks/useToast";
import {
  ActionPermissionSetInput,
  GraphqlOperations,
  UserType,
} from "@src/types.generated";
import { actionPermissionsFragmentToInput } from "@utils/actionPermissions";
import { FC, useCallback, useEffect, useState } from "react";
import { Dialog, DialogTrigger } from "@ui/dialog";

type Props = {
  role: RoleThinFragment;
  trigger: React.ReactNode;
  onClose?: () => void;
};

const EditRoleModal: FC<Props> = ({ role, trigger, onClose }) => {
  const { addToast } = useToast();
  const [name, setName] = useState(role.name);
  const [roleGroupIds, setRoleGroupIds] = useState<string[]>(
    role.roleGroupMemberships.map((rgm) => rgm.roleGroup.id),
  );
  const [directReportRoleIds, setDirectReportRoleIds] = useState<number[]>([]);
  const [roleUserType, setRoleUserType] = useState(
    role.roleActionPermissions?.roleUserType || UserType.Employee,
  );
  const [permissions, setPermissions] = useState<ActionPermissionSetInput>(
    actionPermissionsFragmentToInput(
      role.roleActionPermissions?.actionPermissions,
    ),
  );
  const [clockInConfirmationRequired, setClockInConfirmationRequired] =
    useState<boolean>(
      role.roleActionPermissions?.requireClockInConfirmation == null
        ? true
        : role.roleActionPermissions.requireClockInConfirmation,
    );

  const toggleClockInConfirmationRequired = useCallback(() => {
    setClockInConfirmationRequired((current) => !current);
  }, []);
  useEffect(() => {
    setPermissions(
      actionPermissionsFragmentToInput(
        role.roleActionPermissions?.actionPermissions,
      ),
    );
  }, [role.roleActionPermissions?.actionPermissions]);
  useEffect(() => {
    if (role.roleActionPermissions?.roleUserType) {
      setRoleUserType(role.roleActionPermissions?.roleUserType);
    }
  }, [role.roleActionPermissions?.roleUserType]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateRole, { loading }] = useUpdateRoleMutation({
    variables: {
      id: role.id,
      input: {
        name,
        roleUserType,
        permissions,
        roleGroupIds: { value: roleGroupIds },
        requireClockInConfirmation: clockInConfirmationRequired,
        directReportRoleIds: { value: directReportRoleIds },
      },
    },
    refetchQueries: [GraphqlOperations.Query.RolesList],
    onCompleted: (data) => {
      if (data.updateRole.success) {
        setIsDialogOpen(false);
        onClose && onClose();
        addToast({
          iconType: "check",
          message: "Role updated",
        });
      }
    },
  });
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <RoleModalBody
        title="Edit Role"
        name={name}
        setName={setName}
        roleUserType={roleUserType}
        setRoleUserType={setRoleUserType}
        permissions={permissions}
        setPermissions={setPermissions}
        roleGroupIds={roleGroupIds}
        setRoleGroupIds={setRoleGroupIds}
        clockInConfirmationRequired={clockInConfirmationRequired}
        setClockInConfirmationRequired={setClockInConfirmationRequired}
        toggleClockInConfirmationRequired={toggleClockInConfirmationRequired}
        saveCopy="Save"
        onClose={() => {
          setIsDialogOpen(false);
          onClose?.();
        }}
        onSave={updateRole}
        loading={loading}
        saveDisabled={!name}
        directReportRoleIds={directReportRoleIds}
        setDirectReportRoleIds={setDirectReportRoleIds}
      />
    </Dialog>
  );
};

gql`
  mutation updateRole($id: Int!, $input: UpdateRoleInput!) {
    updateRole(id: $id, input: $input) {
      success
      role {
        ...RoleWithPermissions
      }
    }
  }
`;

export default EditRoleModal;
