import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { EmployeeListItemFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgDetail_PeopleQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type OrgDetail_PeopleQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, createdAt: string, name: string, firstName: string, lastName: string, email?: string | null, phoneNumber?: string | null, language: Language, hasUsedApp: boolean, deactivatedAt?: string | null, deactivatedReason?: Types.DeactivationReason | null, userType: Types.UserType, lastActivityAt?: string | null, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, deactivatedBy?: { __typename?: 'Employee', id: number, name: string } | null, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, firstName: string, lastName: string, startDate?: string | null } | null }> } };


export const OrgDetail_PeopleDocument = gql`
    query OrgDetail_People($input: GetPeopleInput!, $pagination: PaginationInput) {
  People(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...EmployeeListItem
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useOrgDetail_PeopleQuery__
 *
 * To run a query within a React component, call `useOrgDetail_PeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDetail_PeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDetail_PeopleQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrgDetail_PeopleQuery(baseOptions: Apollo.QueryHookOptions<OrgDetail_PeopleQuery, OrgDetail_PeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgDetail_PeopleQuery, OrgDetail_PeopleQueryVariables>(OrgDetail_PeopleDocument, options);
      }
export function useOrgDetail_PeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDetail_PeopleQuery, OrgDetail_PeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgDetail_PeopleQuery, OrgDetail_PeopleQueryVariables>(OrgDetail_PeopleDocument, options);
        }
export type OrgDetail_PeopleQueryHookResult = ReturnType<typeof useOrgDetail_PeopleQuery>;
export type OrgDetail_PeopleLazyQueryHookResult = ReturnType<typeof useOrgDetail_PeopleLazyQuery>;
export type OrgDetail_PeopleQueryResult = Apollo.QueryResult<OrgDetail_PeopleQuery, OrgDetail_PeopleQueryVariables>;