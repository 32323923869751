import ArchiveConfirmationModal from "@components/modals/ArchiveConfirmationModal";
import TrainingResourceModal from "@components/modals/TrainingResourceModal";
import ContextMenuItem, {
  ContextMenuItemProps,
} from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import useActionPermissions from "@hooks/useActionPermissions";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import { FC } from "react";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import { TrainingResourceLibrary } from "./hooks/useLibraryTableRowRenderFunctions";
import { isUserSelectionEmpty } from "@src/utils/assignments";
import AutomationModal from "./assignmentModals/AutomationModal";
import { useLibraryItemAccessAutomationLazyQuery } from "./LibraryContainer.generated";
import { useArchiveTrainingResourceMutation } from "@src/components/libraryItemDetailPages/LibraryItemDetailPageContainer.generated";

type Props = {
  libraryItem: TrainingResourceLibrary;
  className?: string;
  iconClassName?: string;
  rotateIcon?: boolean;
  isVisible: boolean;
};

const TrainingResourceOverflowMenu: FC<Props> = ({ libraryItem }) => {
  const resource = libraryItem.trainingResource;
  const { userActionPermissions } = useActionPermissions();
  const { addToast, addErrorToast } = useToast();
  const { showModal } = useModal();
  const [getAccessAutomation] = useLibraryItemAccessAutomationLazyQuery();
  const [archiveTrainingResource] = useArchiveTrainingResourceMutation({
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    onCompleted: (data) => {
      if (data.archiveTrainingResource.success) {
        addToast({
          message: "Resource successfully archived",
          iconType: "trash-2",
        });
      } else {
        addErrorToast({
          ...data.archiveTrainingResource,
          callsite: "drag_training_resource_archive_training_resource",
        });
      }
    },
  });
  const options: ContextMenuItemProps[] = [];
  if (libraryItem.archivedAt) {
    options.push({
      icon: "undo",
      label: "Unarchive",
      disabled: !userActionPermissions?.manageLibrary,
      onSelect: () => {
        archiveTrainingResource({
          variables: { id: resource.id, archive: false },
        });
      },
    });
  } else {
    options.push(
      {
        icon: "pencil",
        label: "Edit",
        disabled: !userActionPermissions?.createContent,
        onSelect: () => {
          showModal(<TrainingResourceModal file={resource} />);
        },
      },
      {
        icon: "book-open",
        label: "Update Access",
        disabled: !userActionPermissions?.updateAutomations,
        onSelect: () => {
          getAccessAutomation({
            variables: {
              id: libraryItem.id,
            },
          }).then(({ data }) => {
            const automation = data?.LibraryItem?.accessAutomation;
            if (!automation) {
              return;
            }
            const existingAutomation = !isUserSelectionEmpty(
              automation.userSelection,
            );
            showModal(
              <AutomationModal
                automationItem={{
                  automation,
                  contentName: libraryItem.name.en,
                  __typename: "TrainingResource",
                }}
                existingAutomation={existingAutomation}
              />,
            );
          });
        },
      },
      {
        icon: "trash-2",
        label: "Archive",
        disabled: !userActionPermissions?.manageLibrary,
        onSelect: () =>
          showModal(
            <ArchiveConfirmationModal
              onArchive={() =>
                archiveTrainingResource({
                  variables: {
                    id: resource.id,
                    archive: true,
                  },
                })
              }
              title={"Archive Resource"}
              itemName={libraryItem.name.en}
              includeModuleCopy
            />,
          ),
      },
    );
  }
  return (
    <>
      {options.map((x) => (
        <ContextMenuItem key={x.label} {...x} />
      ))}
    </>
  );
};

export default TrainingResourceOverflowMenu;
