import * as React from "react";
import { IconProps } from "../types";

const UserFillIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.00002 8C10.933 8 12.5 6.433 12.5 4.5C12.5 2.567 10.933 1 9.00002 1C7.06702 1 5.50002 2.567 5.50002 4.5C5.50002 6.433 7.06702 8 9.00002 8Z"
          fill={color}
        />
        <path
          d="M9.00001 9C6.23601 9 3.72601 10.636 2.60501 13.167C2.34801 13.747 2.35101 14.412 2.61301 14.992C2.88101 15.583 3.39001 16.035 4.01201 16.231C5.63001 16.741 7.30801 17 8.99901 17C10.69 17 12.368 16.741 13.986 16.231C14.608 16.035 15.118 15.583 15.385 14.992C15.647 14.412 15.65 13.747 15.393 13.167C14.272 10.636 11.762 9 8.99801 9H9.00001Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default UserFillIcon;
