import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { MyTranslationSetFragmentDoc, CoverImageFragmentDoc, TranslationSetFragmentDoc, MediaFragmentDoc, ContentTagMembershipFragmentDoc, ContentQrCodeFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc, LessonFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyTodoAssignmentsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTodoAssignmentsCountQuery = { __typename?: 'Query', MyTodoAssignments: { __typename?: 'Assignments', totalCount: number } };

export type MyTodoAssignmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTodoAssignmentsQuery = { __typename?: 'Query', MyTodoAssignments: { __typename?: 'Assignments', objects: Array<{ __typename?: 'Assignment', id: string, status: AssignmentStatus, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } }, currentCourseInstance?: { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null } | null, currentPathInstance?: { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null } | null }> } };

export type ToDoAssignmentFragment = { __typename?: 'Assignment', id: string, status: AssignmentStatus, libraryItem: { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } }, currentCourseInstance?: { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null } | null, currentPathInstance?: { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null } | null };

export type ToDoLibraryItemFragment = { __typename?: 'LibraryItem', id: string, type: Types.LibraryItemType, name: { __typename?: 'TranslationSet', uuid: string, myTranslation: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, description: { __typename?: 'TranslationSet', uuid: string, myTranslation: string } };

export type ToDoPathInstanceFragment = { __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null };

export type ToDoCourseInstanceFragment = { __typename?: 'CourseInstance', id: number, track: number, startedAt?: string | null, completedAt?: string | null, percentComplete: number, dueDate?: string | null, certificateUrl?: string | null };

export const ToDoLibraryItemFragmentDoc = gql`
    fragment ToDoLibraryItem on LibraryItem {
  id
  type
  name {
    ...MyTranslationSet
  }
  coverImage {
    ...CoverImage
  }
  description {
    ...MyTranslationSet
  }
}
    ${MyTranslationSetFragmentDoc}
${CoverImageFragmentDoc}`;
export const ToDoCourseInstanceFragmentDoc = gql`
    fragment ToDoCourseInstance on CourseInstance {
  id
  track
  startedAt
  completedAt
  percentComplete
  dueDate
  certificateUrl
}
    `;
export const ToDoPathInstanceFragmentDoc = gql`
    fragment ToDoPathInstance on PathInstance {
  id
  startedAt
  completedAt
  percentComplete
  dueDate
}
    `;
export const ToDoAssignmentFragmentDoc = gql`
    fragment ToDoAssignment on Assignment {
  id
  status
  libraryItem {
    ...ToDoLibraryItem
  }
  currentCourseInstance {
    ...ToDoCourseInstance
  }
  currentPathInstance {
    ...ToDoPathInstance
  }
}
    ${ToDoLibraryItemFragmentDoc}
${ToDoCourseInstanceFragmentDoc}
${ToDoPathInstanceFragmentDoc}`;
export const MyTodoAssignmentsCountDocument = gql`
    query MyTodoAssignmentsCount {
  MyTodoAssignments {
    totalCount
  }
}
    `;

/**
 * __useMyTodoAssignmentsCountQuery__
 *
 * To run a query within a React component, call `useMyTodoAssignmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTodoAssignmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTodoAssignmentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTodoAssignmentsCountQuery(baseOptions?: Apollo.QueryHookOptions<MyTodoAssignmentsCountQuery, MyTodoAssignmentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTodoAssignmentsCountQuery, MyTodoAssignmentsCountQueryVariables>(MyTodoAssignmentsCountDocument, options);
      }
export function useMyTodoAssignmentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTodoAssignmentsCountQuery, MyTodoAssignmentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTodoAssignmentsCountQuery, MyTodoAssignmentsCountQueryVariables>(MyTodoAssignmentsCountDocument, options);
        }
export type MyTodoAssignmentsCountQueryHookResult = ReturnType<typeof useMyTodoAssignmentsCountQuery>;
export type MyTodoAssignmentsCountLazyQueryHookResult = ReturnType<typeof useMyTodoAssignmentsCountLazyQuery>;
export type MyTodoAssignmentsCountQueryResult = Apollo.QueryResult<MyTodoAssignmentsCountQuery, MyTodoAssignmentsCountQueryVariables>;
export const MyTodoAssignmentsDocument = gql`
    query MyTodoAssignments {
  MyTodoAssignments {
    objects {
      ...ToDoAssignment
    }
  }
}
    ${ToDoAssignmentFragmentDoc}`;

/**
 * __useMyTodoAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyTodoAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTodoAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTodoAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTodoAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyTodoAssignmentsQuery, MyTodoAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTodoAssignmentsQuery, MyTodoAssignmentsQueryVariables>(MyTodoAssignmentsDocument, options);
      }
export function useMyTodoAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTodoAssignmentsQuery, MyTodoAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTodoAssignmentsQuery, MyTodoAssignmentsQueryVariables>(MyTodoAssignmentsDocument, options);
        }
export type MyTodoAssignmentsQueryHookResult = ReturnType<typeof useMyTodoAssignmentsQuery>;
export type MyTodoAssignmentsLazyQueryHookResult = ReturnType<typeof useMyTodoAssignmentsLazyQuery>;
export type MyTodoAssignmentsQueryResult = Apollo.QueryResult<MyTodoAssignmentsQuery, MyTodoAssignmentsQueryVariables>;