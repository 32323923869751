import AddUsersToLocationsModal from "@components/modals/AddUsersToLocationsModal";
import DeactivatePeopleModal from "@components/modals/DeactivatePeopleModal";
import ReactivatePeopleModal from "@components/modals/ReactivatePeopleModal";
import RemoveTraineesFromLocationsModal from "@components/modals/RemoveTraineesFromLocationsModal";
import InviteUserButton from "@components/people/InviteUserButton";
import {
  useOrgDetail_PeopleLazyQuery,
  useOrgDetail_PeopleQuery,
} from "./OrgDetailPeopleTab.generated";
import { getDeactivatedByCopy } from "@components/people/utils/columnValues";
import { getPersonDetailRoute } from "@components/people/utils/getDetailRoute";
import ContextMenuItem from "@src/deprecatedDesignSystem/components/ContextMenu/ContextMenuItem";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import Text from "@ui/text";
import PaginatedTable from "@src/deprecatedDesignSystem/components/table/PaginatedTable";
import useActionPermissions from "@hooks/useActionPermissions";
import { useHris } from "@src/hooks/useHris";
import { useModal } from "@hooks/useModal";
import { EmployeeListItemFragment } from "@src/fragments.generated";
import { GetPeopleInput, GraphqlOperations } from "@src/types.generated";
import { langCodeToEnglishName, userTypeCopy } from "@utils/copy";
import { formatMultipleGroupNames } from "@utils/strings";
import { format, parseISO } from "date-fns";
import gql from "graphql-tag";
import { FC, useCallback, useMemo } from "react";
import AddUsersToRolesModal from "@components/modals/AddUsersToRolesModal";
import RemoveTraineesFromRolesModal from "@components/modals/RemoveTraineesFromRolesModal";
import { MultiSelectBannerDropdown } from "@src/deprecatedDesignSystem/components/MultiSelectBanner";
import MessageBlastModal from "../messaging/MessageBlastModal";
import PeopleFiltersDropdown from "../filters/people/PeopleFiltersDropdown";
import usePeopleFilterState from "../filters/people/usePeopleFilterState";

type Column =
  | "name"
  | "userType"
  | "locations"
  | "roles"
  | "language"
  | "signedUp"
  | "email"
  | "app"
  | "deactivatedAt"
  | "deactivatedBy"
  | "hrisId"
  | "startDate"
  | "lastActivityAt";

type ExportColumn =
  | "firstName"
  | "lastName"
  | "userType"
  | "locations"
  | "roles"
  | "language"
  | "signedUp"
  | "email"
  | "app"
  | "deactivatedAt"
  | "deactivatedBy"
  | "hrisId"
  | "startDate"
  | "lastActivityAt";

const OrgDetailPeopleTab: FC = () => {
  const { userActionPermissions } = useActionPermissions();
  const {
    filters: peopleSearchFilters,
    graphqlInput: peopleFilterGraphqlInput,
  } = usePeopleFilterState();
  const allItemsCountInput: GetPeopleInput = useMemo(() => {
    return {};
  }, []);
  const { showModal } = useModal();
  const deactivatedAtCopy = useCallback(
    (x: EmployeeListItemFragment) =>
      x?.deactivatedAt
        ? format(parseISO(x.deactivatedAt), "M/d/yyyy h:mmaaa")
        : "Active",
    [],
  );

  const { provider: hrisProvider } = useHris();

  return (
    <PaginatedTable<
      EmployeeListItemFragment,
      Column,
      typeof useOrgDetail_PeopleQuery,
      typeof useOrgDetail_PeopleLazyQuery,
      ExportColumn
    >
      title={"People"}
      allItemsCountInput={allItemsCountInput}
      headerStickyPositioning={0}
      queryHook={useOrgDetail_PeopleQuery}
      lazyQueryHook={useOrgDetail_PeopleLazyQuery}
      getItems={(data) => data?.People?.objects ?? []}
      getTotalItemCount={(data) => data?.People?.totalCount ?? 0}
      itemId={(item) => item.id}
      input={peopleFilterGraphqlInput}
      rowClickLink={(x) => getPersonDetailRoute(x.id)}
      defaultSort={{ column: "signedUp", descending: true }}
      rightAction={
        <>
          <PeopleFiltersDropdown />
          <InviteUserButton />
        </>
      }
      allColumns={[
        "name",
        "userType",
        "locations",
        "roles",
        "startDate",
        "language",
        "signedUp",
        "email",
        "app",
        "deactivatedAt",
        "deactivatedBy",
        "hrisId",
        "lastActivityAt",
      ]}
      visibleColumns={() => {
        const cols: Column[] = [
          "name",
          "userType",
          "locations",
          "roles",
          "language",
          "signedUp",
          "email",
          "app",
          "lastActivityAt",
        ];
        if (hrisProvider) {
          cols.push("hrisId");
          cols.splice(cols.indexOf("roles") + 1, 0, "startDate");
        }
        if (peopleSearchFilters.includeDeactivated) {
          cols.push("deactivatedAt");
          cols.push("deactivatedBy");
        }
        return cols;
      }}
      columnTitles={{
        name: "Name",
        userType: "User Type",
        email: "Email",
        locations: "Locations",
        roles: "Roles",
        language: "Language",
        signedUp: "Signed up",
        app: "App",
        deactivatedAt: "Deactivated at",
        deactivatedBy: "Deactivated by",
        startDate: "Start Date",
        hrisId: `${hrisProvider} ID`,
        lastActivityAt: "Last Active",
      }}
      responsiveness={{
        app: {
          width: 50,
          collapseAtScreenWidth: 1200,
        },
        signedUp: {
          width: 100,
          collapseAtScreenWidth: 1100,
        },
        language: {
          width: 100,
          collapseAtScreenWidth: 1000,
        },
        hrisId: {
          width: 160,
          collapseAtScreenWidth: 1000,
        },
        deactivatedAt: {
          width: 160,
          collapseAtScreenWidth: 1000,
        },
        deactivatedBy: {
          width: 160,
          collapseAtScreenWidth: 1000,
        },
        roles: {
          collapseAtScreenWidth: 800,
        },
        locations: {
          collapseAtScreenWidth: 700,
        },
        email: {
          collapseAtScreenWidth: 700,
        },
        lastActivityAt: {
          collapseAtScreenWidth: 700,
        },
      }}
      overflowMenu={(x) => {
        return (
          <>
            <ContextMenuItem
              label={"Message"}
              icon={"message"}
              disabled={userActionPermissions?.sendMessages === false}
              onSelect={() => {
                showModal(
                  <MessageBlastModal
                    userSelection={{
                      userIds: [x.id],
                      allLocations: false,
                      allManagers: false,
                      allTrainees: false,
                    }}
                  />,
                );
              }}
            />
            <ContextMenuItem
              label={"Add to locations"}
              icon={"plus-circle"}
              disabled={userActionPermissions?.editTrainees === false}
              onSelect={() => {
                showModal(
                  <AddUsersToLocationsModal
                    data={{ trainees: [x] }}
                    refetchQueries={[GraphqlOperations.Query.GetPeople]}
                  />,
                );
              }}
            />
            <ContextMenuItem
              label={"Remove from locations"}
              icon={"minus-circle"}
              disabled={userActionPermissions?.editTrainees === false}
              onSelect={() => {
                showModal(<RemoveTraineesFromLocationsModal trainees={[x]} />);
              }}
            />
            <ContextMenuItem
              label={"Add roles"}
              icon={"plus-circle"}
              disabled={userActionPermissions?.editTrainees === false}
              onSelect={() => {
                showModal(
                  <AddUsersToRolesModal
                    data={{ trainees: [x] }}
                    refetchQueries={[GraphqlOperations.Query.GetPeople]}
                  />,
                );
              }}
            />
            <ContextMenuItem
              label={"Remove roles"}
              icon={"minus-circle"}
              disabled={userActionPermissions?.editTrainees === false}
              onSelect={() => {
                showModal(<RemoveTraineesFromRolesModal trainees={[x]} />);
              }}
            />
          </>
        );
      }}
      multiselect={{
        checkboxColumnWidth: 32,
        renderAvatarFn: (x) => <PersonAvatar person={x} size={"32px"} />,
        actions: (selectedItems, deselectAll) => {
          const deactivated = selectedItems.filter((x) => x.deactivatedAt);
          const active = selectedItems.filter((x) => !x.deactivatedAt);
          return [
            {
              key: "message",
              icon: "message",
              label: "Message",
              hasPermission: Boolean(userActionPermissions?.sendMessages),
              onClick: () => {
                showModal(
                  <MessageBlastModal
                    userSelection={{
                      userIds: selectedItems.map((x) => x.id),
                      allLocations: false,
                      allManagers: false,
                      allTrainees: false,
                    }}
                    onSubmit={deselectAll}
                  />,
                );
              },
            },
            {
              key: "deactivate",
              label:
                active.length > 0 && active.length !== selectedItems.length
                  ? `Deactivate (${active.length})`
                  : "Deactivate",
              icon: "trash-2",
              disabled: active.length === 0,
              hasPermission: Boolean(userActionPermissions?.deactivateTrainees),
              onClick: () => {
                showModal(
                  <DeactivatePeopleModal
                    employeeIds={active.map((x) => x.id)}
                    onSuccess={deselectAll}
                  />,
                );
              },
            },
            {
              key: "reactivate",
              icon: "user-plus",
              label:
                deactivated.length > 0 &&
                deactivated.length !== selectedItems.length
                  ? `Reactivate (${deactivated.length})`
                  : "Reactivate",
              hasPermission: Boolean(userActionPermissions?.deactivateTrainees),
              disabled: deactivated.length === 0,
              onClick: () => {
                showModal(
                  <ReactivatePeopleModal
                    employees={deactivated}
                    onSuccess={deselectAll}
                  />,
                );
              },
            },
            {
              key: "editLocations ",
              component: (
                <MultiSelectBannerDropdown
                  disabled={userActionPermissions?.editTrainees === false}
                  buttonLabel={"Edit locations"}
                  buttonIcon={"pin"}
                  buttonKey={"editLocations"}
                  dropdownOptions={[
                    {
                      label: "Add to locations",
                      icon: "plus-circle",
                      onClick: () => {
                        showModal(
                          <AddUsersToLocationsModal
                            data={{ trainees: selectedItems }}
                            refetchQueries={[GraphqlOperations.Query.GetPeople]}
                            onSuccess={deselectAll}
                          />,
                        );
                      },
                    },
                    {
                      label: "Remove from locations",
                      icon: "minus-circle",
                      onClick: () => {
                        showModal(
                          <RemoveTraineesFromLocationsModal
                            trainees={selectedItems}
                            onSuccess={deselectAll}
                          />,
                        );
                      },
                    },
                  ]}
                />
              ),
              hasPermission: Boolean(userActionPermissions?.editTrainees),
            },
            {
              key: "editRoles",
              component: (
                <MultiSelectBannerDropdown
                  disabled={userActionPermissions?.editTrainees === false}
                  buttonLabel={"Edit roles"}
                  buttonIcon={"id-badge"}
                  buttonKey={"editRoles"}
                  dropdownOptions={[
                    {
                      label: "Add roles",
                      icon: "plus-circle",
                      onClick: () => {
                        showModal(
                          <AddUsersToRolesModal
                            data={{ trainees: selectedItems }}
                            refetchQueries={[GraphqlOperations.Query.GetPeople]}
                            onSuccess={deselectAll}
                          />,
                        );
                      },
                    },
                    {
                      label: "Remove roles",
                      icon: "minus-circle",
                      onClick: () => {
                        showModal(
                          <RemoveTraineesFromRolesModal
                            trainees={selectedItems}
                            onSuccess={deselectAll}
                          />,
                        );
                      },
                    },
                  ]}
                />
              ),
              hasPermission: Boolean(userActionPermissions?.editTrainees),
            },
          ];
        },
      }}
      exportConfig={{
        title: "people.csv",
        exportColumns: () => {
          const cols: ExportColumn[] = [
            "firstName",
            "lastName",
            "userType",
            "locations",
            "roles",
            "language",
            "signedUp",
            "email",
            "app",
          ];
          if (hrisProvider) {
            cols.push("hrisId");
          }
          if (peopleSearchFilters.includeDeactivated) {
            cols.push("deactivatedAt");
            cols.push("deactivatedBy");
          }
          return cols;
        },
        exportFns: {
          firstName: (person) => person.firstName,
          lastName: (person) => person.lastName,
          userType: (x) => userTypeCopy[x.userType],
          email: (x) => x.email,
          locations: (x) => formatMultipleGroupNames(x.locations),
          roles: (x) => formatMultipleGroupNames(x.roles),
          language: (x) => langCodeToEnglishName(x.language),
          signedUp: (x) => format(parseISO(x.createdAt), "M/d/yyyy"),
          app: (x) => (x.hasUsedApp ? "Has used app" : "Has not used app"),
          deactivatedAt: deactivatedAtCopy,
          deactivatedBy: (x) => getDeactivatedByCopy(x),
          hrisId: (x) => x.hrisEmployee?.remoteHrisId,
          startDate: (x) =>
            x.hrisEmployee?.startDate
              ? format(parseISO(x.hrisEmployee.startDate), "M/d/yyyy")
              : null,
          lastActivityAt: (x) =>
            x.lastActivityAt
              ? format(parseISO(x.lastActivityAt), "M/d/yyyy")
              : "-",
        },
      }}
      renderFns={{
        name: (x) => (
          <Text
            type={"P2"}
            fontWeight={"Medium"}
            ellipsis
            ellipsisParentFlexDirection={"row"}
          >
            {x.name}
          </Text>
        ),
        userType: (x) => userTypeCopy[x.userType],
        email: (x) => x.email,
        locations: (x) => formatMultipleGroupNames(x.locations),
        roles: (x) => formatMultipleGroupNames(x.roles),
        language: (x) => langCodeToEnglishName(x.language),
        signedUp: (x) => format(parseISO(x.createdAt), "M/d/yyyy"),
        app: (x) =>
          x.hasUsedApp ? (
            <DeprecatedIcon type="checkmark" />
          ) : (
            <Text type="P2">-</Text>
          ),
        deactivatedAt: deactivatedAtCopy,
        deactivatedBy: (x) => getDeactivatedByCopy(x),
        hrisId: (x) => x.hrisEmployee?.remoteHrisId,
        startDate: (x) =>
          x.hrisEmployee?.startDate
            ? format(parseISO(x.hrisEmployee.startDate), "M/d/yyyy")
            : null,
        lastActivityAt: (x) =>
          x.lastActivityAt
            ? format(parseISO(x.lastActivityAt), "M/d/yyyy")
            : null,
      }}
    />
  );
};

gql`
  query OrgDetail_People(
    $input: GetPeopleInput!
    $pagination: PaginationInput
  ) {
    People(input: $input, pagination: $pagination) {
      totalCount
      objects {
        ...EmployeeListItem
      }
    }
  }
`;

export default OrgDetailPeopleTab;
