import AssignmentsListView from "@src/components/my-training/MyTrainingListView";
import OrgDetail from "@components/home/OrgDetail";
import Page from "@components/layout/Page";
import useUser from "@hooks/useUser";
import { NextPageWithLayout } from "@pages/_app";
import { UserType } from "@src/types.generated";
import { ReactElement } from "react";

const HomePage: NextPageWithLayout = () => {
  const { user } = useUser();
  const isTrainee = user?.userType === UserType.Employee;
  if (isTrainee) {
    return <AssignmentsListView />;
  }
  return <OrgDetail />;
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <Page>{page}</Page>;
};

export default HomePage;
