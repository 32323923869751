import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HrisSetupNextButtonDisabledQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HrisSetupNextButtonDisabledQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, hrisIntegrations: Array<{ __typename?: 'HrisIntegration', id: string, initialSyncCompletedAt?: string | null }> } | null } } | null };

export type HrisConfigFragment = { __typename?: 'HrisConfig', id: string, finishedConnectingAt?: string | null, finishedLoadingAt?: string | null, dataVerifiedAt?: string | null, activatedAt?: string | null };

export type OrgHrisConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgHrisConfigQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, hrisConfig: { __typename?: 'HrisConfig', id: string, finishedConnectingAt?: string | null, finishedLoadingAt?: string | null, dataVerifiedAt?: string | null, activatedAt?: string | null } } | null } } | null };

export type UpdateOrgHrisConfigMutationVariables = Types.Exact<{
  input: Types.OrgHrisConfigInput;
}>;


export type UpdateOrgHrisConfigMutation = { __typename?: 'Mutation', updateOrgHrisConfig: { __typename?: 'HrisConfigMutationResult', success: boolean, hrisConfig?: { __typename?: 'HrisConfig', id: string, finishedConnectingAt?: string | null, finishedLoadingAt?: string | null, dataVerifiedAt?: string | null, activatedAt?: string | null } | null } };

export const HrisConfigFragmentDoc = gql`
    fragment HrisConfig on HrisConfig {
  id
  finishedConnectingAt
  finishedLoadingAt
  dataVerifiedAt
  activatedAt
}
    `;
export const HrisSetupNextButtonDisabledDocument = gql`
    query HrisSetupNextButtonDisabled {
  Me {
    user {
      id
      org {
        id
        hrisIntegrations {
          id
          initialSyncCompletedAt
        }
      }
    }
  }
}
    `;

/**
 * __useHrisSetupNextButtonDisabledQuery__
 *
 * To run a query within a React component, call `useHrisSetupNextButtonDisabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrisSetupNextButtonDisabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrisSetupNextButtonDisabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useHrisSetupNextButtonDisabledQuery(baseOptions?: Apollo.QueryHookOptions<HrisSetupNextButtonDisabledQuery, HrisSetupNextButtonDisabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HrisSetupNextButtonDisabledQuery, HrisSetupNextButtonDisabledQueryVariables>(HrisSetupNextButtonDisabledDocument, options);
      }
export function useHrisSetupNextButtonDisabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HrisSetupNextButtonDisabledQuery, HrisSetupNextButtonDisabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HrisSetupNextButtonDisabledQuery, HrisSetupNextButtonDisabledQueryVariables>(HrisSetupNextButtonDisabledDocument, options);
        }
export type HrisSetupNextButtonDisabledQueryHookResult = ReturnType<typeof useHrisSetupNextButtonDisabledQuery>;
export type HrisSetupNextButtonDisabledLazyQueryHookResult = ReturnType<typeof useHrisSetupNextButtonDisabledLazyQuery>;
export type HrisSetupNextButtonDisabledQueryResult = Apollo.QueryResult<HrisSetupNextButtonDisabledQuery, HrisSetupNextButtonDisabledQueryVariables>;
export const OrgHrisConfigDocument = gql`
    query OrgHrisConfig {
  Me {
    user {
      id
      org {
        id
        hrisConfig {
          ...HrisConfig
        }
      }
    }
  }
}
    ${HrisConfigFragmentDoc}`;

/**
 * __useOrgHrisConfigQuery__
 *
 * To run a query within a React component, call `useOrgHrisConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHrisConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHrisConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgHrisConfigQuery(baseOptions?: Apollo.QueryHookOptions<OrgHrisConfigQuery, OrgHrisConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgHrisConfigQuery, OrgHrisConfigQueryVariables>(OrgHrisConfigDocument, options);
      }
export function useOrgHrisConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgHrisConfigQuery, OrgHrisConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgHrisConfigQuery, OrgHrisConfigQueryVariables>(OrgHrisConfigDocument, options);
        }
export type OrgHrisConfigQueryHookResult = ReturnType<typeof useOrgHrisConfigQuery>;
export type OrgHrisConfigLazyQueryHookResult = ReturnType<typeof useOrgHrisConfigLazyQuery>;
export type OrgHrisConfigQueryResult = Apollo.QueryResult<OrgHrisConfigQuery, OrgHrisConfigQueryVariables>;
export const UpdateOrgHrisConfigDocument = gql`
    mutation UpdateOrgHrisConfig($input: OrgHrisConfigInput!) {
  updateOrgHrisConfig(input: $input) {
    success
    hrisConfig {
      ...HrisConfig
    }
  }
}
    ${HrisConfigFragmentDoc}`;
export type UpdateOrgHrisConfigMutationFn = Apollo.MutationFunction<UpdateOrgHrisConfigMutation, UpdateOrgHrisConfigMutationVariables>;

/**
 * __useUpdateOrgHrisConfigMutation__
 *
 * To run a mutation, you first call `useUpdateOrgHrisConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgHrisConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgHrisConfigMutation, { data, loading, error }] = useUpdateOrgHrisConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgHrisConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgHrisConfigMutation, UpdateOrgHrisConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgHrisConfigMutation, UpdateOrgHrisConfigMutationVariables>(UpdateOrgHrisConfigDocument, options);
      }
export type UpdateOrgHrisConfigMutationHookResult = ReturnType<typeof useUpdateOrgHrisConfigMutation>;
export type UpdateOrgHrisConfigMutationResult = Apollo.MutationResult<UpdateOrgHrisConfigMutation>;
export type UpdateOrgHrisConfigMutationOptions = Apollo.BaseMutationOptions<UpdateOrgHrisConfigMutation, UpdateOrgHrisConfigMutationVariables>;