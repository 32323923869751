import AvatarTitleSubtitle from "@src/deprecatedDesignSystem/components/AvatarTitleSubtitle";
import useScrollIntoView from "@hooks/useScrollIntoView";
import { MinimalEmployeeFragment } from "@src/components/ui/operations.generated";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { onlyFirstLetterCapitalized } from "@utils/strings";
import { css, StyleSheet } from "aphrodite";
import { FC, useRef } from "react";

type Props = {
  user: MinimalEmployeeFragment;
  onMouseMove: () => void;
  addEmployee: (user: MinimalEmployeeFragment) => void;
  highlighted: boolean;
};

const UserSearchResult: FC<Props> = ({
  user,
  onMouseMove,
  addEmployee,
  highlighted,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useScrollIntoView(ref, highlighted);
  return (
    <div
      className={css(styles.resultRow, highlighted && styles.selected)}
      onClick={(evt) => {
        evt.stopPropagation();
        addEmployee(user);
      }}
      ref={ref}
      onMouseMove={onMouseMove}
    >
      <AvatarTitleSubtitle
        size="32px"
        avatarType="Person"
        title={user.name || ""}
        titleProps={{
          type: "P2",
        }}
        subtitle={{
          subtitle1: onlyFirstLetterCapitalized(user.userType),
        }}
        person={{
          name: user.name,
          id: user.id,
        }}
      />
    </div>
  );
};

export default UserSearchResult;

const styles = StyleSheet.create({
  resultRow: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    borderRadius: 8,
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 14,
    fontWeight: 500,
  },
  selected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
