import { useMemo } from "react";
import { LibraryContainerLibraryItemFragment } from "../LibraryContainer.generated";

const useGetTagIdsFromLibraryItems = (
  libraryItems: LibraryContainerLibraryItemFragment[],
): string[] => {
  return useMemo(() => {
    const seenTagIds = new Set<string>();
    const tagIds: string[] = [];
    for (const libraryItem of libraryItems) {
      for (const tagMem of libraryItem.contentTagMemberships) {
        const tagId = tagMem.tagId;
        if (!seenTagIds.has(tagId)) {
          tagIds.push(tagId);
        }
        seenTagIds.add(tagId);
      }
    }
    return tagIds;
  }, [libraryItems]);
};

export default useGetTagIdsFromLibraryItems;
