import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { BrandDetailsFragmentDoc, AiOrgContextFragmentDoc } from '../../hooks/useBranding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgDetail_LocationGroupsQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type OrgDetail_LocationGroupsQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', totalCount: number, objects: Array<{ __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, locationCount: number, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null }> } };

export type OrgDetail_LocationGroupsUserCountQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type OrgDetail_LocationGroupsUserCountQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', totalCount: number, objects: Array<{ __typename?: 'LocationGroup', id: string, userCount: number }> } };

export type OrgDetail_LocationGroupFragment = { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType, locationCount: number, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null };

export const OrgDetail_LocationGroupFragmentDoc = gql`
    fragment OrgDetail_LocationGroup on LocationGroup {
  id
  name
  type
  locationCount
  brandDetails {
    ...BrandDetails
  }
}
    ${BrandDetailsFragmentDoc}`;
export const OrgDetail_LocationGroupsDocument = gql`
    query OrgDetail_LocationGroups($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    totalCount
    objects {
      ...OrgDetail_LocationGroup
    }
  }
}
    ${OrgDetail_LocationGroupFragmentDoc}`;

/**
 * __useOrgDetail_LocationGroupsQuery__
 *
 * To run a query within a React component, call `useOrgDetail_LocationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDetail_LocationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDetail_LocationGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useOrgDetail_LocationGroupsQuery(baseOptions: Apollo.QueryHookOptions<OrgDetail_LocationGroupsQuery, OrgDetail_LocationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgDetail_LocationGroupsQuery, OrgDetail_LocationGroupsQueryVariables>(OrgDetail_LocationGroupsDocument, options);
      }
export function useOrgDetail_LocationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDetail_LocationGroupsQuery, OrgDetail_LocationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgDetail_LocationGroupsQuery, OrgDetail_LocationGroupsQueryVariables>(OrgDetail_LocationGroupsDocument, options);
        }
export type OrgDetail_LocationGroupsQueryHookResult = ReturnType<typeof useOrgDetail_LocationGroupsQuery>;
export type OrgDetail_LocationGroupsLazyQueryHookResult = ReturnType<typeof useOrgDetail_LocationGroupsLazyQuery>;
export type OrgDetail_LocationGroupsQueryResult = Apollo.QueryResult<OrgDetail_LocationGroupsQuery, OrgDetail_LocationGroupsQueryVariables>;
export const OrgDetail_LocationGroupsUserCountDocument = gql`
    query OrgDetail_LocationGroupsUserCount($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    totalCount
    objects {
      id
      userCount
    }
  }
}
    `;

/**
 * __useOrgDetail_LocationGroupsUserCountQuery__
 *
 * To run a query within a React component, call `useOrgDetail_LocationGroupsUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDetail_LocationGroupsUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDetail_LocationGroupsUserCountQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useOrgDetail_LocationGroupsUserCountQuery(baseOptions: Apollo.QueryHookOptions<OrgDetail_LocationGroupsUserCountQuery, OrgDetail_LocationGroupsUserCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgDetail_LocationGroupsUserCountQuery, OrgDetail_LocationGroupsUserCountQueryVariables>(OrgDetail_LocationGroupsUserCountDocument, options);
      }
export function useOrgDetail_LocationGroupsUserCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgDetail_LocationGroupsUserCountQuery, OrgDetail_LocationGroupsUserCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgDetail_LocationGroupsUserCountQuery, OrgDetail_LocationGroupsUserCountQueryVariables>(OrgDetail_LocationGroupsUserCountDocument, options);
        }
export type OrgDetail_LocationGroupsUserCountQueryHookResult = ReturnType<typeof useOrgDetail_LocationGroupsUserCountQuery>;
export type OrgDetail_LocationGroupsUserCountLazyQueryHookResult = ReturnType<typeof useOrgDetail_LocationGroupsUserCountLazyQuery>;
export type OrgDetail_LocationGroupsUserCountQueryResult = Apollo.QueryResult<OrgDetail_LocationGroupsUserCountQuery, OrgDetail_LocationGroupsUserCountQueryVariables>;